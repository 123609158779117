import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DateFilterComponent from './date-filter/DateFilter.vue';
import StateFilterComponent from './state-filter/State.vue';
import InputFilterComponent from './input-filter/Input.vue';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { svg_filters, statesFilters } from '@/app/constants/svgfilters';
import { BASE_URL_ORCHESTATOR } from '@/config';
import axios from 'axios';
import { Modal } from 'ant-design-vue';
import { VueWizard } from '@/vue-wizard';
import { filterMutationsTypes } from '@/app/store/modules/filters/filters.mutations';
import { Filter } from '@/app/store/modules/filters/filters.models';
@Component({
  name: 'filter-component2',
  components: {
    DateFilterComponent,
    StateFilterComponent,
    InputFilterComponent,
    PerfectScrollbar,
  },
})
export default class FilterComponent extends VueWizard {
  @Prop() EventBus!: Vue;
  @Prop() project!: any;
  @Prop() filters_edit!: any;

  public name_filter: string = '';
  public select_filter: string = '+ Añadir Filtro';
  public eventBus: Vue = new Vue();
  public filters_edit_ = false;
  public filterId = '';
  public show_create_filter: boolean = false;
  public loading: boolean = false;
  public components: [
    {
      type_filter: string;
      title_filter: string;
      component: string;
      svg: string;
      name_filter: string;
      source: string;
      states?: any[];
      multiple?: boolean;
      token?: string;
      selectedOptionEdit?: any;
    },
  ] = [
    {
      type_filter: '',
      title_filter: '',
      component: '',
      svg: '',
      name_filter: '',
      source: '',
      states: [],
      multiple: false,
      token: '',
      selectedOptionEdit: [],
    },
  ];

  public componentDate: [
    {
      type_filter: string;
      title_filter: string;
      component: string;
      svg: string;
      name_filter: string;
      source: string;
      states?: any[];
      multiple?: boolean;
      token?: string;
      selectedOptionEdit?: any;
    },
  ] = [
    {
      type_filter: '',
      title_filter: '',
      component: '',
      svg: '',
      name_filter: '',
      source: '',
      states: [],
      multiple: false,
      token: '',
      selectedOptionEdit: [],
    },
  ];

  public showFilter: boolean[] = [true];

  public filters: any = [
    {
      value: 'fecha_creacion_contacto',
      label: 'Fecha de creación del contacto',
      component: 'date-filter-component',
      type: 'date',
    },
    {
      value: 'fecha_inicio_ultima_conversacion',
      label: 'Fecha de inicio de la última conversación',
      component: 'date-filter-component',
      type: 'date',
    },
    {
      value: 'fecha_cierre_conversacion',
      label: 'Fecha de cierre de conversación',
      component: 'date-filter-component',
      type: 'date',
    },
    {
      value: 'ultimo_origen_conversacion',
      label: 'Último origen de conversación',
      component: 'date-filter-component',
      type: 'date',
    },
    {
      value: 'fecha_creacion_orden',
      label: 'Fecha de creación de la orden',
      component: 'date-filter-component',
    },
    {
      value: 'automatizacion_fecha',
      label: 'Automatización Fecha',
      component: 'date-filter-component',
    },
    {
      value: 'ultima_automatizacion',
      label: 'Última automatización',
      component: 'date-filter-component',
    },
    {
      value: 'estado_conversacion',
      label: 'Estado de la conversación',
      component: 'date-filter-component',
    },
    {
      value: 'estado_pedido',
      label: 'Estado del pedido',
      component: 'date-filter-component',
    },
    {
      value: 'etiqueta_finalizacion',
      label: 'Etiqueta de finalización',
      component: 'date-filter-component',
    },
    {
      value: 'motivo_finalizacion',
      label: 'Motivo de finalización',
      component: 'date-filter-component',
    },
    {
      value: 'etiqueta_orden',
      label: 'Etiqueta de orden',
      component: 'date-filter-component',
    },
    {
      value: 'ultimo_asesor_asignado',
      label: 'Último asesor asignado',
      component: 'date-filter-component',
    },
    {
      value: 'creacion_orden',
      label: 'Creación de orden',
      component: 'date-filter-component',
    },
  ];

  public filter_equations: any = [];

  public vistaModel: any = {
    filter_equations: [],
    name_filter: '',
    icon: '',
    source: '',
  };

  public rules: any = {
    filter_equations: [
      {
        required: true,
        message: 'Debe seleccionar al menos un filtro',
        trigger: 'blur',
        type: 'array',
      },
    ],
    name_filter: [
      {
        required: true,
        message: 'Debe ingresar un nombre para el filtro',
        trigger: 'blur',
      },
    ],
    icon: [
      {
        required: true,
        message: 'Debe seleccionar un icono para el filtro',
        trigger: 'blur',
      },
    ],
  };

  public input: string = '';
  public search: string = '';
  public states_pedidos = [];
  public distribution_companies = [];
  public enableDeleteDate: [boolean] = [true];
  public enableDeleteShop: [boolean] = [true];
  // Variables para opciones de filtros

  public enableArray: boolean[] = [
    false, // estado_pedido // 0
    false, // transportadora // 1
    false, // id_producto // 2
    false, // creacion_orden // 3
    false, // fecha_creacion_contacto // 4
    false, // tienda // 5
  ];

  public enableSaveArray: {
    [key: string]: boolean;
  } = {
    estado_pedido: false,
  };

  get hasToken(): boolean {
    return this.project.dropiUsers.length > 0;
  }

  get filters_(): any {
    return this.$store.state.filters.filters_agent;
  }

  get filters_admin(): any {
    return this.$store.state.filters.filters_project;
  }

  async mounted() {
    this.components.pop();
    this.componentDate.pop();
    this.enableSaveArray = {};
    this.states_pedidos = await this.filterStateOptions();
    this.distribution_companies = await this.filterTransportadoraOptions();
    if (this.filters_edit) {
      this.vistaModel.name_filter = this.filters_edit.name;
      this.vistaModel.icon = this.filters_edit.label.split(' ')[0];
      this.vistaModel.source = this.filters_edit.config[0].source;
      this.vistaModel.filter_equations = this.filters_edit.config;
      this.filterId = this.filters_edit.value;
      // insert icon
      this.input = this.filters_edit.label.split(' ')[0];

      // insert components to edit filter
      // exc
      this.components = this.filters_edit.config.map((filter: any) => {
        if (
          filter.type_filter === 'state' &&
          filter.name_filter === 'estado_pedido'
        ) {
          return {
            type_filter: filter.type_filter,
            component: '',
            title_filter: filter.name_filter,
            svg: this.svg_filters[filter.name_filter],
            name_filter: filter.name_filter,
            source: filter.source,
            states: this.states_pedidos,
            multiple: true,
            selectedOptionEdit: filter.selectedOption,
          };
        } else if (
          filter.type_filter === 'state' &&
          filter.name_filter === 'transportadora'
        ) {
          return {
            type_filter: filter.type_filter,
            component: '',
            title_filter: filter.name_filter,
            svg: this.svg_filters[filter.name_filter],
            name_filter: filter.name_filter,
            source: filter.source,
            states: this.distribution_companies,
            multiple: true,
            selectedOptionEdit: filter.selectedOption,
          };
        } else if (
          filter.name_filter !== 'creacion_orden' &&
          filter.name_filter !== 'tienda'
        ) {
          return {
            type_filter: filter.type_filter,
            component: '',
            title_filter: filter.name_filter,
            svg: this.svg_filters[filter.name_filter],
            name_filter: filter.name_filter,
            source: filter.source,
            selectedOptionEdit: filter.selectedOption || filter.value,
            states: this.statesFilters[filter.name_filter],
            multiple: filter.multiple,
          };
        }
      });

      this.componentDate = this.filters_edit.config.map((filter: any) => {
        this.$set(this.enableDeleteDate, 0, false);
        this.$set(this.enableDeleteShop, 0, false);
        if (filter.name_filter === 'creacion_orden') {
          return {
            type_filter: filter.type_filter,
            component: '',
            title_filter: filter.name_filter,
            svg: this.svg_filters[filter.name_filter],
            name_filter: filter.name_filter,
            source: filter.source,
            selectedOptionEdit: filter.selectedOption,
            states: this.statesFilters[filter.name_filter],
            multiple: filter.multiple,
          };
        } else if (filter.name_filter === 'tienda') {
          return {
            type_filter: filter.type_filter,
            component: '',
            title_filter: filter.name_filter,
            svg: this.svg_filters[filter.name_filter],
            name_filter: filter.name_filter,
            source: filter.source,
            states: this.tokenByShop,
            multiple: filter.multiple,
            selectedOptionEdit: filter.selectedOption,
          };
        }
      });

      // remove undefined
      this.components = this.components.filter((component: any) => {
        return component !== undefined;
      }) as any;

      this.componentDate = this.componentDate.filter((component: any) => {
        return component !== undefined;
      }) as any;
      // remove creacion_orden
      // this.vistaModel.filter_equations =
      //   this.vistaModel.filter_equations.filter((filter: any) => {
      //     return filter.name_filter !== "creacion_orden";
      //   });

      this.componentDate.forEach((component: any) => {
        // set enable array
        if (component.name_filter === 'creacion_orden') {
          this.$set(this.enableArray, 3, true);
        }
        if (component.name_filter === 'tienda') {
          this.$set(this.enableArray, 5, true);
        }
      });
      this.components.forEach((component: any) => {
        // set enable array

        if (component.name_filter === 'estado_pedido') {
          this.$set(this.enableArray, 0, true);
        } else if (component.name_filter === 'transportadora') {
          this.$set(this.enableArray, 1, true);
        } else if (component.name_filter === 'id_producto') {
          this.$set(this.enableArray, 2, true);
        } else if (component.name_filter === 'creacion_orden') {
          this.$set(this.enableArray, 3, true);
        } else if (component.name_filter === 'fecha_creacion_contacto') {
          this.$set(this.enableArray, 4, true);
        } else if (component.name_filter === 'tienda') {
          this.$set(this.enableArray, 5, true);
        }
      });
    }
    //

    this.listen();
  }

  get hasDate(): boolean {
    // if exist creacion_orden or fecha_creacion_contacto and its the only filter
    const creacionOrdenFilter = this.vistaModel.filter_equations.find(
      (equation: any) => equation.name_filter === 'creacion_orden',
    );
    return !!creacionOrdenFilter;
  }

  get enableSave(): boolean {
    if (
      this.vistaModel.name_filter != '' &&
      this.vistaModel.filter_equations.length > 0 &&
      this.showFilter[0] &&
      this.vistaModel.icon != '' &&
      this.enableSaveArray_()
    ) {
      return true;
    } else {
      return false;
    }
  }

  get svg_filters(): any {
    return svg_filters;
  }

  get statesFilters(): any {
    return statesFilters;
  }

  // Check every option in enableSaveArray are true
  enableSaveArray_(): boolean {
    return Object.values(this.enableSaveArray).every(value => value);
  }

  changeFilter(value: string) {
    this.$set(this.showFilter, 0, false);
    this.selectFilter(value);
    this.select_filter = '+ Añadir Filtro';
    this.enableSaveArray[value] = false;
    // remove filter from array
    // document.getElementById display none

    // false, // estado_pedido
    // false, // transportadora
    // false, // id_producto
    // false, // creacion_orden
    // false, // fecha_creacion_contacto
    if (value === 'estado_pedido') {
      this.$set(this.enableArray, 0, true);
    } else if (value === 'transportadora') {
      this.$set(this.enableArray, 1, true);
    } else if (value === 'id_producto') {
      this.$set(this.enableArray, 2, true);
    } else if (value === 'creacion_orden') {
      this.$set(this.enableArray, 3, true);
    } else if (value === 'fecha_creacion_contacto') {
      this.$set(this.enableArray, 4, true);
    } else if (value === 'tienda') {
      this.$set(this.enableArray, 5, true);
    }

    // this.components.push(this.select_filter);
  }

  /**
   * @description: Función que se encarga de escuchar los cambios en los filtros
   * Si en los filtros se encuentra alguno de DROPI, se debe agregar el filtro de creacion de orden
   * No se puede agregar el filtro de creacion de orden si ya existe
   * No se puede eliminar el filtro de creacion de orden si existe otro filtro de DROPI
   * @param: void
   * @return: void
   */
  @Watch('components')
  onComponentsChanged() {
    const filterDROPI = this.components.find(
      component => component.source === 'DROPI',
    );
    const filterCreacionOrden = this.components.find(
      component => component.name_filter === 'creacion_orden',
    );

    const isCreacionOrden = this.componentDate.find(
      component => component.name_filter === 'creacion_orden',
    );
    const isTienda = this.componentDate.find(
      component => component.name_filter === 'tienda',
    );

    if (filterDROPI && !filterCreacionOrden && !isCreacionOrden) {
      // disable delete creacion_orden
      this.$set(this.enableDeleteDate, 0, false);
      this.$set(this.enableDeleteShop, 0, false);
      // disable add creacion_orden
      this.$set(this.enableArray, 3, true);
      this.$set(this.enableArray, 5, true);

      this.componentDate.push({
        type_filter: 'state',
        component: 'date-filter-component',
        title_filter: 'Fecha creación de la orden',
        svg: this.svg_filters.creacion_orden,
        name_filter: 'creacion_orden',
        source: 'DROPI',
        selectedOptionEdit: 'ultimos_30_dias',
        states: this.statesFilters['creacion_orden'],
      });
      this.componentDate.push({
        type_filter: 'state',
        component: 'date-filter-component',
        title_filter: 'Tienda',
        svg: this.svg_filters.tienda,
        name_filter: 'tienda',
        source: 'DROPI',
        selectedOptionEdit: [this.project.dropiUsers[0].token],
        states: this.tokenByShop,
        multiple: true,
      });
      this.vistaModel.filter_equations.unshift({
        name_filter: 'tienda',
        type_filter: 'state',
        selectedOption: [this.project.dropiUsers[0].token],
        source: 'DROPI',
        multiple: true,
      });
      this.vistaModel.filter_equations.unshift({
        name_filter: 'creacion_orden',
        type_filter: 'state',
        selectedOption: 'ultimos_30_dias',
        source: 'DROPI',
        multiple: false,
      });
    } else if (
      isCreacionOrden &&
      this.componentDate.length > 1 &&
      this.components.length < 1
    ) {
      this.$set(this.enableDeleteDate, 0, false);
      this.$set(this.enableDeleteShop, 0, true);
      // this.$set(this.enableArray, 3, false);
      this.$set(this.showFilter, 0, true);
    } else if (!isTienda && this.componentDate.length > 1) {
      this.componentDate.push({
        type_filter: 'state',
        component: 'date-filter-component',
        title_filter: 'Tienda',
        svg: this.svg_filters.tienda,
        name_filter: 'tienda',
        source: 'DROPI',
        selectedOptionEdit: [this.project.dropiUsers[0].token],
        states: this.tokenByShop,
        multiple: true,
      });
      this.vistaModel.filter_equations.unshift({
        name_filter: 'tienda',
        type_filter: 'state',
        selectedOption: [this.project.dropiUsers[0].token],
        source: 'DROPI',
        multiple: true,
      });
    } else if (
      !isTienda &&
      this.components.length <= 1 &&
      this.componentDate.length >= 1
    ) {
      // this.componentDate.push({
      //   type_filter: "state",
      //   component: "date-filter-component",
      //   title_filter: "Tienda",
      //   svg: this.svg_filters.tienda,
      //   name_filter: "tienda",
      //   source: "DROPI",
      //   selectedOptionEdit: [this.project.dropiUsers[0].token],
      //   states: this.tokenByShop,
      //   multiple: true,
      // });
      // this.vistaModel.filter_equations.unshift({
      //   name_filter: "tienda",
      //   type_filter: "state",
      //   selectedOption: [this.project.dropiUsers[0].token],
      //   source: "DROPI",
      //   multiple: true,
      // });
    }

    // remove duplicates from componentDate
    this.componentDate = this.componentDate.filter(
      (component: any, index: number, self: any) =>
        index ===
        self.findIndex((t: any) => t.name_filter === component.name_filter),
    ) as any;
  }

  selectFilter(value: string) {
    switch (value) {
      case 'fecha_creacion_contacto':
        this.components.push({
          type_filter: 'state',
          component: 'date-filter-component',
          title_filter: 'Fecha de creación del contacto',
          svg: this.svg_filters.fecha_creacion_contacto,
          name_filter: 'fecha_creacion_contacto',
          source: 'CHATCENTER',
          states: [
            {
              value: 'hoy',
              label: 'Hoy',
            },
            {
              value: 'ayer',
              label: 'Ayer',
            },
            {
              value: 'ultimos_7_dias',
              label: 'Últimos 7 días',
            },
            {
              value: 'ultimos_30_dias',
              label: 'Últimos 30 días',
            },
            {
              value: 'ultimos_90_dias',
              label: 'Últimos 90 días',
            },
            {
              value: 'ultimos_180_dias',
              label: 'Últimos 180 días',
            },
            {
              value: 'ultimos_365_dias',
              label: 'Últimos 365 días',
            },
          ],
          multiple: false,
        });
        break;
      case 'fecha_inicio_ultima_conversacion':
        this.components.push({
          type_filter: 'date',
          component: 'date-filter-component',
          title_filter: 'Fecha de inicio de la última conversación',
          svg: this.svg_filters.fecha_inicio_ultima_conversacion,
          name_filter: 'fecha_inicio_ultima_conversacion',
          source: 'CHATCENTER',
        });
        break;
      case 'fecha_cierre_conversacion':
        this.components.push({
          type_filter: 'date',
          component: 'date-filter-component',
          title_filter: 'Fecha de cierre de conversación',
          svg: this.svg_filters.fecha_cierre_conversacion,
          name_filter: 'fecha_cierre_conversacion',
          source: 'CHATCENTER',
        });
        break;
      case 'ultimo_origen_conversacion':
        this.components.push({
          type_filter: 'date',
          component: 'date-filter-component',
          title_filter: 'Último origen de conversación',
          svg: this.svg_filters.ultimo_origen_conversacion,
          name_filter: 'ultimo_origen_conversacion',
          source: 'CHATCENTER',
        });
        break;
      case 'fecha_creacion_orden':
        this.components.push({
          type_filter: 'date',
          component: 'date-filter-component',
          title_filter: 'Fecha de creación de la orden',
          svg: this.svg_filters.fecha_creacion_orden,
          name_filter: 'fecha_creacion_orden',
          source: 'DROPI',
        });
        break;
      case 'automatizacion_fecha':
        this.components.push({
          type_filter: 'date',
          component: 'date-filter-component',
          title_filter: 'Automatización Fecha',
          svg: this.svg_filters.automatizacion_fecha,
          name_filter: 'automatizacion_fecha',
          source: 'DROPI',
        });
        break;
      case 'ultima_automatizacion':
        this.components.push({
          type_filter: 'date',
          component: 'date-filter-component',
          title_filter: 'Última automatización',
          svg: this.svg_filters.ultima_automatizacion,
          name_filter: 'ultima_automatizacion',
          source: 'DROPI',
        });
        break;
      case 'estado_conversacion':
        this.components.push({
          type_filter: 'state',
          component: 'date-filter-component',
          title_filter: 'Estado de la conversación',
          svg: this.svg_filters.estado_conversacion,
          name_filter: 'estado_conversacion',
          source: 'CHATCENTER',
        });
        break;
      case 'estado_pedido':
        this.$set(this.enableDeleteDate, 0, false);
        this.$set(this.enableDeleteShop, 0, false);
        this.components.push({
          type_filter: 'state',
          component: 'state-filter-component',
          title_filter: 'Estado del pedido',
          svg: this.svg_filters.estado_pedido,
          name_filter: 'estado_pedido',
          source: 'DROPI',
          multiple: true,
          states: this.states_pedidos,
        });
        break;
      case 'etiqueta_finalizacion':
        this.components.push({
          type_filter: 'state',
          component: 'state-filter-component',
          title_filter: 'Etiqueta de finalización',
          svg: this.svg_filters.etiqueta_finalizacion,
          name_filter: 'etiqueta_finalizacion',
          source: 'CHATCENTER',
        });
        break;
      case 'motivo_finalizacion':
        this.components.push({
          type_filter: 'state',
          component: 'state-filter-component',
          title_filter: 'Motivo de finalización',
          svg: this.svg_filters.motivo_finalizacion,
          name_filter: 'motivo_finalizacion',
          source: 'CHATCENTER',
        });
        break;
      case 'etiqueta_orden':
        this.components.push({
          type_filter: 'state',
          component: 'state-filter-component',
          title_filter: 'Etiqueta de orden',
          name_filter: 'etiqueta_orden',
          svg: this.svg_filters.etiqueta_orden,
          source: 'DROPI',
        });
        break;
      case 'ultimo_asesor_asignado':
        this.components.push({
          type_filter: 'date',
          component: 'date-filter-component',
          title_filter: 'Último asesor asignado',
          svg: this.svg_filters.ultimo_asesor_asignado,
          name_filter: 'ultimo_asesor_asignado',
          source: 'CHATCENTER',
        });
        break;

      case 'transportadora':
        this.$set(this.enableDeleteDate, 0, false);
        this.$set(this.enableDeleteShop, 0, false);
        this.components.push({
          type_filter: 'state',
          component: 'state-filter-component',
          title_filter: 'Transportadora',
          svg: this.svg_filters.transportadora,
          name_filter: 'transportadora',
          source: 'DROPI',
          multiple: true,
          states: this.distribution_companies,
        });
        break;
      case 'id_producto':
        this.$set(this.enableDeleteDate, 0, false);
        this.$set(this.enableDeleteShop, 0, false);
        this.components.push({
          type_filter: 'input',
          component: 'state-filter-component',
          title_filter: 'Id Producto',
          svg: this.svg_filters.id_producto,
          name_filter: 'id_producto',
          source: 'DROPI',
        });
        break;
      case 'creacion_orden':
        const isTienda = this.componentDate.find(
          (component: any) => component.name_filter === 'tienda',
        );
        this.$set(this.showFilter, 0, true);

        if (isTienda) {
          this.componentDate.unshift({
            type_filter: 'state',
            component: 'date-filter-component',
            title_filter: 'Fecha creación de la orden',
            svg: this.svg_filters.creacion_orden,
            name_filter: 'creacion_orden',
            source: 'DROPI',
            selectedOptionEdit: 'ultimos_30_dias',
            states: this.statesFilters['creacion_orden'],
          });
          this.vistaModel.filter_equations.unshift({
            name_filter: 'creacion_orden',
            type_filter: 'state',
            selectedOption: 'ultimos_30_dias',
            source: 'DROPI',
            multiple: false,
          });
          this.$set(this.enableArray, 3, true);
        } else {
          // search if exist creacion_orden in componentsDate
          this.componentDate.push({
            type_filter: 'state',
            component: 'date-filter-component',
            title_filter: 'Fecha creación de la orden',
            svg: this.svg_filters.creacion_orden,
            name_filter: 'creacion_orden',
            source: 'DROPI',
            selectedOptionEdit: 'ultimos_30_dias',
            states: this.statesFilters['creacion_orden'],
          });
          this.componentDate.push({
            type_filter: 'state',
            component: 'date-filter-component',
            title_filter: 'Tienda',
            svg: this.svg_filters.tienda,
            name_filter: 'tienda',
            source: 'DROPI',
            selectedOptionEdit: [this.project.dropiUsers[0].token],
            states: this.tokenByShop,
            multiple: true,
          });
          this.vistaModel.filter_equations.unshift({
            name_filter: 'tienda',
            type_filter: 'state',
            selectedOption: [this.project.dropiUsers[0].token],
            source: 'DROPI',
            multiple: true,
          });
          this.vistaModel.filter_equations.unshift({
            name_filter: 'creacion_orden',
            type_filter: 'state',
            selectedOption: 'ultimos_30_dias',
            source: 'DROPI',
            multiple: false,
          });

          this.$set(this.enableArray, 3, true);
          this.$set(this.enableArray, 5, true);
          this.$set(this.enableDeleteDate, 0, false);
        }
        break;
      case 'tienda':
        const isCreacionOrden = this.componentDate.find(
          (component: any) => component.name_filter === 'creacion_orden',
        );
        this.$set(this.showFilter, 0, true);

        if (isCreacionOrden) {
          this.componentDate.push({
            type_filter: 'state',
            component: 'date-filter-component',
            title_filter: 'Tienda',
            svg: this.svg_filters.tienda,
            name_filter: 'tienda',
            source: 'DROPI',
            selectedOptionEdit: [this.project.dropiUsers[0].token],
            states: this.tokenByShop,
            multiple: true,
          });
          this.vistaModel.filter_equations.unshift({
            name_filter: 'tienda',
            type_filter: 'state',
            selectedOption: [this.project.dropiUsers[0].token],
            source: 'DROPI',
            multiple: true,
          });
          this.$set(this.enableArray, 5, true);
        } else {
          // search if exist creacion_orden in componentsDate
          this.componentDate.push({
            type_filter: 'state',
            component: 'date-filter-component',
            title_filter: 'Fecha creación de la orden',
            svg: this.svg_filters.creacion_orden,
            name_filter: 'creacion_orden',
            source: 'DROPI',
            selectedOptionEdit: 'ultimos_30_dias',
            states: this.statesFilters['creacion_orden'],
          });
          this.componentDate.push({
            type_filter: 'state',
            component: 'date-filter-component',
            title_filter: 'Tienda',
            svg: this.svg_filters.tienda,
            name_filter: 'tienda',
            source: 'DROPI',
            selectedOptionEdit: [this.project.dropiUsers[0].token],
            states: this.tokenByShop,
            multiple: true,
          });
          this.vistaModel.filter_equations.unshift({
            name_filter: 'tienda',
            type_filter: 'state',
            selectedOption: [this.project.dropiUsers[0].token],
            source: 'DROPI',
            multiple: true,
          });
          this.vistaModel.filter_equations.unshift({
            name_filter: 'creacion_orden',
            type_filter: 'state',
            selectedOption: 'ultimos_30_dias',
            source: 'DROPI',
            multiple: false,
          });

          this.$set(this.enableArray, 3, true);
          this.$set(this.enableArray, 5, true);
          this.$set(this.enableDeleteDate, 0, false);
        }
        break;
      default:
        break;
    }
    const isTienda = this.componentDate.find(
      component => component.name_filter === 'tienda',
    );

    if (
      !isTienda &&
      this.components.length <= 1 &&
      this.componentDate.length >= 1
    ) {
      this.componentDate.push({
        type_filter: 'state',
        component: 'date-filter-component',
        title_filter: 'Tienda',
        svg: this.svg_filters.tienda,
        name_filter: 'tienda',
        source: 'DROPI',
        selectedOptionEdit: [this.project.dropiUsers[0].token],
        states: this.tokenByShop,
        multiple: true,
      });
      this.vistaModel.filter_equations.unshift({
        name_filter: 'tienda',
        type_filter: 'state',
        selectedOption: [this.project.dropiUsers[0].token],
        source: 'DROPI',
        multile: true,
      });
    }
  }

  private listen(): void {
    this.EventBus.$on('close-filter', (value: any) => {
      const name = value.name_filter;
      const indices = {
        components: this.components.findIndex(c => c.name_filter === name),
        filterEquations: this.vistaModel.filter_equations.findIndex(
          (c: any) => c.name_filter === name,
        ),
        componentDate: this.componentDate.findIndex(
          (c: any) => c.name_filter === name,
        ),
      };

      if (indices.components !== -1) {
        this.$set(this.components, indices.components, { type_filter: '' });
      }
      if (indices.filterEquations !== -1)
        this.vistaModel.filter_equations.splice(indices.filterEquations, 1);
      if (indices.componentDate !== -1)
        this.componentDate.splice(indices.componentDate, 1);

      this.showFilter[0] = true;
      // delete
      delete this.enableSaveArray[value.name_filter];
      const enableArrayMap = {
        estado_pedido: 0,
        transportadora: 1,
        id_producto: 2,
        creacion_orden: 3,
        fecha_creacion_contacto: 4,
        tienda: 5,
      } as any;
      if (name in enableArrayMap) {
        this.$set(this.enableArray, enableArrayMap[name], false);
      }

      const isComponentEmpty = this.components.every(c => c.type_filter === '');

      if (isComponentEmpty) {
        this.$set(this.enableDeleteDate, 0, true);
        this.$set(this.enableDeleteShop, 0, true);
        this.components = [] as any;
      }
    });

    this.EventBus.$on('save-filter', (value: any) => {
      this.$set(this.showFilter, 0, true);
      this.enableSaveArray[value.name_filter] = true;
      const index = this.components.findIndex(
        c => c.name_filter === value.name_filter,
      );
      const indexDate = this.componentDate.findIndex(
        c => c.name_filter === value.name_filter,
      );

      if (indexDate !== -1) {
        this.vistaModel.filter_equations.splice(indexDate, 1, value);
      } else if (index !== -1) {
        this.vistaModel.filter_equations.splice(index + 2, 1, value);
      } else {
        this.vistaModel.filter_equations.push(value);
      }
    });

    this.EventBus.$on('enable-edit', (value: any) => {
      this.enableSaveArray[value.name_filter] = false;
      this.$set(this.showFilter, 0, false);
    });
  }

  async closeFilter(id: string) {
    if (
      this.vistaModel.name_filter.length > 0 ||
      this.vistaModel.filter_equations.length > 0
    ) {
      Modal.confirm({
        icon: undefined,
        centered: true,
        title: 'Descartar cambios',
        content: 'Se perderán los cambios realizados en el filtro',
        cancelText: 'Seguir editando',
        okType: 'danger',
        okText: 'Descartar cambios',
        maskClosable: true,
        // class cancel butto
        onOk: () => {
          this.EventBus.$emit('closeFilter', [false, id]);
        },
        onCancel() {},
      });
    } else {
      this.EventBus.$emit('closeFilter', [false, id]);
    }
    // await this.getFilters();

    // remove filter from array
  }

  async saveFilter() {
    if (this.enableSave && this.filterId.length > 0) {
      const url = `${BASE_URL_ORCHESTATOR}/filters/update/view/${this.filterId}`;
      const data = {
        name: this.vistaModel.name_filter,
        description: 'description',
        agent: this.$store.state.agent.agent._id,
        inbox: this.$store.state.agent.tabSelected,
        config: this.vistaModel.filter_equations,
        icon: this.vistaModel.icon,
        source: this.vistaModel.source,
      };
      try {
        this.loading = true;
        await axios.put(url, data);
        // sleep 3 seconds
        this.EventBus.$emit('closeFilter', [false, 2]);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
      // first event close filter, second event save filter , third event update filter
      this.EventBus.$emit('saveFilter', [
        true,
        data,
        false,
        this.filters_edit.value,
      ]);
    } else {
      const url = `${BASE_URL_ORCHESTATOR}/filters/create/view`;
      const currentView = this.$store.state.filters.currentView;

      const data = {
        name: this.vistaModel.name_filter,
        description: 'description',
        agent: this.$store.state.agent.agent._id,
        project: this.project._id,
        inbox: this.$store.state.agent.tabSelected,
        config: this.vistaModel.filter_equations,
        icon: this.vistaModel.icon,
        source: this.vistaModel.source,
        view: currentView,
      };
      try {
        this.loading = true;
        await axios.post(url, data);

        this.EventBus.$emit('closeFilter', [false, 2]);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
      // validate form}
      this.EventBus.$emit('saveFilter', [true, data, true]);
    }
    // await this.getFilters();
    // this.EventBus.$emit("closeFilter", [false, 2]);
    this.loading = false;

    // this.switchShowCreateFilter();
  }

  validateForm(prop: string, valid: boolean) {}

  append(value: any) {
    this.input = value;
    this.vistaModel.icon = value;
  }

  get tokenByShop(): any {
    const token = this.$store.state.agent!.agent!.projects[0].dropiUsers;
    const tokenByShop = token.map((t: any) => {
      return {
        value: t.token,
        label: t.name || 'Sin nombre',
        country: t.country || 'Sin país',
      };
    });
    return tokenByShop;
  }

  getURlDropi(country: string): string {
    switch (country) {
      case 'CO':
        return 'https://api.dropi.co';
      case 'MX':
        return 'https://api.dropi.mx';
      case 'PE':
        return 'https://api.dropi.pe';
      case 'CL':
        return 'https://api.dropi.cl';
      case 'PA':
        return 'https://api.dropi.pa';
      case 'EC':
        return 'https://api.dropi.ec';
      case 'ES':
        return 'https://api.dropi.com.es';
      case 'PY':
        return 'https://api.dropi.com.py';
      default:
        return 'https://api.dropi.co';
    }
  }

  async filterStateOptions() {
    const country = this.project.country;
    const url = this.getURlDropi(country);

    const response = await axios.get(
      `${url}/api/helpers/getStatusesByCountry`,
      {
        params: {
          country: this.project.country,
        },
      },
    );

    const data = response.data.objects;
    const states = data.map((d: any) => {
      return {
        value: d.id + (d.company ? `-${d.company}` : ''),
        label: d.name,
        company: d.company,
      };
    });
    // remove any filter that contains NOVEDAD in the value

    states.filter((state: any) => {
      if (state.value.includes('NOVEDAD')) {
        states.splice(states.indexOf(state), 1);
      }
    });

    states.push({
      value: 'NOVEDAD',
      label: 'NOVEDAD',
      company: undefined,
    });

    return states;
  }

  async filterTransportadoraOptions() {
    const country = this.project.country;
    const url = this.getURlDropi(country);
    const serviceHeaders = {
      'Content-Type': 'application/json',
      'dropi-integration-key': `${this.project.dropiUsers[0].token}`,
    };
    const response = await fetch(`${url}/integrations/distribution_companies`, {
      method: 'GET',
      headers: serviceHeaders,
    });
    const data = await response.json();

    const states = data.objects.map((d: any) => {
      return {
        value: d.id,
        label: d.name,
      };
    });
    return states;
  }

  /**
   * @description
   * Abre un modal para eliminar un filtro
   * @param {string} id
   * @memberof FilterComponent
   * @returns {void}
   */
  async deleteFilter(id: string): Promise<void> {
    Modal.confirm({
      icon: undefined,
      centered: true,
      title: 'Eliminar Filtro',
      content: '¿Está seguro que desea eliminar este filtro?',
      cancelText: 'Mantener filtro',
      okType: 'danger',
      okText: 'Eliminar filtro',
      maskClosable: true,
      // class cancel butto
      onOk: async () => {
        try {
          await axios.delete(
            `${BASE_URL_ORCHESTATOR}/filters/delete/view/${id}`,
          );
          await this.getFilters();
          // this.switchShowCreateFilter();
          this.EventBus.$emit('closeFilter', [false, id]);
        } catch (error) {
          // show error message in modal
          this.$notification.error({
            placement: 'bottomRight',
            message: 'Error al eliminar el filtro',
            description:
              'No se pudo eliminar el filtro, por favor intente de nuevo o contacte con su administrador.',
          });

          console.log(error);
        }
      },
      onCancel() {},
    });
  }

  /**
   * switch between boolean states of enableDeleteDate
   */
  switchEnableDeleteDate(): void {
    // using $set to update the array
    this.$set(this.enableDeleteDate, 0, !this.enableDeleteDate[0]);
    this.$set(this.enableDeleteShop, 0, !this.enableDeleteShop[0]);
  }

  /**
   * switch between boolean states of show_create_filter
   */
  switchShowCreateFilter(): void {
    // using $set to update the array
    this.show_create_filter = !this.show_create_filter;
    // reset vistaModel
    this.vistaModel = {
      name_filter: '',
      filter_equations: [],
      icon: '😃',
      source: '',
    };
    this.filterId = '';
    this.input = '😃';

    this.filters_edit_ = false;
    this.components = [
      {
        type_filter: '',
        title_filter: '',
        component: '',
        svg: '',
        name_filter: '',
        source: '',
        states: [],
        multiple: false,
        token: '',
        selectedOptionEdit: [],
      },
    ];
    this.componentDate = [
      {
        type_filter: '',
        title_filter: '',
        component: '',
        svg: '',
        name_filter: '',
        source: '',
        states: [],
        multiple: false,
        token: '',
        selectedOptionEdit: [],
      },
    ];
    this.enableArray = [false, false, false, false, false, false];
    this.showFilter = [true];
    this.componentDate.pop();
    this.components.pop();
  }

  /**
   * Quita la referencia del filtro seleccionado
   * @param {string} item
   */
  async unlinkFilter(item: Filter) {
    try {
      this.loading = true;
      const response = await axios.put(
        `${BASE_URL_ORCHESTATOR}/filters/unlink/view/${item.value}`,
        {
          agent: this.$store.state.agent.agent._id,
        },
      );
      if (response.status === 200) {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Filtro desvinculado',
          description: 'El filtro se ha desvinculado correctamente.',
        });
      }
      this.loading = false;
      //remove from filters
      this.$store.commit(filterMutationsTypes.removeFilterAgent(item.value));
      this.$store.commit(filterMutationsTypes.pushNewFIlterProject(item));
    } catch (error) {
      this.loading = false;

      this.$notification.error({
        placement: 'bottomRight',
        message: 'Error al desvincular el filtro',
        description:
          'No se pudo desvincular el filtro, por favor intente de nuevo o contacte con su administrador.',
      });
    }
  }

  /**
   * Asigna la referencia del filtro seleccionado
   * @param {string} item
   */
  async linkFilter(item: Filter) {
    try {
      this.loading = true;
      const response = await axios.put(
        `${BASE_URL_ORCHESTATOR}/filters/link/view/${item.value}`,
        {
          agent: this.$store.state.agent.agent._id,
        },
      );
      if (response.status === 200) {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Filtro vinculado',
          description: 'El filtro se ha vinculado correctamente.',
        });
      }
      // await this.getFilters();
      //remove from filters
      this.loading = false;
      this.$store.commit(filterMutationsTypes.removeFilterProject(item.value));
      this.$store.commit(filterMutationsTypes.pushNewFilterAgent(item));
    } catch (error) {
      this.loading = false;
      this.$notification.error({
        placement: 'bottomRight',
        message: 'Error al vincular el filtro',
        description:
          'No se pudo vincular el filtro, por favor intente de nuevo o contacte con su administrador.',
      });
    }
  }

  /**
   * @description
   * Obtiene los filtros del usuario
   * @memberof FilterComponent
   * @returns {void}
   */
  async getFilters(): Promise<void> {
    try {
      const currenteAgent = this.$store.state.agent.agent._id;
      const project = this.$store.getters.project;
      const currentView = this.$store.state.filters.currentView;
      this.$store.commit(filterMutationsTypes.setFilterAgent([]));
      this.$store.commit(filterMutationsTypes.setFiltersProject([]));
      const url = `${BASE_URL_ORCHESTATOR}/filters/views/project/${project._id}/agent/${currenteAgent}/view/${currentView}`;
      const response = await axios.get(url);
      if (response.status === 200) {
        response.data.filtrosAgente.forEach((filter: any) => {
          const ele = {
            label: (filter.icon ? filter.icon : '') + ' ' + filter.name,
            value: filter._id,
            config: filter.config,
            name: filter.name,
          };

          this.$store.commit(filterMutationsTypes.pushNewFilterAgent(ele));
        });
        response.data.filtros.forEach((filter: any) => {
          const ele = {
            label: (filter.icon ? filter.icon : '') + ' ' + filter.name,
            value: filter._id,
            config: filter.config,
            name: filter.name,
          };

          this.$store.commit(filterMutationsTypes.pushNewFIlterProject(ele));
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  editFilter(item: Filter) {
    this.vistaModel.name_filter = item.name;
    this.vistaModel.filter_equations = item.config;
    this.vistaModel.icon = item.label.split(' ')[0];
    this.input = this.vistaModel.icon;
    this.vistaModel.source = item.config[0].source;
    this.show_create_filter = true;
    this.filters_edit_ = true;
    this.filterId = item.value;
    // this.enableSave = true;
    this.components = item.config.map((filter: any) => {
      if (
        filter.type_filter === 'state' &&
        filter.name_filter === 'estado_pedido'
      ) {
        return {
          type_filter: filter.type_filter,
          component: '',
          title_filter: filter.name_filter,
          svg: this.svg_filters[filter.name_filter],
          name_filter: filter.name_filter,
          source: filter.source,
          states: this.states_pedidos,
          multiple: true,
          selectedOptionEdit: filter.selectedOption,
        };
      } else if (
        filter.name_filter === 'transportadora' &&
        filter.type_filter === 'state'
      ) {
        return {
          type_filter: filter.type_filter,
          component: '',
          title_filter: filter.name_filter,
          svg: this.svg_filters[filter.name_filter],
          name_filter: filter.name_filter,
          source: filter.source,
          selectedOptionEdit: filter.selectedOption || filter.value,
          states: this.distribution_companies,
          multiple: filter.multiple,
        };
      } else if (
        filter.name_filter !== 'creacion_orden' &&
        filter.name_filter !== 'tienda'
      ) {
        return {
          type_filter: filter.type_filter,
          component: '',
          title_filter: filter.name_filter,
          svg: this.svg_filters[filter.name_filter],
          name_filter: filter.name_filter,
          source: filter.source,
          selectedOptionEdit: filter.selectedOption || filter.value,
          states: this.statesFilters[filter.name_filter],
          multiple: filter.multiple,
        };
      }
    });

    this.componentDate = item.config.map((filter: any) => {
      this.$set(this.enableDeleteDate, 0, false);
      this.$set(this.enableDeleteShop, 0, false);
      if (filter.name_filter === 'creacion_orden') {
        return {
          type_filter: filter.type_filter,
          component: '',
          title_filter: filter.name_filter,
          svg: this.svg_filters[filter.name_filter],
          name_filter: filter.name_filter,
          source: filter.source,
          selectedOptionEdit: filter.selectedOption,
          states: this.statesFilters[filter.name_filter],
          multiple: filter.multiple,
        };
      } else if (filter.name_filter === 'tienda') {
        return {
          type_filter: filter.type_filter,
          component: '',
          title_filter: filter.name_filter,
          svg: this.svg_filters[filter.name_filter],
          name_filter: filter.name_filter,
          source: filter.source,
          states: this.tokenByShop,
          multiple: filter.multiple,
          selectedOptionEdit: filter.selectedOption,
        };
      }
    });

    // remove undefined
    this.components = this.components.filter((component: any) => {
      return component !== undefined;
    }) as any;

    this.componentDate = this.componentDate.filter((component: any) => {
      return component !== undefined;
    }) as any;
    // remove creacion_orden
    // this.vistaModel.filter_equations =
    //   this.vistaModel.filter_equations.filter((filter: any) => {
    //     return filter.name_filter !== "creacion_orden";
    //   });

    this.componentDate.forEach((component: any) => {
      // set enable array
      if (component.name_filter === 'creacion_orden') {
        this.$set(this.enableArray, 3, true);
      }
      if (component.name_filter === 'tienda') {
        this.$set(this.enableArray, 5, true);
      }
    });
    this.components.forEach((component: any) => {
      // set enable array

      if (component.name_filter === 'estado_pedido') {
        this.$set(this.enableArray, 0, true);
      } else if (component.name_filter === 'transportadora') {
        this.$set(this.enableArray, 1, true);
      } else if (component.name_filter === 'id_producto') {
        this.$set(this.enableArray, 2, true);
      } else if (component.name_filter === 'creacion_orden') {
        this.$set(this.enableArray, 3, true);
      } else if (component.name_filter === 'fecha_creacion_contacto') {
        this.$set(this.enableArray, 4, true);
      } else if (component.name_filter === 'tienda') {
        this.$set(this.enableArray, 5, true);
      }
    });
  }
  applyFilter(item: any) {
    console.log(item);
  }
}
