import { Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import ImageHolder from '@/app/components/shared/image-holder/ImageHolder.vue';
import FileHolder from '@/app/components/shared/file-holder/FileHolder.vue';

@Component({
  name: 'quick-response-list-element',
  components: { ImageHolder, FileHolder },
})
export default class QuickResponseListElement extends VueWizard {
  @Prop() readonly quickResponse!: any;
  @Prop() readonly type!: string;
  @Prop() readonly topicName!: string;
  @Prop() public EventBus!: any;

  public showLittle: boolean = true;
  public localEventBus: any = null;

  mounted() {
    this.localEventBus = this.EventBus;
  }

  deleteQuickResponse(quickResponseId: string) {
    this.$emit('delete', {
      quickResponseId: quickResponseId,
    });
  }

  sendQuickResponse() {
    this.$emit('send', {
      message: this.quickResponse.messages[0],
      url: this.quickResponse.url,
      fileName: this.quickResponse.messages[1],
      fileType: this.quickResponse.messages[2],
    });
  }

  largeMessage() {
    if (this.quickResponse.messages.toString().length > 201) {
      return true;
    }
    return false;
  }

  formatString(message: any) {
    let stringMessage = message.toString();
    stringMessage = stringMessage.substring(0, 201);
    return stringMessage;
  }

  toggleShowLittle() {
    this.showLittle = !this.showLittle;
  }
}
