import { Component, Watch, Vue } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import dayjs, { Dayjs } from 'dayjs';
import {
  BASE_URL_MANAGER,
  BASE_URL_ORCHESTATOR,
  BASE_URL_WHATSAPP_CLOUD,
  COUNTRIES,
} from '@/config';
import moment, { Moment } from 'moment';
import Axios, { CancelToken, CancelTokenSource, AxiosError } from 'axios';
import { storeTypes } from '@/app/store';
import {
  BusinessStatus,
  Conversation,
  InteractionOrigin,
  MessageType,
} from '@/app/store/modules/conversation/conversation.models';
import eventBus from '@/main';
import ConversationContent from '@/app/components/conversation/conversationContent/ConversationContent.vue';
import DropiInfoManager from '@/app/components/chat/dropi-info-manager/DropiInfoManager.vue';
import BusinessStatusSelector from '@/app/components/chat/business-status-selector/BusinessStatusSelector.vue';
import CustomButton from '../../components/conversation/actions/custom-button.vue';
import ContactDataManager from '@/app/components/chat/contact-data-manager/ContactDataManager.vue';
import FilterComponent from '@/app/components/filter-view2/Filter.vue';
import AgentSidebar from '@/app/views/agent-sidebar/agent-sidebar.vue';

import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { conversationMutationsTypes } from '@/app/store/modules/conversation/conversation.mutations';
import { filterMutationsTypes } from '@/app/store/modules/filters/filters.mutations';
import { agentActionsTypes } from '@/app/store/modules/agent/agent.actions';
import { Filter } from '@/app/store/modules/filters/filters.models';
import ConversationFormSend from '@/app/components/conversation/conversationFormSend/ConversationFormSend.vue';
import Swal from 'sweetalert2';
import axios from 'axios';
import { conversationActionsTypes } from '@/app/store/modules/conversation/conversation.actions';
import ChangeConversationStatusModal from '@/app/components/chat/change-conversation-status-modal/ChangeConversationStatusModal.vue';
import ResolveConversationModal from '@/app/components/chat/resolve-conversation-modal/ResolveConversationModal.vue';
import ImageHolderModal from '@/app/components/shared/image-holder-modal/ImageHolderModal.vue';
import TransferConversationModal from '@/app/components/chat/transfer-conversation-modal/TransferConversationModal.vue';
import NewQuickResponseModal from '@/app/components/agents-management/dedicated-components/new-quick-response-modal/NewQuickResponseModal.vue';
import { IProject } from '@/app/store/modules/user/user.models';

const locale = require('ant-design-vue/es/date-picker/locale/es_ES.js');

// Components

@Component({
  name: 'ordenes',
  components: {
    ConversationContent,
    DropiInfoManager,
    BusinessStatusSelector,
    CustomButton,
    PerfectScrollbar,
    ContactDataManager,
    FilterComponent,
    AgentSidebar,
    ConversationFormSend,
    ChangeConversationStatusModal,
    ResolveConversationModal,
    ImageHolderModal,
    TransferConversationModal,
    NewQuickResponseModal,
  },
})
export default class Ordenes extends VueWizard {
  // states vistas
  public switchTable = true;
  public switchList = false;
  public locale: any = locale.default;
  // first state today first 15 days, from now to 15 days ago
  // format [ "2023-08-17T04:59:59.999Z", "2023-09-29T19:01:12.892Z" ]
  // today
  public startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
  public endDate = moment().format('YYYY-MM-DD');

  public datePickerStart: any = [this.startDate, this.endDate];

  public ranges = {
    Hoy: [moment(), moment()],
    'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
    'Últimos 15 días': [moment().subtract(14, 'days'), moment()],
    'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
    'Mes pasado': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    'Este mes': [moment().startOf('month'), moment().endOf('month')],
  };
  public selected_ranges = '';
  // state switch
  public view = 'table';

  // search state
  public searchKeyWord = '';
  public searchType = 'name';
  public searchProducto = undefined;
  public placeHolderSearch = 'Buscar...';

  //state filter
  public currentFilter = 'all';
  public currentFilters: any = null;
  public loading = false;
  public filter_configs: any = null;
  public filters: any[] = [];
  public filters_admin: any[] = [];
  public visible = false;

  public select_filter = '+ Añadir Filtro';
  public showFilter: boolean[] = [false];

  public enableArray: boolean[] = [
    false, // estado_pedido // 0
    false, // transportadora // 1
    false, // id_producto // 2
    false, // creacion_orden // 3
    false, // fecha_creacion_contacto // 4
    false, // tienda // 5
  ];
  //ordenes
  public orders: any[] = [];
  public loadingOrders = false;
  public pagination = 0;
  public totalData = 0;
  public selectedOrder: any = {
    id: '',
  };
  // search state
  private searchTimer: any;
  private searchDelay = 500;
  public checked = false;
  private source: CancelTokenSource = Axios.CancelToken.source();
  private sourceFilter: CancelTokenSource = Axios.CancelToken.source();
  private abortController: AbortController = new AbortController();
  private signal: AbortSignal = this.abortController.signal;
  // state chat
  public conversation: any = null;
  public businessStatuses: BusinessStatus[] = [];
  public EventBus: Vue = new Vue();
  public isError = false;
  public errors: any = [];
  public showStatusSelector = false;
  public showTransferConversationModal = false;
  public showResolveConversationModal = false;
  public selectedStatus: BusinessStatus | null = null;
  public showSelectStatusModal = false;
  public dropiInfoManagerKey = 0;
  public dropiIncidenceStatus = {
    reconnecting: false,
    reconnectingIntervalId: 0,
    reconnectingRemainingTime: 15,
  };
  menuAddFilters = false;
  menuPosition = {
    top: '0px',
    left: '0px',
  };

  menuOption = false;
  menuOptionPosition = {
    top: '35px',
    left: '0px',
  };

  public instanceAxios = axios.create({
    baseURL: BASE_URL_MANAGER,
    timeout: 5000,
  });
  public totalOrders = 0;
  public translations = {
    countrySelectorLabel: 'Selecciona tu país',
    countrySelectorError: 'Selecciona tu país',
    phoneNumberLabel: 'Número de teléfono',
    example: 'Ejemplo :',
    countrySelector: {
      placeholder: 'Country codes',
      error: 'Choose countrys',
      searchPlaceholder: 'Search the countrys',
    },
  };
  public titleToltip = 'Puedes buscar por nombre, teléfono, ID de orden o guía';
  public tempPhone = '';
  public defaultPhoneNumber = '';
  public data: any;
  public isValid = false;
  public startConverstionObject: any = {
    phone: '',
    name: '',
    text: '',
  };
  public templates: any = [];

  public show_rigth_arrow = false;
  public show_left_arrow = false;
  public currentAgentId = '';
  public formatedFileName: any = '';
  public dataFileUrl: any = '';
  public unsubscribe: any;

  // mounted
  async mounted() {
    this.$store.commit(filterMutationsTypes.setFilterAgent([]));
    this.$store.commit(
      conversationMutationsTypes.setCurrentConversation({
        currentAgent: '',
        currentChannel: '',
        currentConsumer: '',
        currentInputInteraction: [],
        currentTopic: '',
        interactions: [],
        _id: '',
        newMessage: 0,
        variables: {},
        contactData: {},
        newinteractions: 0,
        updatedAt: '',
        createdAt: '',
        status: '',
        checked: false,
      }),
    );
    this.$store.commit(filterMutationsTypes.setCurrentView('orders'));
    await this.getOrders(this.source.token);
    await this.getFilters(this.sourceFilter.token);
    // for each order find the conversation
    this.businessStatuses = this.$store.getters.project.bussinesStatusList;

    let tableData = document.querySelector('.ant-table-body');

    if (tableData) {
      tableData.addEventListener('scroll', this.handleScroll);
    }

    this.listen();
    this.retrieveQuickResponses();

    this.EventBus.$on('closeFilter', async (e: any) => {
      this.$set(this.showFilter, 0, false);
      this.currentFilters = null;

      await this.getFilters(this.sourceFilter.token);
    });

    this.EventBus.$on('saveFilter', async (event: any) => {
      this.$set(this.showFilter, 0, false);
      await this.getFilters(this.sourceFilter.token);
      await this.applyFilter(event[3]);

      // active filter
    });

    window.addEventListener('click', (e: any) => {
      if (e.target.id === 'content-main') {
        this.closeWindowConversation();
      }
    });

    window.addEventListener('keydown', this.handleKeydown);
    let tabFilters = document.getElementById('ordenes-container__tabs-filters');
    if (tabFilters) tabFilters.addEventListener('scroll', this.closeOnScroll);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  }
  updated() {
    if (this.view === 'list') {
      let listData = document.getElementById('list');
      if (listData) {
        listData.addEventListener('scroll', this.handleScroll);
      }
    }
    if (this.view === 'table') {
      let tableData = document.querySelector('.ant-table-body');
      if (tableData) {
        tableData.addEventListener('scroll', this.handleScroll);
      }
    }
  }
  getRowClass(record: any) {
    return this.selectedOrder && this.selectedOrder.id === record.id
      ? 'selected-row'
      : '';
  }

  onFilter(value: any, record: any) {
    record.created_at.indexOf(value) === 0;
  }
  sortByDate(a: any, b: any) {
    moment.locale('es');

    const dateA = moment(a.created_at).format('YYYY-MM-DD');
    const dateB = moment(b.created_at).format('YYYY-MM-DD');
    if (dateA < dateB) {
      return -1;
    }
    return 1;
  }
  customRow(record: any) {
    return {
      on: {
        click: () => {
          if (record.conversation.originConversation !== 'DEFAULT') {
            // currentAgent possible null
            const agentID = record.conversation.currentAgent
              ? record.conversation.currentAgent._id
              : 'no';
            record.conversation.currentAgent = agentID;
            this.$store.commit(
              storeTypes.conversation.mutations.setCurrentConversation(
                record.conversation,
              ),
            );
            this.selectedOrder = record;
          } else {
            Swal.fire({
              title: '¿Deseas iniciar una conversación?',
              text: 'No se ha encontrado una conversación para esta orden',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Si',
              cancelButtonText: 'No',
            }).then(async result => {
              if (result.isConfirmed) {
                this.openStartConversationDialog(record.phone);
              }
            });
          }
        },
      },
    };
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeWindowConversation();
    }
  }
  get finishBusinessStatuses(): BusinessStatus[] {
    return this.businessStatuses.filter(
      (businessStatus: BusinessStatus) => businessStatus.finish,
    );
  }
  get agent(): any {
    return this.$store.state.agent!.agent;
  }
  get hasToken(): boolean {
    return this.project.dropiUsers.length > 0;
  }
  get currentAgent() {
    return this.$store.state.agent!.agent;
  }

  get filters_encola() {
    return this.$store.state.filters!.filters_agent || [];
  }
  get filters_encola_admin() {
    return this.$store.state.filters!.filters_project || [];
  }

  @Watch('currentFilter')
  findFilter() {
    const config = this.filters_encola.find(
      (filter: any) => filter.value === this.currentFilter,
    );
    if (config) {
      this.filter_configs = config.config;
    } else {
      this.filter_configs = null;
    }
  }

  @Watch('agent')
  async getProject(): Promise<any> {
    const businessStatuses: BusinessStatus[] = [];
    if (this.agent.projects) {
      this.agent.projects.forEach((project: any) => {
        let projectId = project._id != undefined ? project._id : project;

        Axios(`${BASE_URL_MANAGER}/project/${projectId}`).then(response => {
          response.data.project.bussinesStatusList.forEach(
            (element: BusinessStatus) => businessStatuses.push(element),
          );
        });
      });
    }
    this.businessStatuses = businessStatuses;
  }

  private listen(): void {
    eventBus.$on('Error-Interaction', (payload: any) => {
      if (payload.code === 'TIMEOUT' || payload.code === 131047) {
        this.isError = payload.isError;
        this.errors = payload.errors;
      } else {
        this.isError = false;
        this.errors = [];
      }
    });
    eventBus.$on('takeConversation', (conversation: any) => {
      this.grebConversation(conversation);
    });
    eventBus.$on('closeConversation', () => {
      this.showResolveConversationModal = true;
    });

    eventBus.$on('transferConversation', () => {
      this.showTransferConversationModal = true;
    });
  }

  async selectConversation(record: any) {
    if (record.conversation.originConversation !== 'DEFAULT') {
      this.$store.commit(
        storeTypes.conversation.mutations.setCurrentConversation(
          record.conversation,
        ),
      );
      this.selectedOrder = record;
    } else {
      Swal.fire({
        title: '¿Deseas iniciar una conversación?',
        text: 'No se ha encontrado una conversación para esta orden',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          this.openStartConversationDialog(record.phone);
        }
      });
    }
  }

  async handleScroll(event: any) {
    const bottomOfWindow =
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight - 5;

    if (!bottomOfWindow) return;
    if (this.searchTimer) clearTimeout(this.searchTimer);
    this.source.cancel('Operation canceled by the user.');
    this.source = Axios.CancelToken.source();

    const fetchData = async () => {
      this.loadingOrders = true;
      if (this.currentFilter === 'all') {
        await this.getOrders(this.source.token);
      } else {
        await this.getConversationsByFilter(
          this.currentFilter,
          this.source.token,
        );
      }
      this.loadingOrders = false;
    };

    this.searchTimer = setTimeout(fetchData, this.searchDelay);
  }

  /**
   * Metodo para cambiar de vista
   * @param view
   * @returns
   */
  public changeView(view: string) {
    this.view = view;
    this.hiddenSettings();
    this.closeConversation();
  }

  public showModal(): void {
    if (this.showFilter[0]) {
      this.$set(this.showFilter, 0, false);
    } else {
      this.$set(this.showFilter, 0, true);
    }
    this.closeMenuFilter();
    // this.showFiltersAdmin();
  }

  /**
   * Metodo encargado de saber en que filtro se encuentra
   * @param filter
   */
  async changeFilter(filter: any) {
    this.pagination = 0;
    if (filter === 'all') {
      if (this.currentFilter === filter) return;
      this.currentFilter = 'all';
      this.orders = [];
      this.source.cancel('Operation canceled by the user.');
      this.source = Axios.CancelToken.source();
      this.searchTimer = setTimeout(async () => {
        this.orders = [];
        await this.getOrders(this.source.token);
        this.hiddenSettings();
      }, this.searchDelay);
    } else {
      this.searchKeyWord = '';
      this.pagination = 0;
      if (this.currentFilter === filter.value) return;

      this.currentFilter = filter.value;
      if (this.searchTimer) clearTimeout(this.searchTimer);
      this.source.cancel('Operation canceled by the user.');
      this.source = Axios.CancelToken.source();
      this.searchTimer = setTimeout(async () => {
        this.orders = [];
        await this.getConversationsByFilter(filter.value, this.source.token);
        this.hiddenSettings();
      }, this.searchDelay);
    }
  }
  async applyFilter(IdFilter: string) {
    this.pagination = 0;
    this.orders = [];
    this.source.cancel('Operation canceled by the user.');
    this.source = Axios.CancelToken.source();
    this.searchTimer = setTimeout(async () => {
      this.orders = [];
      await this.getConversationsByFilter(IdFilter, this.source.token);
      this.currentFilter = IdFilter;
      this.hiddenSettings();
    }, this.searchDelay);
  }

  /**
   * Metodo encargado de manejar el buscador
   * @param searchKeyWord
   */
  async onSearchChange(): Promise<void> {
    if (this.searchTimer) clearTimeout(this.searchTimer);
    this.source.cancel('Operation canceled by the user.');
    this.source = Axios.CancelToken.source();
    this.searchTimer = setTimeout(async () => {
      this.loadingOrders = true;
      this.orders = [];
      this.pagination = 0;
      if (this.currentFilter === 'all') {
        await this.getOrders(this.source.token);
        this.hiddenSettings();
      } else {
        await this.getConversationsByFilter(
          this.currentFilter,
          this.source.token,
        );
      }
    }, this.searchDelay);
  }

  /**
   * Metodo encargado de recibir el evento de cambio de busqueda
   */
  async onChangeRadioButton(option: any) {
    if (option.target.value !== 'product') {
      this.searchType = option.target.value;
      this.searchProducto = undefined;
      // change placeholder CELULAR, name, ORDEN ID, GUIA, product
      switch (this.searchType) {
        case 'CELULAR':
          this.placeHolderSearch = 'Buscar por teléfono';
          break;
        case 'name':
          this.placeHolderSearch = 'Buscar por nombre';
          break;
        case 'ORDEN ID':
          this.placeHolderSearch = 'Buscar por ID de orden';
          break;
        case 'GUIA':
          this.placeHolderSearch = 'Buscar por guía';
          break;

        default:
          this.placeHolderSearch = 'Buscar por teléfono';
          break;
      }
      this.pagination = 0;
      this.getOrders(this.source.token);
    } else {
      this.placeHolderSearch = 'Buscar por producto';
      this.searchProducto = option.target.value;
    }

    // hide settings
    // this.showSettings();
  }

  /**
   * Metodo encargado de mostrar las configuraciones en la busquedad,
   * busca el elemento en el dom y le agrega el estilo para mostrarlo
   */
  showSettings(event: any) {
    if (this.menuOption) return;
    this.menuOption = true;
    // center of the element
    this.menuOptionPosition = {
      left: `${event.target.offsetLeft}px`,
      top: `${event.target.offsetTop + 40}px`,
    };

    document.addEventListener('mousedown', this.closeMenuOutsideSettings);
  }

  hiddenSettings() {
    this.menuOption = false;
    document.removeEventListener('mousedown', this.closeMenuOutsideSettings);
  }

  closeMenuOutsideSettings(event: any) {
    if (event.target.id === '') {
      this.hiddenSettings();
    }
  }

  showFiltersAdmin(event: any) {
    if (this.menuAddFilters) return;
    this.menuAddFilters = true;
    // center of the element
    // if event.target.offsetLeft + 40 px + 320px > window.innerWidth
    // then left = window.innerWidth - 320px
    // else left = event.target.offsetLeft + 40px
    const left =
      event.target.offsetLeft + 40 + 320 > window.innerWidth
        ? window.innerWidth - 320
        : event.target.offsetLeft;
    // if currentView === 'orders' then top = event.target.offsetTop + 40px
    // else top = event.target.offsetTop + 40px - 200px
    const top =
      this.view === 'table'
        ? event.target.offsetTop + 40
        : event.target.offsetTop + 40;

    this.menuPosition = {
      left: `${left}px`,
      top: `${top}px`,
    };

    document.addEventListener('mousedown', this.closeMenuOutsideFilter);
  }

  closeMenuFilter() {
    this.menuAddFilters = false;
    document.removeEventListener('mousedown', this.closeMenuOutsideFilter);
  }
  closeMenuOutsideFilter(event: any) {
    if (event.target.id === '') {
      this.closeMenuFilter();
    }
  }
  @Watch('searchKeyWord')
  emptySearch(): void {
    if (this.searchKeyWord.length == 0) {
      this.orders = [];
      this.pagination = 0;
      this.getOrders(this.source.token);
    } else {
      this.pagination = 0;
      this.getOrders(this.source.token);
      this.changeFilter('all');
    }
  }

  async created(): Promise<any> {
    this.EventBus.$on('image-modal', (values: any) => {
      setTimeout(() => this.enable(this.formatedFileName), 500);
      this.formatedFileName = values.formatedFileName;
      this.dataFileUrl = values.dataFileUrl;
    });

    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'setAgent') {
        this.retrieveQuickResponses();
        this.currentAgentId = this.agent._id;
      }
    });
  }
  enable(id: string): void {
    $(`#${id}`).modal('show');
  }
  async getOrders(cancelToken: CancelToken) {
    try {
      const project = this.$store.getters.project;

      this.loadingOrders = true;

      // this.orders = [];
      const fetchOrderData = async (dropiUser: any) => {
        let { country, token, name } = dropiUser;
        if (!country) {
          country = project!.country;
        }
        // parse blank space to %0A
        const keyWord = this.searchKeyWord;

        const url = `${BASE_URL_ORCHESTATOR}/filters/conversations/myOrders`;

        const responseAxios = await axios.get(url, {
          params: {
            token: token,
            country: country,
            keyWord: keyWord,
            startDate: this.startDate,
            endDate: this.endDate,
            pagination: this.pagination,
            project: project._id,
            searchType: this.searchType,
          },
          cancelToken,
        });

        const data = responseAxios.data;
        this.totalOrders = data.count;
        return { objects: data.result, country, token, name };
      };

      const dropiUsersData = await Promise.all(
        (project.dropiUsers || []).map(fetchOrderData),
      );

      dropiUsersData.forEach((response: any) => {
        if (response.objects) {
          response.objects.forEach((order: any) => {
            this.orders.push({
              ...order,
              shop_country: response.country,
              shop_name: response.name,
              tokenDropi: response.token,
            });
          });
        }
      });

      this.pagination += 20;
      this.totalData = this.orders.length;

      // remove duplicates
      this.orders = this.orders.filter(
        (order, index, self) =>
          index === self.findIndex(t => t.id === order.id),
      );

      this.loadingOrders = false;
    } catch (err) {}
  }
  loadMoreOrders() {
    this.getOrders(this.source.token);
  }
  async getConversationsByFilter(filter: string, cancelToken: CancelToken) {
    try {
      this.loadingOrders = true;

      const currenteAgent = this.$store.state.agent.agent._id;
      const project = this.$store.getters.project;
      const textToSearch = this.searchKeyWord;
      const pagination = this.pagination;
      const searchProducto = this.searchProducto;
      const searchType = this.searchType;
      const url = `${BASE_URL_ORCHESTATOR}/filters/conversations/${currenteAgent}/v3`;
      const data = {
        filter: filter,
        token: project.dropiUsers,
        country: project.country,
        pagination: pagination,
        textToSearch,
        searchProducto,
        searchType,
      };

      const resp = await Axios.post(url, data, { cancelToken });
      if (resp.status == 200) {
        const response = resp.data;
        this.orders.push(...response.result);
      }
      this.pagination += 20;
      this.totalData = this.orders.length;
      this.totalOrders = resp.data.count;
      this.loadingOrders = false;
    } catch (e) {
      this.loadingOrders = false;
      const error = e as AxiosError;
      console.log(error);
    }
  }
  getAreaCode(country: string): number {
    switch (country) {
      case 'CO':
        return 57;
      case 'MX':
        return 52;
      case 'PA':
        return 507;
      case 'EC':
        return 593;
      case 'PE':
        return 51;
      case 'CL':
        return 56;
      case 'ES':
        return 34;
      case 'PY':
        return 595;
      default:
        return 57;
    }
  }

  getDropiUrl(country: string): string {
    switch (country) {
      case 'CO':
        return COUNTRIES.CO;
      case 'MX':
        return COUNTRIES.MX;
      case 'PA':
        return COUNTRIES.PA;
      case 'EC':
        return COUNTRIES.EC;
      case 'PE':
        return COUNTRIES.PE;
      case 'CL':
        return COUNTRIES.CL;
      case 'ES':
        return COUNTRIES.ES;
      case 'PY':
        return COUNTRIES.PY;

      default:
        return COUNTRIES.CO;
    }
  }
  formatUpdatedDate(date: Date | string, showTime: boolean): string {
    moment.locale('es');
    if (showTime) {
      return moment(date).format('DD/MM/YYYY - hh:mm');
    }
    return moment(date).format('DD/MM/YYYY');
  }

  formatName(conversation: any) {
    if (
      conversation &&
      conversation.currentAgent &&
      conversation.currentAgent.user &&
      conversation.currentAgent.user.name
    ) {
      const { firstName, lastName } = conversation.currentAgent.user.name;
      // capital letter
      return `${firstName.charAt(0).toUpperCase()}${firstName.slice(
        1,
      )} ${lastName.charAt(0).toUpperCase()}${lastName.slice(1)}`;
    } else {
      return 'Sin agente';
    }
  }

  /**
   * Metodo encargado de clasificar la conversacion, si el status es
   * IN_QUEUE, la bandeja es en cola
   * AUTO, NEW_AUTO y no tiene agente asignado, la bandeja es auto
   * ACTIVE, la bandeja es activa
   * RESOLVED, la bandeja es resuelta
   */
  classifyConversation(conversation: any) {
    if (conversation.status === 'IN_QUEUE' || conversation.status === 'NEW') {
      return 'Cola';
    } else if (
      conversation.status === 'AUTO' ||
      conversation.status === 'NEW_AUTO'
    ) {
      return 'Automatización sin respuesta';
    } else if (
      conversation.status === 'ACTIVE' ||
      conversation.status === 'TRANSFERRED'
    ) {
      return 'Activas';
    } else if (conversation.status === 'RESOLVED') {
      return 'Listas';
    } else {
      return 'Sin conversación';
    }
  }

  /**
   * Metodo encargado de manejar el cambio de fecha en el filtro
   * para la busqueda de ordenes
   *
   *
   * @param date
   *
   */

  onChangeCalendar(date: any) {
    if (date.length === 2) {
      this.startDate = date[0].format('YYYY-MM-DD');
      this.endDate = date[1].format('YYYY-MM-DD');
      this.orders = [];
      this.pagination = 0;
      this.getOrders(this.source.token);
      const range = this.isInRanges(this.startDate, this.endDate);
      this.selected_ranges = range;
    }
  }

  /**
   * Verificar si el rango de fechas entra en los rangos prestablecidos
   * si no, devolver "custom"
   */
  isInRanges(startDate: string, endDate: string) {
    const ranges: { [key: string]: Moment[] } = this.ranges;
    let range = 'custom';
    for (const key in ranges) {
      if (ranges.hasOwnProperty(key)) {
        const element = ranges[key];
        if (
          element[0].format('YYYY-MM-DD') === startDate &&
          element[1].format('YYYY-MM-DD') === endDate
        ) {
          range = key;
          return range;
        }
      }
    }
    return range;
  }
  /**
   * Metodo encargado de desabilitar las fechas en el calendario
   * Solo se puede de hoy hacia atras
   */
  disableDate(current: Dayjs) {
    return current && current > dayjs().endOf('day');
  }

  closeWindowConversation() {
    this.selectedOrder = {
      id: '',
    };
    this.$store.commit(
      conversationMutationsTypes.setCurrentConversation({
        currentAgent: '',
        currentChannel: '',
        currentConsumer: '',
        currentInputInteraction: [],
        currentTopic: '',
        interactions: [],
        _id: '',
        newMessage: 0,
        variables: {},
        contactData: {},
        newinteractions: 0,
        updatedAt: '',
        createdAt: '',
        status: '',
        checked: false,
      }),
    );
  }

  async closeConversation() {
    let tempCurrentConversation = this.currentConversation;

    await this.$store.dispatch(
      storeTypes.conversation.actions.closeConversation(),
    );
    const currentConversation =
      this.$store.state.agent!.agentCloseConversations.length;
    this.$store.dispatch(
      storeTypes.conversation.actions.setTotalCloseConversation(
        currentConversation,
      ),
    );
    this.selectedOrder = {
      id: '',
    };
    if (tempCurrentConversation._id !== '')
      this.updateStatusConversationClose(tempCurrentConversation);
  }

  async getFilters(cancelToken: CancelToken) {
    try {
      const currenteAgent = this.$store.state.agent.agent._id;
      const project = this.$store.getters.project;
      const currentView = this.$store.state.filters.currentView;
      const url = `${BASE_URL_ORCHESTATOR}/filters/views/project/${project._id}/agent/${currenteAgent}/view/${currentView}`;
      const resp = await Axios.get(url, { cancelToken });
      if (resp.status == 200) {
        this.show_rigth_arrow = true;
        // separar los filtros por inbox
        this.$store.commit(filterMutationsTypes.setFilterAgent([]));
        this.$store.commit(filterMutationsTypes.setFiltersProject([]));

        resp.data.filtrosAgente.forEach((filter: any, index: number) => {
          const completRows = Math.floor(index / 3);
          const ele = {
            label: (filter.icon ? filter.icon : '') + ' ' + filter.name,
            value: filter._id,
            config: filter.config,
            name: filter.name,
            menuVisible: false,
            menuPosition: {
              top: `-${completRows}px`,
              left: '0px',
            },
          };

          // remove duplicates
          if (
            filter.inbox === 'encola' &&
            !this.filters.find((f: any) => f.value === ele.value)
          ) {
            this.$store.commit(filterMutationsTypes.pushNewFilterAgent(ele));
          }
        });
        resp.data.filtros.forEach((filter: any) => {
          const ele = {
            label: (filter.icon ? filter.icon : '') + ' ' + filter.name,
            value: filter._id,
            config: filter.config,
            name: filter.name,
            menuVisible: false,
            menuPosition: {
              top: '0px',
              left: '0px',
            },
          };

          this.filters_encola_admin.find((f: any) => f.value === ele.value)
            ? null
            : this.$store.commit(
                filterMutationsTypes.pushNewFIlterProject(ele),
              );
        });
        // agentTopics.forEach((topic: string) => {
        //   this.agentTopics.push(topic);
        // });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al obtener los filtros`,
          description: `Error: ${resp.status}`,
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      this.$notification.error({
        placement: 'bottomRight',
        message: `Hubo un error al obtener los filtros`,
        description: `Error: ${error.message}`,
      });
    }
  }
  getUserName(item: any): any {
    let userName = '';
    let conversation: Conversation;
    if (item.variables) {
      conversation = item;
    } else {
      conversation = item.conversation;
    }
    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME
    ) {
      userName = conversation.variables.CONSUMER_NAME.value;
    }
    return userName;
  }
  getUserPicture(item: any): any {
    let profileurl = '';

    let conversation: Conversation;
    if (item.variables) {
      conversation = item;
    } else {
      conversation = item.conversation;
    }

    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME
    ) {
      profileurl =
        'https://ui-avatars.com/api/?name=' +
        conversation.variables.CONSUMER_NAME.value +
        '&size=64&background=e0f5ff&color=007ab4&font-size=0.25&bold=true';
    }

    return profileurl;
  }
  getBusinessStatus(status: string): BusinessStatus | undefined {
    return this.businessStatuses.find(
      (businessStatus: BusinessStatus) => businessStatus.name === status,
    );
  }
  // todo lo de chat conversaciones
  get currentConversation(): any {
    return this.$store.state.conversation!.currentConversation;
  }
  get currentCloseConversations(): any {
    return this.$store.state.agent!.agentCloseConversations.filter(
      (conversation: any) =>
        conversation.currentConsumer ===
        this.$store.state.conversation!.currentConversation.currentConsumer,
    );
  }
  get error(): any {
    return this.$store.state.conversation!.error;
  }
  get project(): IProject {
    return this.$store.getters.project;
  }
  get noFinishBusinessStatuses(): BusinessStatus[] {
    return this.businessStatuses.filter(
      (businessStatus: BusinessStatus) => !businessStatus.finish,
    );
  }
  public get showActionButtons() {
    const isQueued = this.isCurrentConversationQueued();
    if (isQueued) {
      return true;
    }
    return false;
  }
  public isCurrentConversationQueued() {
    const currentConversation: any = this.currentConversation;
    if (
      currentConversation.status === 'IN_QUEUE' ||
      currentConversation.status === 'AUTO' ||
      currentConversation.status === 'NEW' ||
      currentConversation.status === 'NEW_AUTO'
    ) {
      return true;
    }
    return false;
  }
  public isCurrentConversationResolved() {
    const currentConversation: any = this.currentConversation;
    if (currentConversation.status === 'RESOLVED') {
      return true;
    }
    return false;
  }

  public isCurrentConversationOpen() {
    const currentConversation: any = this.currentConversation;
    if (
      currentConversation.status === 'ACTIVE' ||
      currentConversation.status === 'TRANSFERRED' ||
      currentConversation.status === 'AUTO'
    ) {
      return true;
    }
    return false;
  }

  public isCurrentConversationEmpty() {
    const currentConversation: any = this.currentConversation;

    if (currentConversation.status === '') {
      return true;
    }
    return false;
  }

  public isMyConversation() {
    const currentAgent: string = this.$store.state.agent.agent._id;
    const currentConversationAgent: any = this.currentConversation.currentAgent;
    if (
      currentConversationAgent === null ||
      currentConversationAgent === undefined
    )
      return false;

    if (currentAgent === currentConversationAgent) {
      return true;
    } else {
      return false;
    }
  }
  public get showChatFooter() {
    const isEmpty = this.isCurrentConversationEmpty();
    const isOpen = this.isCurrentConversationOpen();
    const isMine = this.isMyConversation();
    if (!isEmpty && isOpen && isMine) {
      return true;
    }
    return false;
  }
  public spinConversation = false;
  public agentQuickResponses: any = [];
  public topicQuickResponses: any = [];

  takeConversation() {
    this.grebConversation(this.currentConversation);
  }
  closeConversations() {
    this.showResolveConversationModal = true;
  }
  transferConversation() {
    this.showTransferConversationModal = true;
  }
  public get showRetakeConversationButton() {
    const isResolved = this.isCurrentConversationResolved();
    if (isResolved) {
      return true;
    }
    return false;
  }
  retrieveQuickResponses(): void {
    this.retrieveAgentQuickResponses();
    this.retrieveTopicQuickResponses();
  }

  retrieveTopicQuickResponses(): void {
    let actualAgent = this.$store.state.agent!.agent;
    let project = this.$store.state.agent!.agent.subscriptions[0].split('_')[0];
    let projectTopics = [];
    let suscribedTopics: any = [];
    let userSubscriptions: any = [];

    let url = `${BASE_URL_MANAGER}/topic/${project}`;
    let urlQuickResponses = '';

    this.topicQuickResponses = [];

    Axios.get(url).then(res => {
      projectTopics = res.data.projectTopics;

      actualAgent.subscriptions.forEach((sub: any) => {
        let splitedSub = sub.split('_');
        splitedSub.shift();
        userSubscriptions.push(splitedSub.join('_'));
      });

      projectTopics.forEach((projectTopic: any) => {
        userSubscriptions.forEach((subscription: any) => {
          if (subscription === projectTopic.name) {
            suscribedTopics.push(projectTopic._id);
          }
        });
      });

      let topicQuickResponsesId: any = [];
      suscribedTopics.forEach((topic: any) => {
        urlQuickResponses = `${BASE_URL_MANAGER}/quickResponse/topic/${topic}`;
        Axios.get(urlQuickResponses).then(res => {
          if (res !== undefined) {
            res.data.forEach((quickResponse: any) => {
              if (!topicQuickResponsesId.includes(quickResponse._id)) {
                topicQuickResponsesId.push(quickResponse._id);
                this.topicQuickResponses.push(quickResponse);
              }
            });
          } else {
            this.topicQuickResponses = [];
          }
        });
      });
    });
  }
  async retrieveAgentQuickResponses() {
    let url = `${BASE_URL_MANAGER}/quickResponse/${this.currentAgent._id}`;
    try {
      const response = await Axios.get(url);
      this.agentQuickResponses = response.data;
    } catch (error) {
      this.agentQuickResponses = [];
    }
  }
  async sendQuickResponse(values: any): Promise<any> {
    const message = values.message;
    if (values.fileName == '') {
      this.sendQuickResponseMessage(message);
    } else {
      const url = values.url;
      if (url == '') {
        this.sendQuickResponseMessage(message);
      } else {
        this.sendQuickResponseMessage(message).then(() => {
          this.sendQuickResponseFile(url, values.fileName, values.fileType);
        });
      }
    }
  }
  async sendQuickResponseMessage(send: string): Promise<any> {
    return new Promise(async resolve => {
      const currentTextMessage: any = [send];
      const idTextMessage = Math.random().toString(36).substring(2, 9);
      this.EventBus.$emit('sendingMessage', idTextMessage);
      await this.$store.dispatch(
        storeTypes.conversation.actions.sendMessage({
          id: idTextMessage,
          type: MessageType.MESSAGE,
          text: currentTextMessage,
          origin: InteractionOrigin.RSP,
        }),
      );
      this.EventBus.$emit('sentMessage', idTextMessage);
      resolve(true);
    });
  }
  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.$notification.success({
      placement: 'bottomRight',
      message: `Texto copiado`,
      description: `El texto se ha copiado al portapapeles`,
    });
  }
  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer
      ? item.currentConsumer
      : item.consumer
      ? item.consumer
      : '';
    if (consumer.includes('@')) consumer = consumer.split('@')[0];
    return consumer;
  }
  async sendQuickResponseFile(
    send: any,
    fileName: any,
    fileType: any,
  ): Promise<any> {
    const currentFileUrl: string = send;
    const idTextMessage = Math.random().toString(36).substring(2, 9);
    //fileName = fileName.split('/')[1].split('_')[1];
    this.EventBus.$emit('sendingMessage', idTextMessage);
    if (fileType === 'image') {
      await this.$store.dispatch(
        storeTypes.conversation.actions.sendMessage({
          id: idTextMessage,
          type: MessageType.IMAGE,
          fileurl: currentFileUrl,
          filename: fileName,
          origin: InteractionOrigin.RSP,
        }),
      );
    } else if (fileType === 'file') {
      await this.$store.dispatch(
        storeTypes.conversation.actions.sendMessage({
          id: idTextMessage,
          type: MessageType.FILE,
          fileurl: currentFileUrl,
          filename: fileName,
          origin: InteractionOrigin.RSP,
        }),
      );
    }
    this.EventBus.$emit('sentMessage', idTextMessage);
  }

  deleteQuickResponse(values: any): void {
    let quickResponseId = values.quickResponseId;
    let url = `${BASE_URL_MANAGER}/quickResponse/${quickResponseId}`;
    Axios.delete(url).then(res => {
      if (res.status == 200) {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Respuesta rápida eliminada exitosamente',
          description: 'Los datos han sido eliminados',
        });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información ${res.status}`,
          description: `Error: ${res.status}`,
        });
      }
      this.retrieveQuickResponses();
    });
  }
  notifyOpenModal() {
    eventBus.$emit('openModal');
  }
  toggleRightSidebar() {
    const el = this.$refs.rightSidebarRef as HTMLElement;
    if (el.classList.contains('hide-right-sidebar-tablet')) {
      el.classList.toggle('hide-right-sidebar');
    } else {
      el.classList.remove('hide-right-sidebar-tablet');
    }
  }
  onSelectedStatus(event: BusinessStatus): void {
    this.selectedStatus = event;
    this.showStatusSelector = false;
    this.showSelectStatusModal = true;
  }
  handleIncidenceStatus(data: any) {
    this.dropiIncidenceStatus = {
      ...data,
    };
  }

  grebConversation(conversation: any): void {
    // Remove class from body
    setTimeout(() => {
      $('body').removeClass('swal2-height-auto');
    }, 75);

    this.$store.commit(storeTypes.agent.mutations.setTabSelected('activas'));

    this.$store
      .dispatch(storeTypes.conversation.actions.grebConversation(conversation))
      .then(response => {
        if (response) {
          if (
            this.$store.state.agent!.agentQueueTotal <=
            this.$store.state.agent!.agentQueuePerPage
          ) {
            this.$store.dispatch(
              storeTypes.agent.actions.loadAgentQueues({
                subscriptions: this.$store.state.agent!.agent.subscriptions,
                page: this.$store.state.agent!.actualPage,
                restartQueue: true,
              }),
            );
          }

          this.$store.dispatch(
            storeTypes.agent.actions.updateTotalConversationsOpen(
              this.$store.state.agent!.agentOpenConversations.length,
            ),
          );
          this.$store.dispatch(agentActionsTypes.orderConversations('newest'));
          this.$store.dispatch(
            conversationMutationsTypes.setCurrentConversation(conversation),
          );
          this.updateStatusConversation(conversation);
        } else {
          this.fireSwalGrabError();
        }
      })
      .catch(this.fireSwalGrabError);
  }
  async fireSwalGrabError(): Promise<void> {
    setTimeout(() => {
      $('body').removeClass('swal2-height-auto');
    }, 75);
    await Swal.fire({
      icon: 'error',
      title: 'Ha ocurrido un problema',
      text: 'Alguien mas tomo esta conversación antes que tu',
    });
  }

  openMenu(item: any, event: any) {
    if (event.button === 0) {
      const rect = event.target.getBoundingClientRect();
      const left = `${rect.left - 115}px`;
      const top = `${rect.top + window.scrollY + rect.height + 10}px`;

      const payload = {
        left,
        top,
        index: item.value,
        value: true,
      };

      this.$store.commit(filterMutationsTypes.setOpenMenu(payload));
      document.addEventListener('mousedown', this.closeMenuOutside);
      // close menu on scroll
    }
  }
  closeMenu() {
    this.$store.commit(filterMutationsTypes.setCloseMenu());
    document.removeEventListener('mousedown', this.closeMenuOutside);
  }

  closeMenuOutside(event: any) {
    if (
      event.target.id === 'editFilter' ||
      event.target.id === 'unlinkFilter'
    ) {
    } else {
      this.closeMenu();
    }
  }
  closeOnScroll() {
    this.closeMenu();
  }

  async unlinkFilter(item: Filter) {
    try {
      this.loading = true;
      const response = await axios.put(
        `${BASE_URL_ORCHESTATOR}/filters/unlink/view/${item.value}`,
        {
          agent: this.$store.state.agent.agent._id,
        },
      );
      if (response.status === 200) {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Filtro desvinculado',
          description: 'El filtro se ha desvinculado correctamente.',
        });
      }
      if (this.currentFilter === item.value) {
        await this.changeFilter('all');
      }
      this.loading = false;
      //remove from filters
      this.$store.commit(filterMutationsTypes.removeFilterAgent(item.value));
      this.$store.commit(filterMutationsTypes.pushNewFIlterProject(item));
      this.closeMenuFilter();
    } catch (error) {
      this.loading = false;

      this.$notification.error({
        placement: 'bottomRight',
        message: 'Error al desvincular el filtro',
        description:
          'No se pudo desvincular el filtro, por favor intente de nuevo o contacte con su administrador.',
      });
    }
  }

  /**
   * Asigna la referencia del filtro seleccionado
   * @param {string} item
   */
  async linkFilter(item: Filter) {
    try {
      this.loading = true;
      const response = await axios.put(
        `${BASE_URL_ORCHESTATOR}/filters/link/view/${item.value}`,
        {
          agent: this.$store.state.agent.agent._id,
        },
      );
      if (response.status === 200) {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Filtro vinculado',
          description: 'El filtro se ha vinculado correctamente.',
        });
      }
      // await this.getFilters();
      //remove from filters
      this.loading = false;
      this.$store.commit(filterMutationsTypes.removeFilterProject(item.value));
      this.$store.commit(filterMutationsTypes.pushNewFilterAgent(item));

      this.closeMenuFilter();
    } catch (error) {
      this.loading = false;
      this.$notification.error({
        placement: 'bottomRight',
        message: 'Error al vincular el filtro',
        description:
          'No se pudo vincular el filtro, por favor intente de nuevo o contacte con su administrador.',
      });
    }
  }
  editFilter(item: Filter) {
    this.currentFilters = item;
    this.closeMenu();
    this.closeMenuFilter();
  }

  onUpdate(data: any) {
    this.data = data;
    // is valid
    this.isValid = data.isValid;
    this.defaultPhoneNumber = this.data.nationalNumber;
    this.tempPhone = this.data.nationalNumber;
  }

  onClear() {
    this.data = null;
    this.isValid = false;
  }
  async onChangeTemplate(e: any) {
    // this.startConverstionObject.template = e;
    let body = await this.startConverstionObject.template.components.find(
      (e: any) => e.type == 'BODY',
    );
    this.startConverstionObject.text = await body.text;
  }
  async startConversation() {
    try {
      let channel = '';

      let node: any = {
        type: 'TEXT',
        text: [this.startConverstionObject.text],
      };

      //todo:guardar esto
      switch (this.project!.integrator) {
        case 'DROPI':
          channel = 'WHATSAPP_CLOUD';
          break;
        case 'MESSAGEBIRD':
          channel = 'WHATSAPP_MESSAGEBIRD';
          break;
        default:
          channel = 'WHATSAPP_CLOUD';
          break;
      }

      if (this.tempPhone.startsWith('0')) {
        this.tempPhone = this.tempPhone.substring(1);
      }

      this.startConverstionObject.phone = this.tempPhone
        .split(' ')
        .join('')
        .substring(1);

      /**
       * primero busco si ya existe una conversation para el phone number y chanel
       * que esten en status activa o trnasferida, con un agente diferente al actual
       * si no encuentra nada entonces continua, sino, bota unerror diciendo que ya hay un agente dueñiol de esa conversacion
       */
      Axios(`${BASE_URL_ORCHESTATOR}/conversation/get/byconsumerandchannel`, {
        params: {
          project: this.project._id,
          currentChannel: channel,
          consumer: [this.startConverstionObject.phone],
          currentConsumer: this.startConverstionObject.phone,
        },
      })
        .then(async (res: any) => {
          let { data } = res;
          let conversation = data.conversation;

          //cuando una conversacion es activa o ha sido trnasferida a un agente, se debe validar si ya etsa en una conevrsacion con otro agente para no permitir tomarla
          if (
            conversation != null &&
            conversation.currentAgent &&
            conversation.currentAgent != null &&
            conversation.currentAgent != this.$store.state.agent.agent._id &&
            (conversation.status == 'ACTIVE' ||
              conversation.status == 'TRANSFERRED')
          ) {
            //'El mensaje no se puede enviar porque  el número de destino ya se encuentra en una una conversación activa con otro agente',
            this.$notification.error({
              placement: 'bottomRight',
              message: `Error`,
              description: `El mensaje no se puede enviar porque el número de destino ya se encuentra en una una conversación activa con otro agente`,
            });
          } else {
            let respMessage = await Axios.post(
              `${BASE_URL_WHATSAPP_CLOUD}/user/${this.startConverstionObject.phone}`,
              {
                message: node,
                idmessase: null,
                isForwarded: false,
                idreference: null,
                conversation_id: null,
                apiKey: this.project.accessToken,
                phoneNumberId: this.project.phone_number_id,
                wabaId: this.project.bussinnes_account_id,
                template: this.startConverstionObject.template,
              },
            );

            let dataMessage = respMessage!.data!.data;

            if (dataMessage.error) {
              this.$notification.error({
                placement: 'bottomRight',
                message: `Error`,
                description: `${dataMessage.error.error_data.details}`,
              });
            }
            const currentAgent = this.$store.state.agent.agent._id;

            let data = {
              nodo: node,
              conversation: {
                project: this.project._id,
                currentChannel: channel,
                consumer: [this.startConverstionObject.phone],
                currentConsumer: this.startConverstionObject.phone,
                topics: this.$store.state.agent.agent.subscriptions,
                show_in_queue: true,
                isTemplate: true,
                agent: currentAgent,
              },
              msgidentity: {
                idmessage: dataMessage!.resp!.data!.messages[0]!.id,
              },
              consumerName: this.startConverstionObject.name,
            };

            let url = `${BASE_URL_ORCHESTATOR}/conversation/interaction/output`;

            let respConversation = await Axios.post(url, data);

            let conversation = respConversation!.data!.conversation;

            conversation.currentConsumer = this.startConverstionObject.phone;

            if (respConversation.status == 200) {
              this.$store.commit(
                conversationMutationsTypes.setCurrentConversation(conversation),
              );
              this.onClear();
              // this.$store.commit(
              //   agentMutationsTypes.setAgentOpenConversations([conversation])
              // );

              this.grebConversation(conversation);

              Axios(
                `${BASE_URL_ORCHESTATOR}/agent/${
                  this.$store.state.agent.agent._id
                }/conversation/grab/${conversation!._id}`,
              )
                .then(res => {
                  let modal: any = $('#start-conversation');
                  modal.modal('hide');
                  this.startConverstionObject = {};
                })
                .catch(e => {
                  this.$notification.error({
                    placement: 'bottomRight',
                    message: `Error al tomar la conversacion`,
                    description: `Error: ${e!.message}`,
                  });
                })
                .finally(() => {
                  let modal: any = $('#start-conversation');
                  modal.modal('hide');
                  this.startConverstionObject = {};
                });
            } else {
              this.$notification.error({
                placement: 'bottomRight',
                message: `Hubo un error al guardar al información ${respConversation.status}`,
                description: `Error: ${respConversation.status}`,
              });
            }
          }
        })
        .catch(e => {
          this.$notification.error({
            placement: 'bottomRight',
            message: `Error al tomar la conversacion`,
            description: `No se pudo tomar la conversación`,
          });
        });
    } catch (e) {
      this.$notification.error({
        placement: 'bottomRight',
        description: `Hubo un error al guardar al información`,
        message: `Error:`,
      });
    }
  }

  getTemplates() {
    Axios.get(BASE_URL_MANAGER + '/templates', {
      params: {
        project_id: this.project._id,
      },
    }).then(async res => {
      if (res.status == 200) {
        this.templates = res!
          .data!.templates.map((t: any) => {
            const dontHaveParams = t.components.filter(
              (e: any) =>
                e.type == 'BODY' &&
                e.text != undefined &&
                e!.text.indexOf('{') < 0,
            );
            return {
              ...t,
              dontHaveParams,
            };
          })
          .filter((t: any) => {
            return t.dontHaveParams.length > 0 /*&& t.status === 'APPROVED'*/;
          });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información ${res.status}`,
          description: `Error: ${res.status}`,
        });
      }
      this.retrieveQuickResponses();
    });
  }
  isInvalidValidStartConversationForm() {
    if (!this.isValid) {
      return true;
    }

    if (
      this.startConverstionObject.text == undefined ||
      this.startConverstionObject.text == ''
    ) {
      return true;
    }
    if (this.tempPhone == undefined || this.tempPhone == '') {
      return true;
    }
    if (
      this.startConverstionObject.template == undefined ||
      this.startConverstionObject.template == ''
    ) {
      return true;
    }

    return false;
  }
  openStartConversationDialog(phone: string): void {
    this.startConverstionObject = {
      phone,
      name: '',
      text: '',
    };
    this.tempPhone = phone;
    this.defaultPhoneNumber = this.tempPhone;

    // clear mzphone input using clear event
    this.data = [];

    if (this.templates.length == 0) {
      this.getTemplates();
    }
    let modal: any = $('#start-conversation');
    modal.modal('show');
  }

  // modales
  async onResolveByBusinessStatus(event: BusinessStatus): Promise<void> {
    this.showResolveConversationModal = false;
    //store.commit(conversationMutationsTypes.setLoading(true));
    await this.changeConversationStatus(event);
    await this.closeConversation();
    //  store.commit(conversationMutationsTypes.setLoading(false));
    Vue.$toast.success('La conversacion ha sido cerrada', {
      timeout: 2000,
    });
    await this.getOrders(this.source.token);
    console.timeEnd('test');
  }
  async changeConversationStatus(event: BusinessStatus): Promise<any> {
    this.selectedStatus = null;
    this.showSelectStatusModal = false;
    this.$store.commit(conversationMutationsTypes.setLoading(true));
    return new Promise((resolve, reject) => {
      this.$store
        .dispatch(conversationActionsTypes.changeConversationStatus(event))
        .then(async () => {
          // await this.loadAgent();
          this.$notification.success({
            placement: 'bottomRight',
            message: 'Cambio realizado',
            description: `El estado de la conversación ha cambiado`,
          });
          resolve(true);
        })
        .catch(error => {
          this.$notification.error({
            message: 'Ha ocurrido un problema',
            description: error.message,
          });

          reject(true);
        })
        .finally(() =>
          this.$store.commit(conversationMutationsTypes.setLoading(false)),
        );
    });
  }
  onModalNoConfirm(): void {
    this.selectedStatus = null;
    this.showSelectStatusModal = false;
    this.showResolveConversationModal = false;
    this.showTransferConversationModal = false;
  }
  async transferConversationToAgent(fullName: string): Promise<void> {
    await this.sendQuickResponse([
      `La conversación ha sido transferida a ${fullName}`,
    ]);
  }
  async onResolveNormally(): Promise<void> {
    this.showResolveConversationModal = false;
    // store.commit(conversationMutationsTypes.setLoading(true));
    await this.closeConversation();
    // store.commit(conversationMutationsTypes.setLoading(false));
    Vue.$toast.success('La conversacion ha sido cerrada', {
      timeout: 2000,
    });
  }

  /**
   *  Metodo encargado de actualizar el estado de la conversacion, buscando por id de la conversacion
   *  en Orchestator y actualizando el estado de la conversacion en la tabla de conversaciones
   * @param conversation
   */
  async updateStatusConversation(conversation: any) {
    try {
      const url = `${BASE_URL_ORCHESTATOR}/conversation/conversations/${conversation._id}`;
      const response = await Axios.get(url);
      if (response.status === 200) {
        const conversation = response.data.conversation;
        for (let i = 0; i < this.orders.length; i++) {
          if (this.orders[i].conversation._id === conversation._id) {
            this.orders[i].conversation = conversation;
          }
        }
        this.$store.commit(
          conversationMutationsTypes.setCurrentConversation(conversation),
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async updateStatusConversationClose(conversation: any) {
    try {
      const url = `${BASE_URL_ORCHESTATOR}/conversation/conversations/${conversation._id}`;
      const response = await Axios.get(url);
      if (response.status === 200) {
        const conversation = response.data.conversation;
        for (let i = 0; i < this.orders.length; i++) {
          if (this.orders[i].conversation._id === conversation._id) {
            this.orders[i].conversation = conversation;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  formatLanguageTemplate(template: any) {
    switch (template.language) {
      case 'es':
        return 'Español';
      case 'en':
        return 'Ingles';
      case 'pt':
        return 'Portugues';
      default:
        return 'Español';
    }
  }

  formatNameTemplate(template: any) {
    const rawName = template.name;
    const name = rawName
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    const language = this.formatLanguageTemplate(template);
    const status = template.status === 'APPROVED' ? 'Aprobado' : 'Pendiente';
    return `${name} (${language}) - ${status}`;
  }
}
