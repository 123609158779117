import { Component, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { BusinessStatus } from '@/app/store/modules/conversation/conversation.models';
import { fromEvent, Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import $ from 'jquery';
const _ = require('lodash');

@Component({ name: 'business-status-selector' })
export default class BusinessStatusSelector extends VueWizard {
  @Prop()
  public businessStatuses!: BusinessStatus[];
  @Prop()
  public actualBusinessStatus!: BusinessStatus;

  public selectedBusinessStatus = null;
  public outClickSubscription: Subscription | null = null;

  mounted(): void {
    const container = $('#conversation-statuses');
    timer(100).subscribe(() => {
      this.outClickSubscription = fromEvent<MouseEvent>(document, 'click')
        .pipe(
          filter((event: MouseEvent) => {
            return event.target
              ? !container.is(event.target as any) &&
                  container.has(event.target as any).length === 0
              : false;
          }),
        )
        .subscribe(() => this.$emit('outClick'));
    });
  }

  beforeDestroy(): void {
    if (this.outClickSubscription) {
      this.outClickSubscription.unsubscribe();
    }
  }

  get orderedBusinessStatuses(): BusinessStatus[] {
    if (this.actualBusinessStatus) {
      return this.businessStatuses.filter(
        status => status.name !== this.actualBusinessStatus.name,
      );
    } else {
      return this.businessStatuses;
    }
  }

  selectBusinessStatus(businessStatus: BusinessStatus): void {
    if (
      !this.actualBusinessStatus ||
      businessStatus.name !== this.actualBusinessStatus.name
    ) {
      this.$emit('statusSelected', businessStatus);
    }
  }

  statusColorStyle(status: BusinessStatus): any {
    const rgb = this.hexToRgb(status.color);
    return {
      '--label-bg-color-r': rgb ? rgb.r : 0,
      '--label-bg-color-g': rgb ? rgb.g : 0,
      '--label-bg-color-b': rgb ? rgb.b : 0,
    };
  }

  hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
}
