import { render, staticRenderFns } from "./BusinessStatusSelector.vue?vue&type=template&id=6dd07704&scoped=true"
import script from "./business-status-selector.ts?vue&type=script&lang=ts&external"
export * from "./business-status-selector.ts?vue&type=script&lang=ts&external"
import style0 from "./BusinessStatusSelector.vue?vue&type=style&index=0&id=6dd07704&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dd07704",
  null
  
)

export default component.exports