import { Component, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { ContactData } from '@/app/store/modules/conversation/conversation.models';
import Axios from 'axios';
import { BASE_URL_ORCHESTATOR } from '@/config';
import { store } from '@/app/store';
import { conversationActionsTypes } from '@/app/store/modules/conversation/conversation.actions';
const _ = require('lodash');

@Component({ name: 'contact-data-manager' })
export default class ContactDataManager extends VueWizard {
  public contactData: ContactData | null = null;
  public arrayContactData: any[] = [];
  public missingContactData: any[] = [];
  public editingContactData = 0;

  public toFillKey: any = null;
  public toFillData: any = null;

  async mounted(): Promise<any> {
    await this.getContactData();
  }

  get currentConversation(): any {
    return store.state.conversation!.currentConversation;
  }

  getMissingContactData(): void {
    this.missingContactData = [
      'name',
      'lastName',
      'phoneNumber',
      'address',
      'city',
      'email',
      'identification',
      'postalCode',
    ];
    this.arrayContactData.forEach(contactData =>
      this.missingContactData.splice(
        this.missingContactData.indexOf(contactData.originalKey),
        1,
      ),
    );
  }

  async editContactData(contactData: any): Promise<any> {
    (this.contactData as any)[contactData.originalKey] = contactData.value;
    await this.sendNewContactData();
    this.editingContactData = 0;
  }

  @Watch('currentConversation')
  async getContactData(): Promise<any> {
    if (this.currentConversation.contactData) {
      this.contactData = _.cloneDeep(this.currentConversation.contactData);
    } else {
      this.contactData = {};
    }
    this.getArrayContactData();
    this.getMissingContactData();
  }

  getArrayContactData(): void {
    this.arrayContactData = [];
    if (this.contactData) {
      Object.entries(this.contactData).forEach(([key, value]) => {
        this.arrayContactData.push({
          originalKey: key,
          key: this.translateKey(key),
          value: value,
          icon: this.keyIcon(key),
        });
      });
    }
  }
  async setContactData(): Promise<any> {
    let tempData: any = {};

    tempData[this.toFillData.originalKey] = this.toFillData.value;
    this.contactData = { ...this.contactData, ...tempData };
    this.toFillData = null;
    this.toFillKey = null;
    this.getArrayContactData();
    this.getMissingContactData();
    await this.sendNewContactData();
  }

  changeToFillData(): void {
    this.toFillData = {
      originalKey: this.toFillKey,
      key: this.translateKey(this.toFillKey),
      value: '',
      icon: this.keyIcon(this.toFillKey),
    };
  }

  async sendNewContactData(): Promise<any> {
    const url = `${BASE_URL_ORCHESTATOR}/agent/${
      store.state.agent!.agent._id
    }/conversation/contactData/${this.currentConversation._id}`;
    try {
      await Axios.post(url, this.contactData);
      if (this.contactData) {
        store.dispatch(
          conversationActionsTypes.changeCurrentConversationContactData(
            this.contactData,
          ),
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  translateKey(key: string): string {
    switch (key) {
      case 'name':
        return 'Nombre';
      case 'lastName':
        return 'Apellido';
      case 'phoneNumber':
        return 'Telefono';
      case 'address':
        return 'Dirección';
      case 'city':
        return 'Ciudad';
      case 'email':
        return 'Correo';
      case 'identification':
        return 'Identificación';
      case 'postalCode':
        return 'Codigo postal';
      default:
        return 'Sin definir';
    }
  }

  keyIcon(key: string): string {
    switch (key) {
      case 'name':
        return 'user';
      case 'lastName':
        return 'signature';
      case 'phoneNumber':
        return 'phone';
      case 'address':
        return 'map-marker';
      case 'city':
        return key;
      case 'email':
        return 'envelope';
      case 'identification':
        return 'id-card';
      case 'postalCode':
        return 'mail-bulk';
      default:
        return 'user';
    }
  }
}
