import { VueWizard } from '@/vue-wizard';
import { Component } from 'vue-property-decorator';
import $ from 'jquery';
import { store, storeTypes } from '@/app/store';
import { userTypes } from '@/app/store/modules/user';

@Component({
  name: 'agent-sidebar',
})
export default class AgentSidebar extends VueWizard {
  public showModal = false;

  async mounted(): Promise<any> {
    // DarkMode with LocalStorage
    if ($('#dark-mode-toggle').length > 0) {
      let darkMode = localStorage.getItem('darkMode');

      const enableDarkMode = () => {
        document.body.classList.add('darkmode');
        localStorage.setItem('darkMode', 'enabled');
      };

      if (darkMode === 'enabled') {
        enableDarkMode();
      }
    }
  }

  async logout(): Promise<any> {
    const modal: any = $('#closeSessionModal');
    modal.modal('hide');
    await store.dispatch(storeTypes.auth.actions.logout());
  }

  get FullName(): string {
    const firstName = this.user.name.firstName || '';
    const lastName = this.user.name.lastName || '';
    return `${firstName} ${lastName}`;
  }

  get email(): string {
    return this.user.username || '';
  }
  get initials(): string {
    const firstName = this.user.name.firstName.charAt(0).toUpperCase() || '';
    const lastName = this.user.name.lastName.charAt(0).toUpperCase() || '';
    return `${firstName}${lastName}`;
  }
  get isLoggedIn(): boolean {
    return store.getters[userTypes.getters.isLoggedIn];
  }

  get loadingLogin(): boolean {
    return store.state.user!.loading;
  }

  get user(): any {
    return store.state.user!.user;
  }
}
