import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';

import { Prop } from 'vue-property-decorator';

@Component({
  name: 'float-button',
})
export default class FloatButton extends VueWizard {
  @Prop() readonly name!: string;
  @Prop() readonly function!: any;
  @Prop() readonly type!: string;

  get icon() {
    return this.type === 'document' ? 'mdi-file-document' : 'mdi-file-image';
  }

  get color() {
    return this.type === 'document' ? 'blue' : 'green';
  }

  parseClass() {
    return `__${this.type}`;
  }

  parseSVGClass() {
    return `__${this.type}`;
  }
}
