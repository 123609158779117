import Axios from 'axios';
import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { store } from '@/app/store';
import { BASE_URL_MANAGER } from '@/config';
import QuickResponseListElement from '@/app/components/conversation/quick-response-list-element/QuickResponseListElement.vue';
import { Prop } from 'vue-property-decorator';
import * as bootstrap from 'bootstrap';
import $ from 'jquery';

@Component({
  name: 'conversation-quick-response',
  components: { QuickResponseListElement },
})
export default class ConversationQuickResponse extends VueWizard {
  @Prop() readonly agentQuickResponses!: any;
  @Prop() readonly topicQuickResponses!: any;
  @Prop() readonly currentAgentId!: any;
  @Prop() public EventBus!: Vue;

  public visible: number = 1;
  public topicIds: any = [];
  public topics: any = [];

  enableCreateQuickResponse(id: string) {
    this.EventBus.$emit('reload-modal');
    this.enable(id);
  }

  showAgents() {
    this.visible = 1;
  }

  showTopics() {
    this.visible = 2;
  }

  enable(id: string) {
    $('#' + id).modal('show');
  }

  mounted() {
    this.obtainTopics();
    this.retrieveTopics();
  }

  deleteQuickResponse(values: any) {
    this.$emit('delete', values);
  }

  sendQuickResponse(quickResponseMessage: any) {
    this.$emit('send', quickResponseMessage);
  }

  obtainTopics() {
    this.topicQuickResponses.forEach((quickResponse: { topic: any }) => {
      if (!this.topicIds.includes(quickResponse.topic)) {
        this.topicIds.push(quickResponse.topic);
      }
    });
  }

  retrieveTopics() {
    let agent = store.state.agent!.agent;
    let projectId = agent.subscriptions[0].split('_')[0];
    let url = `${BASE_URL_MANAGER}/topic/${projectId}`;

    Axios.get(url).then(res => {
      this.topics = res.data.projectTopics;
    });
  }

  obtainTopicName(index: number) {
    let name: string[] = [];
    this.topics.forEach((topic: { _id: any; name: any }) => {
      this.topicQuickResponses[index].topic.forEach(
        (quickResponseTopic: any) => {
          if (topic._id === quickResponseTopic) {
            name.push(topic.name);
          }
        },
      );
    });
    return name;
  }

  close() {
    this.$emit('close');
  }
}
