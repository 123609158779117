export default {
  Frecuentes: {
    pulgar_hacia_arriba: '👍',
    pulgar_hacia_abajo: '👎',
    sollozo: '😭',
    confuso: '😕',
    cara_neutral: '😐',
    sonrojo: '😊',
    ojos_del_corazón: '😍',
  },
  Gente: {
    sonreír: '😄',
    sonriente: '😃',
    sonriendo: '😀',
    sonrojo: '😊',
    guiño: '😉',
    ojos_del_corazón: '😍',
    besando_el_corazón: '😘',
    besando_los_ojos_cerrados: '😚',
    besos: '😗',
    besando_ojos_sonrientes: '😙',
    sacó_la_lengua_guiñando_un_ojo: '😜',
    sacó_la_lengua_con_los_ojos_cerrados: '😝',
    sacó_la_lengua: '😛',
    sonrojado: '😳',
    mueca: '😁',
    pensativo: '😔',
    aliviado: '😌',
    no_divertido: '😒',
    decepcionado: '😞',
    perseverar: '😣',
    llorar: '😢',
    alegría: '😂',
    sollozo: '😭',
    soñoliento: '😪',
    decepcionado_aliviado: '😥',
    sudor_frío: '😰',
    sonrisa_de_sudor: '😅',
    sudor: '😓',
    cansado: '😩',
    Cara_cansada: '😫',
    temeroso: '😨',
    gritar: '😱',
    enojado: '😠',
    rabia: '😡',
    triunfo: '😤',
    aturdido: '😖',
    riendo: '😆',
    ñam: '😋',
    máscara: '😷',
    Gafas_de_sol: '😎',
    dormido: '😴',
    cara_mareada: '😵',
    asombrado: '😲',
    preocupado: '😟',
    ceñudo: '😦',
    angustiado: '😧',
    diablillo: '👿',
    boca_abierta: '😮',
    haciendo_muecas: '😬',
    cara_neutral: '😐',
    confuso: '😕',
    callado: '😯',
    sonrisa_afectada: '😏',
    inexpresivo: '😑',
    hombre_con_gua_pi_mao: '👲',
    hombre_con_turbante: '👳',
    policía: '👮',
    Obrero: '👷',
    defensor: '💂',
    bebé: '👶',
    chico: '👦',
    niña: '👧',
    hombre: '👨',
    mujer: '👩',
    hombre_mayor: '👴',
    mujer_mayor: '👵',
    persona_con_cabello_rubio: '👱',
    ángel: '👼',
    princesa: '👸',
    gato_sonriente: '😺',
    sonrisa_gato: '😸',
    corazón_ojos_gato: '😻',
    gato_besándose: '😽',
    gato_smirk: '😼',
    gato_grito: '🙀',
    cara_de_gato_llorando: '😿',
    gato_de_alegría: '😹',
    gato_haciendo_pucheros: '😾',
    ogro_japonés: '👹',
    duende_japonés: '👺',
    no_ver_el_mal: '🙈',
    no_escuchar_el_mal: '🙉',
    no_hables_cosas_malas: '🙊',
    cráneo: '💀',
    extraterrestre: '👽',
    Hankey: '💩',
    fuego: '🔥',
    destellos: '✨',
    star2: '🌟',
    mareado: '💫',
    auge: '💥',
    ira: '💢',
    gotas_de_sudor: '💦',
    gotita: '💧',
    zzz: '💤',
    'guión,_correr_precipitadamente,_precipitarse,_ir_de_prisa': '💨',
    oído: '👂',
    ojos: '👀',
    nariz: '👃',
    lengua: '👅',
    labios: '👄',
    Pulgares_hacia_arriba: '👍',
    '-1': '👎',
    ok_mano: '👌',
    puñetazo_en_la_cara: '👊',
    puño: '✊',
    ola: '👋',
    mano: '✋',
    manos_abiertas: '👐',
    señalar_2: '👆',
    señalar_hacia_abajo: '👇',
    señalar_a_la_derecha: '👉',
    punto_a_la_izquierda: '👈',
    manos_levantadas: '🙌',
    orar: '🙏',
    aplaudir: '👏',
    músculo: '💪',
    para_caminar: '🚶',
    corredor: '🏃',
    bailarín: '💃',
    Pareja: '👫',
    familia: '👪',
    pareja: '💏',
    pareja_con_corazon: '💑',
    bailarines: '👯',
    ok_mujer: '🙆',
    no_es_bueno: '🙅',
    persona_de_escritorio_de_información: '💁',
    levantando_la_mano: '🙋',
    masaje: '💆',
    Corte_de_pelo: '💇',
    cuidado_de_uñas: '💅',
    novia_con_velo: '👰',
    persona_con_cara_de_puchero: '🙎',
    persona_frunciendo_el_ceño: '🙍',
    arco: '🙇',
    sombrero_de_copa: '🎩',
    corona: '👑',
    sombrero_de_mujer: '👒',
    calzado_deportivo: '👟',
    zapato_de_hombre: '👞',
    sandalia: '👡',
    tacón_alto: '👠',
    bota: '👢',
    camisa: '👕',
    corbata: '👔',
    ropa_de_mujer: '👚',
    vestido: '👗',
    camiseta_para_correr_con_faja: '🎽',
    pantalones: '👖',
    kimono: '👘',
    bikini: '👙',
    maletín: '💼',
    bolso: '👜',
    bolsa: '👝',
    los_anteojos: '👓',
    cinta: '🎀',
    paraguas_cerrado: '🌂',
    lápiz_labial: '💄',
    corazon_amarillo: '💛',
    corazón_azul: '💙',
    corazón_Purpura: '💜',
    corazón_verde: '💚',
    corazón_roto: '💔',
    pulso: '💗',
    latido_del_corazón: '💓',
    dos_corazones: '💕',
    corazón_brillante: '💖',
    corazones_giratorios: '💞',
    Cupido: '💘',
    carta_de_amor: '💌',
    Beso: '💋',
    anillo: '💍',
    joya: '💎',
    busto_en_silueta: '👤',
    globo_de_discurso: '💬',
    huellas: '👣',
  },
  Naturaleza: {
    perro: '🐶',
    lobo: '🐺',
    gato: '🐱',
    ratón: '🐭',
    hámster: '🐹',
    Conejo: '🐰',
    rana: '🐸',
    Tigre: '🐯',
    coala: '🐨',
    oso: '🐻',
    cerdo: '🐷',
    nariz_de_cerdo: '🐽',
    vaca: '🐮',
    Jabali: '🐗',
    cara_de_mono: '🐵',
    mono: '🐒',
    caballo: '🐴',
    oveja: '🐑',
    elefante: '🐘',
    cara_de_panda: '🐼',
    pingüino: '🐧',
    pájaro: '🐦',
    pollito: '🐤',
    polluelo_eclosionado: '🐥',
    pollito_para_incubar: '🐣',
    pollo: '🐔',
    serpiente: '🐍',
    Tortuga: '🐢',
    error: '🐛',
    abeja: '🐝',
    hormiga: '🐜',
    escarabajo: '🐞',
    caracol: '🐌',
    pulpo: '🐙',
    cáscara: '🐚',
    pez_tropical: '🐠',
    pescado: '🐟',
    delfín: '🐬',
    ballena: '🐳',
    caballo_de_carreras: '🐎',
    cara_de_dragon: '🐲',
    pez_globo: '🐡',
    camello: '🐫',
    caniche: '🐩',
    pies: '🐾',
    ramo_de_flores: '💐',
    flor_de_cerezo: '🌸',
    tulipán: '🌷',
    trébol_de_cuatro_hojas: '🍀',
    Rosa: '🌹',
    girasol: '🌻',
    hibisco: '🌺',
    hoja_de_arce: '🍁',
    hojas: '🍃',
    hoja_caida: '🍂',
    hierba: '🌿',
    espiga_de_arroz: '🌾',
    seta: '🍄',
    cactus: '🌵',
    palmera: '🌴',
    castaña: '🌰',
    planta_de_semillero: '🌱',
    florecer: '🌼',
    Luna_nueva: '🌑',
    primer_cuarto_de_luna: '🌓',
    Luna: '🌔',
    Luna_llena: '🌕',
    primer_cuarto_de_luna_con_cara: '🌛',
    Luna_creciente: '🌙',
    tierra_asia: '🌏',
    volcán: '🌋',
    Vía_láctea: '🌌',
    estrellas: '🌠',
    parcialmente_soleado: '⛅',
    monigote_de_nieve: '⛄',
    ciclón: '🌀',
    brumoso: '🌁',
    arco_iris: '🌈',
    Oceano: '🌊',
  },
  Objetos: {
    bambú: '🎍',
    corazón_de_regalo: '💝',
    muñecas: '🎎',
    mochila_escolar: '🎒',
    junta_de_mortero: '🎓',
    banderas: '🎏',
    fuegos_artificiales: '🎆',
    bengala: '🎇',
    carillón_de_viento: '🎐',
    escena_de_arroz: '🎑',
    Jack_o_linterna: '🎃',
    fantasma: '👻',
    Papa_Noel: '🎅',
    árbol_de_Navidad: '🎄',
    regalo: '🎁',
    árbol_tanabata: '🎋',
    tada: '🎉',
    bola_de_confeti: '🎊',
    globo: '🎈',
    banderas_cruzadas: '🎌',
    bola_de_cristal: '🔮',
    cámara_de_cine: '🎥',
    cámara: '📷',
    camara_de_video: '📹',
    vhs: '📼',
    discos_compactos: '💿',
    DVD: '📀',
    minidisc: '💽',
    disco_flexible: '💾',
    computadora: '💻',
    iphone: '📱',
    receptor_de_teléfono: '📞',
    buscapersonas: '📟',
    fax: '📠',
    satélite: '📡',
    televisión: '📺',
    radio: '📻',
    Sonido_alto: '🔊',
    campana: '🔔',
    altoparlante: '📢',
    mega: '📣',
    arena_que_fluye_reloj_de_arena: '⏳',
    reloj_de_arena: '⌛',
    despertador: '⏰',
    reloj: '⌚',
    desbloquear: '🔓',
    bloquear: '🔒',
    cerradura_con_pluma_de_tinta: '🔏',
    cerradura_cerrada_con_llave: '🔐',
    llave: '🔑',
    mag_derecho: '🔎',
    bulbo: '💡',
    Linterna: '🔦',
    Enchufe_electrico: '🔌',
    batería: '🔋',
    revista: '🔍',
    bañera: '🛀',
    baño: '🚽',
    llave_inglesa: '🔧',
    tuerca_y_el_perno: '🔩',
    martillo: '🔨',
    puerta: '🚪',
    de_fumar: '🚬',
    bomba: '💣',
    pistola: '🔫',
    hocho: '🔪',
    píldora: '💊',
    jeringuilla: '💉',
    bolsa_de_dinero: '💰',
    yen: '💴',
    dólar: '💵',
    tarjeta_de_crédito: '💳',
    dinero_con_alas: '💸',
    vocación: '📲',
    correo_electrónico: '📧',
    bandeja_de_entrada: '📥',
    bandeja_de_salida: '📤',
    sobre_con_flecha: '📩',
    sobre_entrante: '📨',
    buzón: '📫',
    buzón_cerrado: '📪',
    paquete: '📦',
    memorándum: '📝',
    página_hacia_arriba: '📄',
    página_con_rizo: '📃',
    pestañas_de_marcadores: '📑',
    gráfico_de_barras: '📊',
    gráfico_con_tendencia_al_alza: '📈',
    gráfico_con_tendencia_a_la_baja: '📉',
    Desplazarse: '📜',
    portapapeles: '📋',
    fecha: '📅',
    calendario: '📆',
    fichero: '📇',
    carpeta_de_archivos: '📁',
    abrir_carpeta_de_archivos: '📂',
    chincheta: '📌',
    clip_de_papel: '📎',
    regla_recta: '📏',
    regla_triangular: '📐',
    libro_cerrado: '📕',
    libro_Verde: '📗',
    libro_Azul: '📘',
    libro_naranja: '📙',
    cuaderno: '📓',
    cuaderno_con_tapa_decorativa: '📔',
    libro_mayor: '📒',
    libros: '📚',
    libro: '📖',
    marcador: '🔖',
    insignia_de_identificación: '📛',
    periódico: '📰',
    Arte: '🎨',
    badajo: '🎬',
    micrófono: '🎤',
    auriculares: '🎧',
    puntuación_musical: '🎼',
    nota_musical: '🎵',
    notas: '🎶',
    teclado_musical: '🎹',
    violín: '🎻',
    trompeta: '🎺',
    saxofón: '🎷',
    guitarra: '🎸',
    invasor_del_espacio: '👾',
    videojuego: '🎮',
    bromista_negro: '🃏',
    naipes_de_flores: '🎴',
    dominó_chino: '🀄',
    juego_de_morir: '🎲',
    dardo: '🎯',
    fútbol_americano: '🏈',
    baloncesto: '🏀',
    fútbol: '⚽',
    béisbol: '⚾',
    tenis: '🎾',
    bola_8: '🎱',
    bolos: '🎳',
    golf: '⛳',
    bandera_a_cuadros: '🏁',
    trofeo: '🏆',
    esquí: '🎿',
    snowboarder: '🏂',
    nadador: '🏊',
    tablista: '🏄',
    caña_de_pescar_y_pescado: '🎣',
    té: '🍵',
    motivo: '🍶',
    cerveza: '🍺',
    cervezas: '🍻',
    cóctel: '🍸',
    bebida_tropical: '🍹',
    Copa_de_vino: '🍷',
    tenedor_y_cuchillo: '🍴',
    Pizza: '🍕',
    hamburguesa: '🍔',
    papas_fritas: '🍟',
    pata_de_ave: '🍗',
    carne_en_hueso: '🍖',
    espaguetis: '🍝',
    curry: '🍛',
    camarón_frito: '🍤',
    bento: '🍱',
    Sushi: '🍣',
    pastel_de_pescado: '🍥',
    bola_de_arroz: '🍙',
    galleta_de_arroz: '🍘',
    arroz: '🍚',
    ramen: '🍜',
    estofado: '🍲',
    Oden: '🍢',
    dango: '🍡',
    huevo: '🍳',
    pan_de_molde: '🍞',
    rosquilla: '🍩',
    lactoso: '🍮',
    helado: '🍦',
    Hielo_picado: '🍧',
    cumpleaños: '🎂',
    pastel: '🍰',
    Galleta: '🍪',
    barra_de_chocolate: '🍫',
    caramelo: '🍬',
    pirulí: '🍭',
    tarro_de_miel: '🍯',
    manzana: '🍎',
    manzana_verde: '🍏',
    Mandarina: '🍊',
    guindas: '🍒',
    uvas: '🍇',
    sandía: '🍉',
    fresa: '🍓',
    melocotón: '🍑',
    melón: '🍈',
    plátano: '🍌',
    piña: '🍍',
    batata: '🍠',
    berenjena: '🍆',
    tomate: '🍅',
    maíz: '🌽',
  },
  Lugares: {
    casa: '🏠',
    casa_con_jardin: '🏡',
    colegio: '🏫',
    oficina: '🏢',
    oficina_postal: '🏣',
    hospital: '🏥',
    banco: '🏦',
    tienda_de_conveniencia: '🏪',
    Hotel_del_amor: '🏩',
    hotel: '🏨',
    Boda: '💒',
    Iglesia: '⛪',
    grandes_almacenes: '🏬',
    amanecer_de_la_ciudad: '🌇',
    puesta_de_sol_de_la_ciudad: '🌆',
    castillo_japonés: '🏯',
    castillo_europeo: '🏰',
    tienda: '⛺',
    fábrica: '🏭',
    Torre_de_Tokio: '🗼',
    Japón: '🗾',
    Monte_Fuji: '🗻',
    amanecer_sobre_las_montañas: '🌄',
    amanecer: '🌅',
    noche_con_estrellas: '🌃',
    estatua_de_la_Libertad: '🗽',
    puente_de_noche: '🌉',
    caballo_carrusel: '🎠',
    rueda_de_la_fortuna: '🎡',
    fuente: '⛲',
    montaña_rusa: '🎢',
    Embarcacion: '🚢',
    barco: '⛵',
    lancha_rápida: '🚤',
    cohete: '🚀',
    asiento: '💺',
    estación: '🚉',
    lado_de_la_bala: '🚄',
    tren_delantero: '🚅',
    metro: '🚇',
    vagón_de_ferrocarril: '🚃',
    autobús: '🚌',
    carro_azul: '🚙',
    coche: '🚗',
    Taxi: '🚕',
    camión: '🚚',
    luz_giratoria: '🚨',
    coche_de_policía: '🚓',
    camion_de_bomberos: '🚒',
    ambulancia: '🚑',
    bicicleta: '🚲',
    Barbero: '💈',
    parada_de_autobús: '🚏',
    boleto: '🎫',
    semáforo: '🚥',
    construcción: '🚧',
    principiante: '🔰',
    bomba_de_combustible: '⛽',
    linterna_izakaya: '🏮',
    maquina_de_casino: '🎰',
    moyai: '🗿',
    tienda_de_circo: '🎪',
    las_artes_escénicas: '🎭',
    chincheta_redonda: '📍',
    bandera_triangular_en_el_poste: '🚩',
  },
  Símbolos: {
    keycap_ten: '🔟',
    '1234': '🔢',
    symbols: '🔣',
    capital_abcd: '🔠',
    abcd: '🔡',
    abc: '🔤',
    arrow_up_small: '🔼',
    arrow_down_small: '🔽',
    rewind: '⏪',
    fast_forward: '⏩',
    arrow_double_up: '⏫',
    arrow_double_down: '⏬',
    ok: '🆗',
    new: '🆕',
    up: '🆙',
    cool: '🆒',
    free: '🆓',
    ng: '🆖',
    signal_strength: '📶',
    cinema: '🎦',
    koko: '🈁',
    u6307: '🈯',
    u7a7a: '🈳',
    u6e80: '🈵',
    u5408: '🈴',
    u7981: '🈲',
    ideograph_advantage: '🉐',
    u5272: '🈹',
    u55b6: '🈺',
    u6709: '🈶',
    u7121: '🈚',
    restroom: '🚻',
    mens: '🚹',
    womens: '🚺',
    baby_symbol: '🚼',
    wc: '🚾',
    no_smoking: '🚭',
    u7533: '🈸',
    accept: '🉑',
    cl: '🆑',
    sos: '🆘',
    id: '🆔',
    no_entry_sign: '🚫',
    underage: '🔞',
    no_entry: '⛔',
    negative_squared_cross_mark: '❎',
    white_check_mark: '✅',
    heart_decoration: '💟',
    vs: '🆚',
    vibration_mode: '📳',
    mobile_phone_off: '📴',
    ab: '🆎',
    diamond_shape_with_a_dot_inside: '💠',
    ophiuchus: '⛎',
    six_pointed_star: '🔯',
    atm: '🏧',
    chart: '💹',
    heavy_dollar_sign: '💲',
    currency_exchange: '💱',
    x: '❌',
    exclamation: '❗',
    question: '❓',
    grey_exclamation: '❕',
    grey_question: '❔',
    o: '⭕',
    top: '🔝',
    end: '🔚',
    back: '🔙',
    on: '🔛',
    soon: '🔜',
    arrows_clockwise: '🔃',
    clock12: '🕛',
    clock1: '🕐',
    clock2: '🕑',
    clock3: '🕒',
    clock4: '🕓',
    clock5: '🕔',
    clock6: '🕕',
    clock7: '🕖',
    clock8: '🕗',
    clock9: '🕘',
    clock10: '🕙',
    clock11: '🕚',
    heavy_plus_sign: '➕',
    heavy_minus_sign: '➖',
    heavy_division_sign: '➗',
    white_flower: '💮',
    '100': '💯',
    radio_button: '🔘',
    link: '🔗',
    curly_loop: '➰',
    trident: '🔱',
    small_red_triangle: '🔺',
    black_square_button: '🔲',
    white_square_button: '🔳',
    red_circle: '🔴',
    large_blue_circle: '🔵',
    small_red_triangle_down: '🔻',
    white_large_square: '⬜',
    black_large_square: '⬛',
    large_orange_diamond: '🔶',
    large_blue_diamond: '🔷',
    small_orange_diamond: '🔸',
    small_blue_diamond: '🔹',
  },
};
