import { Component, Prop, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { fromEvent, Subscription } from 'rxjs';
import $ from 'jquery';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { BASE_URL_MANAGER } from '@/config';
// Components
import LoadBar from '@/app/components/shared/load-bar/LoadBar.vue';
import { store } from '@/app/store';
import { conversationActionsTypes } from '@/app/store/modules/conversation/conversation.actions';
import { timer } from 'rxjs';

interface TemporalTransferAgent {
  createdAt: Date;
  disabled: boolean;
  user: {
    name: {
      firstName: string;
      lastName: string;
    };
    username: string;
  };
  projects: string[];
  subscriptions: string[];
  updatedAt: Date;
  __v: any;
  _id: string;
}

interface TemporalTransferTopic {
  agents: string[];
  name: string;
  project: string;
  __V: any;
  _id: string;
}

@Component({
  name: 'transfer-conversation-modal',
  components: {
    LoadBar,
  },
})
export default class TransferConversationModal extends VueWizard {
  @Prop() public readonly projectIds?: string[];

  private modalReference: JQuery<HTMLElement> | null = null;
  private modalHideSubscription: Subscription | null = null;

  public selectedTransferOption: number | null = null;
  public step = 2;

  public topics: TemporalTransferTopic[] = [];
  public agents: TemporalTransferAgent[] = [];

  public loading = false;
  public transferring = false;
  public transferred = false;
  public loadingErrors: AxiosError[] | null = null;

  public selectedTopicIndex: number = 0;
  public selectedAgentIndex: number = 0;

  async mounted() {
    this.modalReference = $('#transfer-conversation-modal');
    this.modalReference.modal('show');
    this.modalHideSubscription = fromEvent(
      this.modalReference,
      'hidden.bs.modal',
    ).subscribe(() => {
      this.$emit('modalHideNoConfirm');
      if (this.modalHideSubscription) {
        this.modalHideSubscription.unsubscribe();
      }
      this.reset();
    });
    this.loading = true;
    this.projectIds!.map(async (project: string) => {
      await this.loadProjectTopics(project);
      await this.loadProjectAgents();
      this.loading = false;
    });
  }

  reset(): void {
    this.step = 1;
    this.selectedTransferOption = null;
    this.topics = [];
    this.agents = [];
    this.loadingErrors = null;
    this.loading = false;
    this.selectedTopicIndex = 0;
    this.selectedAgentIndex = 0;
    this.transferring = false;
  }

  @Watch('step')
  async onNextStep(): Promise<void> {
    if (this.step === 2) {
      if (this.projectIds && Array.isArray(this.projectIds)) {
        this.loading = true;
        await this.asyncForEach(this.projectIds, async (project: string) => {
          if (this.selectedTransferOption === 1) {
            await this.loadProjectTopics(project);
          } else if (this.selectedTransferOption === 2) {
            await this.loadProjectTopics(project);
            await this.loadProjectAgents();
          }
        });
        this.loading = false;
      }
    }
  }

  async loadProjectTopics(project: any): Promise<void> {
    try {
      const response = await axios.get(
        `${BASE_URL_MANAGER}/topic/${project._id}`,
      );
      const projectTopics = response.data.projectTopics;
      this.topics = projectTopics;
    } catch (error) {
      this.addLoadingError;
    }
  }

  async loadProjectAgents(): Promise<void> {
    this.agents = [];

    const currentAgentId = store.state.agent!.agent._id;
    const agentIds: string[] = this.topics
      .flatMap((topic: any) => topic.agents || [])
      .filter((agent: any) => agent !== currentAgentId);

    await this.obtainAgentsByIds([...new Set(agentIds)]);
  }

  async obtainUsersByAgents(): Promise<void> {
    if (this.agents) {
      const requests = this.agents.map((agent: any) => {
        if (agent.user && agent.user != null) {
          return this.getUserById(agent.user);
        }
      });
      const users = await Promise.all(requests);
      users.forEach((user: any, index: number) => {
        if (user != null && user.name) {
          Object.assign(this.agents![index], {
            username: user.username,
            name: user.name,
          });
        }
      });
    }
  }

  async obtainAgentsByIds(agentIds: any[]): Promise<void> {
    const requests = await this.getAgentById(agentIds);
    requests.map((agent: any) => {
      if (agent.user && agent.user != null) {
        this.agents.push(agent);
      }
    });
  }

  async asyncForEach(
    array: any[],
    callback: (element: any, index: number, array: any[]) => void,
  ): Promise<void> {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  async getAgentById(agentId: string[]) {
    try {
      const response = await axios.post(`${BASE_URL_MANAGER}/agent/find`, {
        agentIds: agentId,
      });
      return response.data.agents;
    } catch (error) {
      this.addLoadingError;
    }
  }

  async getUserById(userId: string): Promise<any> {
    return new Promise(async resolve => {
      await axios
        .get(`${BASE_URL_MANAGER}/user/${userId}`)
        .then((response: AxiosResponse) => resolve(response.data.user))
        .catch(this.addLoadingError);
    });
  }

  async retryLoad(): Promise<any> {
    this.loadingErrors = null;
    await this.onNextStep();
  }

  addLoadingError(error: AxiosError): void {
    if (!this.loadingErrors) {
      this.loadingErrors = [];
    }
    this.loadingErrors.push(error);
  }

  formatTopicName(name: string): string[] {
    const validElements = name.split('_');
    validElements.splice(0, 1);
    return [name.split('_')[0], validElements.join(' ')];
  }

  agentNameInitials(name: { firstName: string; lastName: string }): string {
    return name.firstName && name.firstName[0]
      ? name.firstName[0].toUpperCase()
      : '' + name.lastName && name.lastName[0]
      ? name.lastName[0].toUpperCase()
      : '';
  }

  transferConversation(): void {
    const selectedAgent = this.agents[this.selectedAgentIndex - 1];
    const selectedTopic = this.topics[this.selectedTopicIndex - 1];

    if (selectedAgent) {
      this.$emit(
        'transferredToAgent',
        `${selectedAgent.user.name.firstName} ${selectedAgent.user.name.lastName}`,
      );
      this.transfer(selectedAgent._id, false);
    } else if (selectedTopic) {
      this.transfer(selectedTopic._id, true);
    }
  }

  transfer(id: string, isTopic: boolean): void {
    this.step = 3;
    this.transferring = true;
    const action = isTopic
      ? conversationActionsTypes.transferConversationToSegment(id)
      : conversationActionsTypes.transferConversationToAgent(id);

    store.dispatch(action).then(() => {
      this.transferring = false;
      this.transferred = true;
    });
  }
  finish(): void {
    if (this.modalReference) {
      this.modalReference.modal('hide');
    }
  }
}
