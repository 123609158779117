var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"position-absolute d-flex card flex-row shadow",attrs:{"id":"conversation-statuses"}},[_c('div',{staticClass:"card-body p-2 w-100"},[_vm._m(0),(_vm.orderedBusinessStatuses.length > 0)?_c('div',{attrs:{"id":"business-status-elements-container"}},_vm._l((_vm.orderedBusinessStatuses),function(businessStatus,index){return _c('div',{key:index,staticClass:"card business-status-element bg-white",class:{
        'mb-2': index !== _vm.businessStatuses.length - 1,
        'actual-state-element':
          _vm.actualBusinessStatus &&
          businessStatus.name === _vm.actualBusinessStatus.name,
      },style:(_vm.statusColorStyle(businessStatus)),on:{"click":function($event){return _vm.selectBusinessStatus(businessStatus)}}},[_c('div',{staticClass:"card-body d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row justify-content-between mb-2"},[_c('small',{staticClass:"text-secondary d-flex align-items-center"},[_c('b',{staticClass:"mr-2"},[_vm._v(_vm._s(businessStatus.name.toUpperCase()))]),(_vm.actualBusinessStatus &&
                businessStatus.name === _vm.actualBusinessStatus.name
              )?_c('span',{staticClass:"badge badge-success py-1 px-1 rounded-pill"},[_c('i')]):_vm._e()]),_vm._m(1,true)]),_c('div',{staticClass:"business-status-element-description"},[_c('font-awesome-icon',{attrs:{"icon":"align-left"}}),_c('small',[_vm._v(" "+_vm._s(businessStatus.description)+" ")])],1),_c('div',{staticClass:"business-status-element-message text-secondary"},[_c('font-awesome-icon',{attrs:{"icon":"comment"}}),_c('small',[_c('i',[_vm._v(" "+_vm._s(businessStatus.message)+" ")])])],1)])])}),0):_c('div',{staticClass:"alert alert-warning"},[_c('h6',{staticClass:"alert-heading"},[_vm._v("No hay etiquetas para este proyecto")]),_c('small',[_vm._v("Habla con el administrador si quieres crear uno")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-left w-100 mb-2"},[_c('b',{staticClass:"text-secondary"},[_vm._v("Etiquetas")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"badge color-indicator py-1 px-4"},[_c('i')])
}]

export { render, staticRenderFns }