import { Component, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import $ from 'jquery';
import { fromEvent, Subscription } from 'rxjs';
import { BusinessStatus } from '@/app/store/modules/conversation/conversation.models';
import eventBus from '@/main';

@Component({ name: 'resolve-conversation-modal' })
export default class ResolveConversationModal extends VueWizard {
  @Prop()
  public readonly businessStatuses!: BusinessStatus[];

  private modalReference: JQuery<HTMLElement> | null = null;
  private modalHideSubscription: Subscription | null = null;

  public selectedStatusIndex: number | null = null;
  public defaultSelected = false;

  get isConversationActive(): boolean {
    return (
      this.$store.state.conversation.currentConversation.status === 'ACTIVE' ||
      this.$store.state.conversation.currentConversation.status ===
        'TRANSFERRED'
    );
  }

  mounted(): void {
    this.modalReference = $('#resolve-conversation-modal');
    this.modalReference.modal('show');
    this.modalHideSubscription = fromEvent(
      this.modalReference,
      'hidden.bs.modal',
    ).subscribe(() => {
      this.$emit('modalHideNoConfirm');
      if (this.modalHideSubscription) {
        this.modalHideSubscription.unsubscribe();
      }
    });
  }

  get selectedStatusMessage(): string | null {
    return this.selectedStatusIndex
      ? this.businessStatuses[(this.selectedStatusIndex as any) - 1].message
      : null;
  }

  statusColorStyle(status: BusinessStatus): any {
    return {
      '--label-bg-color-r': this.hexToRgb(status.color)
        ? (this.hexToRgb(status.color) as any).r
        : 0,
      '--label-bg-color-g': this.hexToRgb(status.color)
        ? (this.hexToRgb(status.color) as any).g
        : 0,
      '--label-bg-color-b': this.hexToRgb(status.color)
        ? (this.hexToRgb(status.color) as any).b
        : 0,
    };
  }

  hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  resolve(): void {
    if (this.modalHideSubscription) {
      this.modalHideSubscription.unsubscribe();
    }
    if (this.modalReference) {
      this.modalReference.modal('hide');
    }
    if (this.selectedStatusIndex) {
      this.$emit(
        'resolveByBusinessStatus',
        this.businessStatuses[(this.selectedStatusIndex as any) - 1],
      );
    } else {
      this.$emit('resolveNormally');
    }
    eventBus.$emit('showConversationContentLoading');
  }
}
