import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import axios from "axios";

@Component({ name: "input-filter-component" })
export default class InputFilterComponent extends VueWizard {
  @Prop() readonly title_filter!: string;
  @Prop() readonly type_filter!: string;
  @Prop() readonly name_filter!: string;
  @Prop() readonly source!: string;
  @Prop() readonly svg!: string;
  @Prop() readonly EventBus!: Vue;
  @Prop() readonly selectedOptionEdit!: any;
  public editFilter: boolean = true;
  public inputValue: string = "";
  public timeout: any;
  public currentValue: string = "";
  public options: any = [];
  public fetching = false;
  public lastFecth = 0;
  mounted() {
    if (this.inputValue.length > 0) {
      this.editFilter = false;
    }
    if (this.selectedOptionEdit) {
      this.inputValue = this.selectedOptionEdit;
      this.editFilter = false;
    }
  }
  closeFilter() {
    this.EventBus.$emit("close-filter", {
      name_filter: this.name_filter,
      type_filter: this.type_filter,
    });
  }
  saveFilter() {
    this.editFilter = false;

    if (this.inputValue.length > 0) {
      this.EventBus.$emit("save-filter", {
        name_filter: this.name_filter,
        type_filter: this.type_filter,
        value: this.inputValue,
        source: this.source,
      });
    }
  }
  get enableApply() {
    return this.inputValue.length > 0;
  }
  convertString(value: string): string {
    const string = value.replace(/_/g, " ");
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  enableEdit() {
    this.editFilter = true;
    this.EventBus.$emit("enable-edit", {
      name_filter: this.name_filter,
      type_filter: this.type_filter,
    });
  }

  async handleChange(val: string) {
    this.inputValue = val;
    await this.fetchSearch(val, (data: any[]) => (this.options = data));
  }
  async handleSearch(val: string) {
    await this.fetchSearch(val, (data: any[]) => (this.options = data));
  }

  async fetchSearch(query: string, cb: any): Promise<any> {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.currentValue = query;
    this.timeout = setTimeout(async () => {
      const data = await this.fetchSearchResults(query);
      cb(data);
    }, 500);
  }

  async fetchSearchResults(query: string) {
    if (query.length < 2) return;
    this.lastFecth++;
    const fetchId = this.lastFecth;
    const url = "https://api.dropi.co/integrations/products/index";
    const params = {
      active: true,
      keywords: query,
      order_by: "name",
      order_type: "asc",
      pageSize: 10,
      startData: 0,
    };
    const headers = {
      "Content-Type": "application/json",

      "dropi-integration-key":
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBwLmRyb3BpLmNvIiwiaWF0IjoxNjkwMzgwNTQ0LCJleHAiOjQ4NDYwNTQxNDQsIm5iZiI6MTY5MDM4MDU0NCwianRpIjoiNUY2MWpSZ3Z6N0xVUkFBZyIsInN1YiI6MTAsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEiLCJhdWQiOiJDSEFUQ0VOVEVSIiwiaW50ZWdyYXRpb25fdHlwZSI6IkNIQVRDRU5URVIiLCJpbnRlZ3JhdGlvbl91cmwiOm51bGwsInRva2VuX3R5cGUiOiJJTlRFR1JBVElPTlMifQ.BKyr5lVP_kJUVCZRS6So1jsHAga3NbV24OTwiy9X1vY",
    };

    try {
      this.fetching = true;
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(params),
      });
      const data = await response.json();
      if (fetchId !== this.lastFecth) return;
      if (this.currentValue === query) {
        this.fetching = false;
        const products = data.objects.map((product: any) => {
          return {
            label: product.id + " - " + product.name,
            value: product.id + " - " + product.name,
          };
        });
        return products;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @Watch("inputValue")
  onInputValueChange() {
    this.options = [];
    this.fetching = false;
  }
}
