import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import {
  Conversation,
  MessageType,
  Interaction,
  InteractionOrigin,
} from '@/app/store/modules/conversation/conversation.models';
import { store, storeTypes } from '@/app/store';
import Axios from 'axios';
import {
  BASE_URL_MANAGER,
  BASE_URL_ORCHESTATOR,
  BASE_URL_WHATSAPP_CLOUD,
} from '@/config';
import allEmojis from '@/app/models/emojis';
import { fromEvent } from 'rxjs';
import ConversationQuickResponse from '@/app/components/conversation/conversation-quick-response/ConversationQuickResponse.vue';
import $ from 'jquery';

import ModalComponent from '@/app/components/modal/ModalComponent.vue';
import { conversationMutationsTypes } from '@/app/store/modules/conversation/conversation.mutations';
import FloatButton from '@/app/components/buttons/float-action-button.vue';
import Swal from 'sweetalert2';
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/src/plugin/microphone';
import RecordRTC from 'recordrtc';
import eventBus from '@/main';

@Component({
  name: 'conversation-form-send',
  components: { ConversationQuickResponse, ModalComponent, FloatButton },
})
export default class ConversationFormSend extends VueWizard {
  @Prop() readonly currentConversation!: Conversation;
  @Prop() readonly spinConversation!: boolean;
  @Prop() readonly EventBus!: Vue;
  @Prop() readonly agentQuickResponses!: any;
  @Prop() readonly topicQuickResponses!: any;
  @Prop() readonly currentAgentId!: any;

  public emojis = [];
  public bus: Vue = new Vue();
  public message = '';
  public messageSend = '';
  public emogiSearch = '';
  public imageList: any[] = [];
  public originalFileOrder: any[] = [];
  public originalImageOrder: any[] = [];
  public originalVideoOrder: any[] = [];
  public fileList: any[] = [];
  public fileListEmpty: any[] = [];

  public limitAlert = false;

  public audioList: any[] = [];
  public audio: any = null;
  public imageListempty: any[] = [];
  public uploading = false;
  public visiableAttachmentForm = false;
  public visibleEmojis = false;
  public previewVisible = false;
  public visibleQuickResponses = false;
  public previewImage = '';
  public toUploadFiles = 0;
  public uploadedFiles = 0;
  public emojiCategories: string[] = [];
  public actualCategory = '';

  public replyText = '';
  public replyData: Object | any = {};
  public replyContainer = false;
  public errorToast: any;
  public imageUrl = '';
  public loading = false;
  public isAgent?: boolean;
  public sendingMessage = false;
  public URL_ORCHESTATOR = BASE_URL_ORCHESTATOR;
  public isError = true;
  public isModalVisible = false;
  public project: any;

  public audioSender = false;
  public minutes = 0;
  public seconds = 0;
  public audioIntervalID: any = null;
  public wavesurfer: any = null;
  public recorder: any = null;
  private MAX_FILE_SIZE = 2000000;
  public templates: any = [];
  public startConverstionObject: any = {
    phone: store.state.conversation!.currentConversation.currentConsumer,
    name: '',
    text: '',
  };

  // endpoint managment
  public URL_MANAGER = BASE_URL_MANAGER;
  // MODAL
  openStartConversationDialog(): void {
    this.startConverstionObject = {
      phone: '',
      name: '',
      text: '',
    };

    if (this.templates.length == 0) {
      this.getTemplates();
    }
  }
  async startConversation() {
    try {
      let channel = '';

      let node: any = {
        type: 'TEXT',
        text: [this.startConverstionObject.text],
      };
      switch (this.project!.integrator) {
        case 'DROPI':
          channel = 'WHATSAPP_CLOUD';
          break;
        case 'MESSAGEBIRD':
          channel = 'WHATSAPP_MESSAGEBIRD';
          break;
        default:
          channel = 'WHATSAPP_CLOUD';
          break;
      }

      this.startConverstionObject.phone = this.startConverstionObject.phone
        .split(' ')
        .join('');

      const currentAgent = this.$store.state.agent.agent._id;

      let respMessage = await Axios.post(
        `${BASE_URL_WHATSAPP_CLOUD}/user/${this.startConverstionObject.phone}`,
        {
          message: node,
          idmessase: null,
          isForwarded: false,
          idreference: null,
          conversation_id: null,
          apiKey: this.project.accessToken,
          phoneNumberId: this.project.phone_number_id,
          wabaId: this.project.bussiness_account_id,
          template: this.startConverstionObject.template,
          agent: currentAgent,
        },
      );

      let dataMessage = respMessage!.data!.data;

      let data = {
        nodo: node,
        conversation: {
          project: this.project._id,
          currentChannel: channel,
          consumer: [this.startConverstionObject.phone],
          currentConsumer: this.startConverstionObject.phone,
          topics: this.$store.state.agent.agent.subscriptions,
          show_in_queue: true,
        },
        msgidentity: { idmessage: dataMessage!.resp!.data!.messages[0]!.id },
        consumerName: this.startConverstionObject.name,
      };

      let url = `${BASE_URL_ORCHESTATOR}/conversation/interaction/output`;
      let respConversation = await Axios.post(url, data);

      let conversation = respConversation!.data!.conversation;

      conversation.currentConsumer = this.startConverstionObject.phone;

      if (respConversation.status == 200) {
        store.commit(
          conversationMutationsTypes.setCurrentConversation(conversation),
        );

        //this.grebConversation(conversation);

        Axios(
          `${BASE_URL_ORCHESTATOR}/agent/${
            this.$store.state.agent.agent._id
          }/conversation/grab/${conversation!._id}`,
        )
          .then(res => {
            console.log('response', res);
          })
          .catch(e => {
            this.$notification.error({
              placement: 'bottomRight',
              message: `Error al tomar la conversacion`,
              description: `Error: ${e!.message}`,
            });
          })
          .finally(() => {
            let modal: any = $('#start-conversation');
            modal.modal('hide');
            this.startConverstionObject = {};
            this.isModalVisible = false;
          });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información ${respConversation.status}`,
          description: `Error: ${respConversation.status}`,
        });
      }
    } catch (e) {
      this.$notification.error({
        placement: 'bottomRight',
        description: `Hubo un error al guardar al informacióddn`,
        message: `Error:`,
      });
    }
  }
  async getProject(idProject: string) {
    let resp = await Axios.get(`${BASE_URL_MANAGER}/project/${idProject}`);
    if (resp.status == 200) {
      return resp.data.project;
    }
  }
  async getTemplates() {
    let projectID = store.state.conversation!.currentConversation.project;
    this.project = await this.getProject(projectID!);
    Axios.get(BASE_URL_MANAGER + '/templates', {
      params: {
        project_id: this.project._id,
      },
    }).then(async res => {
      if (res.status == 200) {
        this.templates = res.data.templates
          .map((t: any) => {
            const dontHaveParams = t.components.filter(
              (e: any) =>
                e.type == 'BODY' &&
                e.text != undefined &&
                e!.text.indexOf('{') < 0,
            );
            return {
              ...t,
              dontHaveParams,
            };
          })
          .filter((t: any) => {
            return t.dontHaveParams.length > 0 /*&& t.status === 'APPROVED'*/;
          });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información ${res.status}`,
          description: `Error: ${res.status}`,
        });
      }
    });
  }

  isInvalidValidStrartConfersationForm() {
    this.startConverstionObject.phone =
      store.state.conversation!.currentConversation.currentConsumer;
    if (
      this.startConverstionObject.text == undefined ||
      this.startConverstionObject.text == ''
    ) {
      return true;
    }
    if (
      this.startConverstionObject.phone == undefined ||
      this.startConverstionObject.phone == ''
    ) {
      return true;
    }
    if (
      this.startConverstionObject.template == undefined ||
      this.startConverstionObject.template == ''
    ) {
      return true;
    }

    return false;
  }

  async onChangeTemplate(e: any) {
    // this.startConverstionObject.template = e;
    let body = await this.startConverstionObject.template.components.find(
      (e: any) => e.type == 'BODY',
    );
    this.startConverstionObject.text = await body.text;
  }

  // ENDMODAL

  showAudioSender(): void {
    this.audioSender = true;
    this.visibleQuickResponses = false;
    this.visibleEmojis = false;
    this.imageList = [];
    this.fileList = [];
    let self = this;
    const surfer = <HTMLElement>this.$refs.waveform;

    if (this.audioSender) {
      this.wavesurfer = WaveSurfer.create({
        container: surfer,
        height: 32,
        waveColor: '#777777',
        progressColor: '#009ee3',
        cursorWidth: 0,
        barMinHeight: 1,
        barWidth: 3,
        barGap: 3,
        barRadius: 3,
        responsive: true,
        plugins: [MicrophonePlugin.create({})],
      });
      this.wavesurfer.microphone.on(
        'deviceReady',
        function (stream: MediaStream) {
          // debug recordrtc false
          self.recorder = new RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/wav',
            recorderType: RecordRTC.StereoAudioRecorder,
            numberOfAudioChannels: 2,
            disableLogs: true,
          });

          self.startTimer();
          self.recorder.startRecording();
        },
      );
      this.wavesurfer.microphone.on('deviceError', function (code: string) {
        console.warn('Device error: ' + code);
      });
      this.wavesurfer.microphone.start();
    }
  }

  hideAudioSender(): void {
    this.audioSender = false;
    this.wavesurfer.destroy();
    this.stopTimer();
  }

  startTimer(): void {
    let self = this;
    if (this.audioIntervalID !== null) return;

    this.audioIntervalID = setInterval(() => {
      self.seconds++;
      if (self.seconds === 60) {
        self.minutes++;
        self.seconds = 0;
      }
    }, 1000);
  }

  stopTimer(): void {
    clearInterval(this.audioIntervalID);
    this.audioIntervalID = null;
    this.minutes = 0;
    this.seconds = 0;
  }

  mounted(): void {
    eventBus.$on('openModal', () => {
      this.isModalVisible = true;
      this.openStartConversationDialog();
    });
    this.isError = true;
    this.emojiCategories = Object.keys(allEmojis);
    this.getEmojis(this.emojiCategories[0]);
    fromEvent(document, 'paste').subscribe((event: any) => this.onPaste(event));
    let self = this;
    this.$root.$on('closeReply', function (changeConversation: boolean) {
      if (changeConversation) self.replyData = {};
    });
    this.$root.$on(
      'sendReply',
      async function (reply: Interaction, currentUser: boolean) {
        const element: any = <Vue>self.$refs['input'];
        if (element && typeof element !== undefined) await element.focus();
        self.isAgent = currentUser;
        self.replyContainer = true;
        self.replyData = reply;
        self.replyData.origin = InteractionOrigin.REF;

        switch (reply.type) {
          case 'MESSAGE':
            if (self.imageUrl) {
              self.imageUrl = '';
            }
            break;

          case 'IMAGE':
            await self.dataUrlToFile(reply.fileurl).then((res: string) => {
              self.imageUrl = res;
            });
            break;

          case 'FILE':
            if (self.imageUrl) {
              self.imageUrl = '';
            }
            break;
          default:
            break;
        }
        self.replyText = reply.text[0];
      },
    );
    this.errorToast = $('#error-toast');
  }

  @Watch('currentConversation')
  changeInteractions(): void {
    this.reset();
    this.emojiCategories = Object.keys(allEmojis);
    this.getEmojis(this.emojiCategories[0]);
  }

  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer
      ? item.currentConsumer
      : item.consumer
      ? item.consumer
      : '';
    if (consumer.includes('@')) consumer = consumer.split('@')[0];
    return consumer;
  }

  reset(): void {
    this.message = '';
    this.messageSend = '';
    this.emogiSearch = '';
    this.imageList = [];
    this.fileList = [];
    this.fileListEmpty = [];
    this.limitAlert = false;
    this.audioList = [];
    this.audio = null;
    this.imageListempty = [];
    this.uploading = false;
    this.visiableAttachmentForm = false;
    this.visibleEmojis = false;
    this.visibleQuickResponses = false;
    this.previewVisible = false;
    this.previewImage = '';
    this.toUploadFiles = 0;
    this.uploadedFiles = 0;
    this.emojiCategories = [];
    this.actualCategory = '';
    this.audioSender = false;
    this.originalFileOrder = [];
    this.originalImageOrder = [];
    this.isError = false;
  }

  async onPaste(event: ClipboardEvent): Promise<any> {
    const clipboardData: DataTransfer | null = event.clipboardData;
    if (clipboardData) {
      const file: File | null = clipboardData.items[0].getAsFile();
      if (file) {
        this.visibleEmojis = false;
        this.visibleQuickResponses = false;
        this.audioSender = false;
        if (file.size < 40000000) {
          Object.assign(file, { uid: Math.round(Math.random() * 100000) });
          const info = { file };
          this.handleChange(info);
        } else {
          this.$message.error(`El archivo ${file.name} es muy grande.`);
        }
      }
    }
  }

  get interactions(): Interaction[] {
    return store.state.agent!.interactions;
  }
  @Watch('interactions')
  isLastInteractionFailed(): void {
    const lastInteraction = this.interactions[this.interactions.length - 1];

    // search the last interaction mode INPUT reversed
    const lastInteractionInput = this.interactions
      .slice()
      .reverse()
      .find((interaction: Interaction) => interaction.mode === 'INPUT');

    // check if the created date interaction is minor than 24 hours
    if (lastInteractionInput != undefined) {
      let date = new Date(lastInteractionInput.createdAt);
      let now = new Date();
      let diff = Math.abs(now.getTime() - date.getTime());
      let diffHours = Math.ceil(diff / (1000 * 3600));
      if (diffHours > 24) {
        this.isError = true;
        eventBus.$emit('Error-Interaction', {
          errors: [
            {
              title:
                'Han pasado mas de 24 horas desde la ultima interacción del cliente, para empezar la conversación en Whatsapp API se requiere una plantilla de Facebook.',
            },
          ],
          code: 'TIMEOUT',
          isError: true,
        });
        return;
      }
    }
    if (lastInteractionInput == undefined) {
      this.isError = true;
      eventBus.$emit('Error-Interaction', {
        errors: [
          {
            title:
              'Como el cliente no ha respondido, solo puedes enviar plantillas de Facebook.',
          },
        ],
        code: 'TIMEOUT',
        isError: true,
      });
      return;
    }

    // check lastInteraction._errors is not undefined and has errors
    if (
      lastInteraction._errors != undefined &&
      lastInteraction._errors.length > 0
    ) {
      this.isError = true;
      if (
        lastInteraction._errors[0].title == 'TIMEOUT' ||
        lastInteraction._errors[0].code === '131047'
      ) {
        eventBus.$emit('Error-Interaction', {
          errors: lastInteraction._errors,
          isError: true,
        });
        Swal.fire({
          title: 'Error',
          text: lastInteraction._errors[0].title,
          icon: 'error',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.isModalVisible = true;
            this.getTemplates();
          }
        });
      } else {
        eventBus.$emit('Error-Interaction', {
          errors: lastInteraction._errors,
          isError: false,
        });
        this.isError = false;
      }
    } else {
      this.isError = false;
      eventBus.$emit('Error-Interaction', {
        errors: [],
        isError: false,
      });
    }
  }
  getEmojis(category: string): void {
    this.actualCategory = category;
    let temporalEmojis: any = allEmojis;
    let filterEmojis: any = temporalEmojis[this.actualCategory];
    this.emojis = Object.values(filterEmojis);
  }

  insert(emoji: string): void {
    this.setContentInMessage(emoji);
  }

  generateAudioName(): string {
    return `${this.currentConversation._id}${Date.now()}.wav`;
  }

  sendRecord(): void {
    let self = this;

    this.recorder.stopRecording(function () {
      let blob = self.recorder.getBlob();
      self.audio = blob;
      self.wavesurfer.destroy();
      self.stopTimer();
      self.audioSender = false;
      self.sendMessage(event);
    });
  }

  removeAll(): void {
    this.fileList = [];
    this.imageList = [];
    this.originalFileOrder = [];
    this.originalImageOrder = [];
    this.toUploadFiles = 0;
    this.uploadedFiles = 0;
    this.limitAlert = false;
  }

  handleRemove(file: any): void {
    let index = this.imageList.indexOf(file);
    let indexOriginal = this.originalImageOrder.indexOf(file.uid);

    if (index != -1) {
      const newimageList = this.imageList.slice();
      const newOriginalImageList = this.originalImageOrder.slice();
      newimageList.splice(index, 1);
      newOriginalImageList.splice(indexOriginal, 1);
      this.imageList = newimageList;
      this.originalImageOrder = newOriginalImageList;
    }

    index = this.fileList.indexOf(file);
    indexOriginal = this.originalFileOrder.indexOf(file.uid);

    if (index != -1) {
      const newfileList = this.fileList.slice();
      const newOriginalFileList = this.originalFileOrder.slice();
      newfileList.splice(index, 1);
      newOriginalFileList.splice(indexOriginal, 1);
      this.fileList = newfileList;
      this.originalFileOrder = newOriginalFileList;
    }

    this.toUploadFiles = this.fileList.length + this.imageList.length;
    this.limitAlert = false;
  }

  beforeUploadImage(file: any): boolean {
    const allowedImageTypes = ['image/jpeg', 'image/png'];
    const allowedVideoTypes = [
      'video/mp4',
      'video/gif',
      'video/webm',
      'video/mov',
      'video/avi',
      'video/wmv',
      'video/mpg',
      'video/mpeg',
      'video/flv',
      'video/3gp',
      'video/mkv',
      'video/m4v',
    ];

    const isImage = allowedImageTypes.includes(file.type);
    const isVideo = allowedVideoTypes.includes(file.type);
    const isLt2M = file.size / 1024 / 1024 < 2;

    if ((isImage && !isLt2M) || (!isImage && !isVideo)) {
      this.showUploadError('Formato o tamaño de archivo no válido.');
      return false;
    }

    return true;
  }

  private showUploadError(message: string): void {
    this.$notification.error({
      message: 'Error',
      description: message,
      placement: 'bottomRight',
    });
  }

  beforeUploadFiles(file: any): boolean {
    // facebook suports only .doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.txt,.mp4,.csv
    const isPermitted =
      file.type === 'text/plain' ||
      file.type === 'application/pdf' ||
      file.type === 'application/msword' ||
      file.type === 'aplication/vnd.ms-powerpoint' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'video/mp4' ||
      file.type === 'text/csv';

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      // message error display from bottom to top
      this.$notification.error({
        message: 'Error',
        description: 'La imagen debe ser menor a 2MB!',
        placement: 'bottomRight',
      });
    }

    if (!isPermitted) {
      // message error display from bottom to top
      this.$notification.error({
        message: 'Error',
        description:
          'Solo puedes subir archivos .doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.txt,.mp4',
        placement: 'bottomRight',
      });
    }

    return false;
  }

  getBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handleCancel(): void {
    this.previewVisible = false;
  }

  @Watch('fileList')
  updateToUploadFiles(): void {
    this.uploadedFiles = this.fileList.length + this.imageList.length;
  }

  @Watch('imageList')
  updateToUploadImages(): void {
    this.uploadedFiles = this.fileList.length + this.imageList.length;
  }

  handleChange(info: any): void {
    this.resetUIState();

    const status = info.file.status;

    if (info.file.size < this.MAX_FILE_SIZE && status !== 'done') {
      let uploader: Promise<any>;

      if (info.file.originFileObj) {
        uploader = this.handleUpload(info.file.originFileObj);
      } else {
        uploader = this.handleUpload(info.file);
      }

      if (this.isImageFile(info.file)) {
        this.originalImageOrder.push(info.file.uid);
      } else if (this.isVideoFile(info.file)) {
        this.originalVideoOrder.push(info.file.uid);
      } else {
        this.originalFileOrder.push(info.file.uid);
      }

      uploader
        .then((file: any) => {
          this.handleUploadSuccess(file);
        })
        .catch(() => {
          this.handleUploadFailure(info.file);
        });
    }
  }
  private isImageFile(file: any): boolean {
    return file.type.indexOf('image/') !== -1;
  }

  private isVideoFile(file: any): boolean {
    return file.type.indexOf('video/') !== -1;
  }

  private resetUIState(): void {
    this.visibleQuickResponses = false;
    this.visibleEmojis = false;
    this.audioSender = false;
  }

  private handleUploadSuccess(file: any): void {
    if (file.type.indexOf('image/') !== -1) {
      this.imageList.push(file);
    } else {
      this.fileList.push(file);
    }

    this.updateToUploadFiles();
    this.updateToUploadImages();

    file.status = 'done';
    this.verifyUploadedFilesMatches();

    if (this.toUploadFiles === this.uploadedFiles) {
      this.uploading = false;
      this.limitAlert = false;
      this.reArrangeImageList();
      this.reArrangeFileList();
    }
  }

  private handleUploadFailure(file: any): void {
    if (file.type.indexOf('image/') !== -1) {
      this.originalImageOrder.pop();
    } else {
      this.originalFileOrder.pop();
    }
  }

  verifyUploadedFilesMatches() {
    this.fileList.forEach((file: any) => {
      if (!this.originalFileOrder.includes(file.uid)) {
        let index = this.fileList.indexOf(file);
        const newFilesList = this.fileList.slice();
        newFilesList.splice(index, 1);
        this.fileList = newFilesList;
      }
    });

    this.imageList.forEach((image: any) => {
      if (!this.originalImageOrder.includes(image.uid)) {
        let index = this.imageList.indexOf(image);
        const newImagesList = this.imageList.slice();
        newImagesList.splice(index, 1);
        this.imageList = newImagesList;
      }
    });
  }

  reArrangeImageList() {
    let tempImageList = this.imageList;
    this.imageList = [];
    this.originalImageOrder.forEach((image: any) => {
      let localImage = null;
      tempImageList.forEach(tempImage => {
        if (tempImage && tempImage.uid === image) {
          localImage = tempImage;
        }
      });
      this.imageList.push(localImage);
    });
  }

  reArrangeFileList() {
    let tempFileList = this.fileList;
    this.fileList = [];
    this.originalFileOrder.forEach((file: any) => {
      let localFile = null;
      tempFileList.forEach(tempFile => {
        if (tempFile && tempFile.uid === file) {
          localFile = tempFile;
        }
      });
      this.fileList.push(localFile);
    });
  }

  async handlePreview(file: any): Promise<any> {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;

    this.EventBus.$emit('image-modal', {
      formatedFileName: Math.round(Math.random() * 100000),
      dataFileUrl: this.previewImage,
    });
  }

  handleUpload(file: any): Promise<File> {
    return new Promise(async (resolve, reject) => {
      const limit = 10;
      if (this.toUploadFiles >= limit) {
        if (this.toUploadFiles === limit && !this.limitAlert) {
          this.$notification.error({
            message: 'Acción no valida',
            description: `El limite de envio es de ${limit} archivos`,
          });
          this.limitAlert = true;
          setTimeout(() => (this.limitAlert = false), 500);
        }
        reject();
      } else {
        this.toUploadFiles++;
        const formData = new FormData();
        const idProject = this.currentConversation.project || '';
        formData.append('image', file);
        formData.append('contentType', file.type);
        formData.append('project', idProject);

        this.uploading = true;
        await Axios.post(`${BASE_URL_MANAGER}/company/fileupload/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async (resp: any) => {
            let data = resp.data;
            if (data.success === true) {
              file.originalUrl = resp.data.urlfile;
              file.url = resp.data.urlfile;

              resolve(file);
            } else {
              this.uploading = false;
              file.status = 'error';
              reject();
            }
          })
          .catch(() => {
            this.uploading = false;
            this.toUploadFiles--;

            file.status = 'error';
            reject();
          });
      }
    });
  }

  handleUploadAudio(blob: any): void {
    const formData = new FormData();
    let name = this.generateAudioName();
    const idProject = this.currentConversation.project || '';
    let file = new File([blob], name, {
      type: blob.type,
      lastModified: Date.now(),
    });
    formData.append('audio', file);
    formData.append('contentType', file.type);
    formData.append('project', idProject);

    this.uploading = true;

    Axios.post(`${BASE_URL_MANAGER}/company/audioupload/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(async (resp: any) => {
        let data = resp.data;
        if (data.success === true) {
          let url = resp.data.urlfile;
          const idTextMessage = Math.random().toString(36).substring(2, 9);
          this.EventBus.$emit('sendingMessage', idTextMessage);
          await store
            .dispatch(
              storeTypes.conversation.actions.sendMessage({
                id: idTextMessage,
                type: MessageType.AUDIO,
                fileurl: url,
                filename: file.name,
                mimetype: 'audio/wav',
                origin: this.replyData.origin,
                text: ['test'],
              }),
            )
            .finally(() => {
              this.EventBus.$emit('sentMessage', idTextMessage);
              this.bus.$emit('clearRecordList');
              this.audio = null;
              this.uploading = false;
            })
            .catch(() => {
              this.errorToast.removeClass('d-none');
              this.errorToast.toast('show');
              setTimeout(() => this.errorToast.toast('hide'), 2000);
            });
        }
      })
      .catch(() => (this.uploading = false));
  }

  async asyncForEach(array: any, callback: any) {
    this.sendingMessage = true;
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
    this.sendingMessage = false;
  }

  async sendMessage(event: any): Promise<any> {
    if (event.shiftKey && event.keyCode === 13) {
      return;
    }
    this.toUploadFiles = 0;
    this.uploadedFiles = 0;
    this.visibleEmojis = false;
    this.visibleQuickResponses = false;
    const idTextMessage = Math.random().toString(36).substring(2, 9);
    if (this.message && this.message.length > 0 && this.message.trim() !== '') {
      const currentTextMessage = this.message;
      this.EventBus.$emit('sendingMessage', idTextMessage);
      this.replyContainer = false;
      this.message = '';
      await store
        .dispatch(
          storeTypes.conversation.actions.sendMessage({
            id: idTextMessage,
            type: MessageType.MESSAGE,
            text: [currentTextMessage],
            referenceId: this.replyData._id,
            origin: this.replyData.origin,
          }),
        )
        .finally(() => {
          this.EventBus.$emit('sentMessage', idTextMessage);
        })
        .catch(() => {
          this.errorToast.removeClass('d-none');
          this.errorToast.toast('show');
          setTimeout(() => this.errorToast.toast('hide'), 2000);
        });
      this.replyData = {};
    }

    if (this.imageList.length > 0) {
      this.EventBus.$emit('sendingMessage', idTextMessage);

      await this.asyncForEach(this.imageList, async (file: any) => {
        await this.sendFile(file, MessageType.IMAGE, idTextMessage);
      });

      this.imageList = [];
      this.originalImageOrder = [];
      this.EventBus.$emit('sentMessage', idTextMessage);
    }

    if (this.fileList.length > 0) {
      this.EventBus.$emit('sendingMessage', idTextMessage);

      await this.asyncForEach(this.fileList, async (file: any) => {
        await this.sendFile(file, MessageType.FILE, idTextMessage);
      });

      this.fileList = [];
      this.originalFileOrder = [];
      this.EventBus.$emit('sentMessage', idTextMessage);
    }

    if (this.audio != null) {
      this.handleUploadAudio(this.audio);
    }
  }

  async sendFile(file: any, type: MessageType, id: string): Promise<any> {
    this.replyContainer = false;
    await store
      .dispatch(
        storeTypes.conversation.actions.sendMessage({
          id,
          type,
          fileurl: file.url,
          filename: file.name,
          mimetype: file.type,
          referenceId: this.replyData._id,
          origin: this.replyData.origin,
          text: ['test'],
        }),
      )
      .then(() => {
        this.replyData = {};
      })
      .finally(() => {
        this.EventBus.$emit('sentMessage', id);
      })
      .catch(error => {
        this.errorToast.removeClass('d-none');
        this.errorToast.toast('show');
        setTimeout(() => this.errorToast.toast('hide'), 2000);
      });
  }

  setContentInMessage(message: string): void {
    this.message += message;
  }

  showAttachmentSite(): void {
    if (this.visiableAttachmentForm) {
      this.$el.setAttribute('style', 'bottom: 0px');
      this.visiableAttachmentForm = false;
      this.EventBus.$emit('showAttachmentSite', 0);
    } else {
      this.$el.setAttribute('style', 'bottom: 100px');
      this.visiableAttachmentForm = true;
      this.EventBus.$emit('showAttachmentSite', 100);
    }
  }

  setVisibleEmojis(): void {
    this.visibleEmojis = !this.visibleEmojis;
    this.visibleQuickResponses = false;
    this.audioSender = false;
    this.imageList = [];
    this.originalFileOrder = [];
    this.originalImageOrder = [];
    this.fileList = [];
    const element = <Vue>this.$refs['input'];
    const input = <HTMLInputElement>element.$el;
    input.focus();
  }

  setVisibleQuickResponses(): void {
    this.visibleEmojis = false;
    this.visibleQuickResponses = !this.visibleQuickResponses;
    this.audioSender = false;
    this.imageList = [];
    this.originalFileOrder = [];
    this.originalImageOrder = [];
    this.fileList = [];
    const element = <Vue>this.$refs['input'];
    const input = <HTMLInputElement>element.$el;
    input.focus();
  }

  async dataUrlToFile(url: string | any): Promise<string> {
    this.loading = true;
    let tempUrl = '';
    await Axios.post(`${BASE_URL_MANAGER}/company/getfile`, {
      urlfile: url,
    }).then(response => {
      tempUrl = response.data.file;
      this.loading = false;
    });
    return tempUrl;
  }

  triggerDeleteEvent(values: any) {
    this.$emit('delete', values);
  }

  sendQuickResponse(values: any) {
    this.visibleQuickResponses = false;

    this.$emit('send', values);
  }

  isDisabled(): boolean {
    return (
      this.uploading ||
      this.sendingMessage ||
      this.fileList.length > 0 ||
      this.imageList.length > 0 ||
      this.isError
    );
  }
  formatLanguageTemplate(template: any) {
    switch (template.language) {
      case 'es':
        return 'Español';
      case 'en':
        return 'Ingles';
      case 'pt':
        return 'Portugues';
      default:
        return 'Español';
    }
  }

  formatNameTemplate(template: any) {
    const rawName = template.name;
    const name = rawName
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    const language = this.formatLanguageTemplate(template);
    const status = template.status === 'APPROVED' ? 'Aprobado' : 'Pendiente';
    return `${name} (${language}) - ${status}`;
  }
}
