import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';

import { store } from '@/app/store';

import Axios from 'axios';

import {
  BASE_URL_MANAGER,
  BASE_URL_ORCHESTATOR,
  BASE_URL_WHATSAPP_CLOUD,
} from '@/config';
import { conversationMutationsTypes } from '@/app/store/modules/conversation/conversation.mutations';
@Component({
  name: 'modal-component',
})
export default class ModalComponent extends VueWizard {
  public project: any;

  public templates: any = [];
  public startConverstionObject: any = {
    phone: '',
    name: '',
    text: '',
  };

  openStartConversationDialog(): void {
    if (this.templates.length == 0) {
      this.getTemplates();
    }

    let modal: any = $('#start-conversation');
    modal.modal('show');
  }
  async startConversation() {
    try {
      let channel = '';

      let node: any = {
        type: 'TEXT',
        text: [this.startConverstionObject.text],
      };
      let areaCode: any = '';
      switch (this.project.country) {
        case 'CO':
          areaCode = 57;
          break;
        case 'MX':
          areaCode = 52;
          break;
        case 'PA':
          areaCode = 507;
          break;
        case 'PE':
          areaCode = 51;
          break;
        case 'CL':
          areaCode = 56;
          break;
        case 'ES':
          areaCode = 34;
          break;
        case 'PY':
          areaCode = 595;
          break;
        default:
          areaCode = 57;
          break;
      }
      //todo:guardar esto
      switch (this.project!.integrator) {
        case 'DROPI':
          channel = 'WHATSAPP_CLOUD';
          break;
        case 'MESSAGEBIRD':
          channel = 'WHATSAPP_MESSAGEBIRD';
          break;
        default:
          channel = 'WHATSAPP_CLOUD';
          break;
      }

      this.startConverstionObject.phone = this.startConverstionObject.phone
        .split(' ')
        .join('');

      let respMessage = await Axios.post(
        `${BASE_URL_WHATSAPP_CLOUD}/user/${
          areaCode + this.startConverstionObject.phone
        }`,
        {
          message: node,
          idmessase: null,
          isForwarded: false,
          idreference: null,
          conversation_id: null,
          apiKey: this.project.accessToken,
          phoneNumberId: this.project.phone_number_id,
          wabaId: this.project.bussiness_account_id,
          template: this.startConverstionObject.template,
        },
      );

      let dataMessage = respMessage.data!.data;

      let data = {
        nodo: node,
        conversation: {
          project: this.project._id,
          currentChannel: channel,
          consumer: [areaCode + this.startConverstionObject.phone],
          currentConsumer: areaCode + this.startConverstionObject.phone,
          topics: this.$store.state.agent.agent.subscriptions,
          show_in_queue: true,
        },
        msgidentity: { idmessage: dataMessage!.resp!.data!.messages[0]!.id },
        consumerName: this.startConverstionObject.name,
      };

      let url = `${BASE_URL_ORCHESTATOR}/conversation/interaction/output`;
      let respConversation = await Axios.post(url, data);

      let conversation = respConversation!.data!.conversation;

      conversation.currentConsumer =
        areaCode + this.startConverstionObject.phone;

      if (respConversation.status == 200) {
        store.commit(
          conversationMutationsTypes.setCurrentConversation(conversation),
        );

        //this.grebConversation(conversation);

        Axios(
          `${BASE_URL_ORCHESTATOR}/agent/${
            this.$store.state.agent.agent._id
          }/conversation/grab/${conversation!._id}`,
        )
          .then(res => {
            console.log('response', res);
          })
          .catch(e => {
            this.$notification.error({
              placement: 'bottomRight',
              message: `Error al tomar la conversacion`,
              description: `Error: ${e!.message}`,
            });
          })
          .finally(() => {
            let modal: any = $('#start-conversation');
            modal.modal('hide');
            this.startConverstionObject = {};
          });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información ${respConversation.status}`,
          description: `Error: ${respConversation.status}`,
        });
      }
    } catch (e) {
      console.log(e);

      this.$notification.error({
        placement: 'bottomRight',
        description: `Hubo un error al guardar al información`,
        message: `Error:`,
      });
    }
  }
  async getProject(idProject: string) {
    let resp = await Axios.get(`${BASE_URL_MANAGER}/project/${idProject}`);

    if (resp.status == 200) {
      return resp.data;
    }
  }
  async getTemplates() {
    let projectID = store.state.conversation!.currentConversation.project;
    this.project = await this.getProject(projectID!);

    Axios.get(BASE_URL_MANAGER + '/templates', {
      params: {
        project_id: this.project._id,
      },
    }).then(async res => {
      if (res.status == 200) {
        this.templates = res!
          .data!.templates.map((t: any) => {
            const dontHaveParams = t.components.filter(
              (e: any) =>
                e.type == 'BODY' &&
                e.text != undefined &&
                e!.text.indexOf('{') < 0,
            );
            return {
              ...t,
              dontHaveParams,
            };
          })
          .filter((t: any) => {
            return t.dontHaveParams.length > 0 /*&& t.status === 'APPROVED'*/;
          });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información ${res.status}`,
          description: `Error: ${res.status}`,
        });
      }
    });
  }
  isInvalidValidStrartConfersationForm() {
    if (
      this.startConverstionObject.text == undefined ||
      this.startConverstionObject.text == ''
    ) {
      return true;
    }
    if (
      this.startConverstionObject.phone == undefined ||
      this.startConverstionObject.phone == ''
    ) {
      return true;
    }
    if (
      this.startConverstionObject.template == undefined ||
      this.startConverstionObject.template == ''
    ) {
      return true;
    }

    return false;
  }

  async onChangeTemplate(e: any) {
    let body = await this.startConverstionObject.template.components.find(
      (e: any) => e.type == 'BODY',
    );
    this.startConverstionObject.text = await body.text;
  }
}
