import { render, staticRenderFns } from "./float-action-button.vue?vue&type=template&id=e6a15316"
import script from "./float-action-button.ts?vue&type=script&lang=ts&external"
export * from "./float-action-button.ts?vue&type=script&lang=ts&external"
import style0 from "./float-action-button.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports