import { Component, Vue, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';

interface State {
  value: string;
  label: string;
}

@Component({ name: 'state-filter-component' })
export default class StateFilterComponent extends VueWizard {
  @Prop() readonly title_filter!: string;
  @Prop() readonly type_filter!: string;
  @Prop() readonly name_filter!: string;
  @Prop() readonly source!: string;
  @Prop() readonly svg!: string;
  @Prop() readonly options!: any;
  @Prop() readonly states!: State[];
  @Prop() readonly multiple!: boolean;
  @Prop() readonly EventBus!: Vue;
  @Prop() readonly selected!: any;
  @Prop() readonly selectedOptionEdit!: any;
  @Prop() readonly hasDate!: boolean;
  @Prop() readonly hasShop!: boolean;

  public editFilter: boolean = true;
  public selectedOption: string[] = [];
  public isCreacion_order: boolean = true;
  public textTooltip: string =
    'Por defecto traera las ordenes del primer token guardado, aumentar el numero de tokens aumentará el tiempo de respuesta';
  public textTooltip2: string =
    'Por defecto traera las ordenes creadas de los ultimos 30 dias, ampliar este rango aumentará el tiempo de respuesta';
  mounted() {
    if (this.name_filter === 'estado_pedido') {
      this.groupBsy;
    }
    if (this.name_filter === 'creacion_orden') {
      this.isCreacion_order = this.hasDate;
    }
    if (this.selectedOptionEdit) {
      this.selectedOption = this.selectedOptionEdit;
      this.editFilter = false;
    }
  }

  get enableApply() {
    return this.selectedOption.length > 0;
  }

  get groupBsy() {
    if (this.states.length > 0) {
      // get only state.company
      const companies = this.states.map((state: any) => {
        return state.company;
      });
      // unique companies
      const uniqueCompanies = [...new Set(companies)];
      // group by company
      const groupByCompany = uniqueCompanies.map((company: string) => {
        return {
          company: company || 'Dropi',
          states: this.states
            .filter((state: any) => {
              return state.company === company;
            })
            .map((state: any) => {
              // label = state + company all mayus if not company = dropi
              return {
                value: state.value,
                label: state.label + (company ? '-' + company : ''),
              };
            }),
        };
      });
      // sort inside states company servientrega
      groupByCompany.forEach((company: any) => {
        company.states.sort((a: any, b: any) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
      });

      return groupByCompany;
    }
  }

  closeFilter() {
    this.EventBus.$emit('close-filter', {
      name_filter: this.name_filter,
      type_filter: this.type_filter,
    });
  }

  saveFilter() {
    this.editFilter = false;

    if (this.selectedOption.length > 0) {
      if (!Array.isArray(this.selectedOption)) {
        this.EventBus.$emit('save-filter', {
          name_filter: this.name_filter,
          type_filter: this.type_filter,
          selectedOption: [this.selectedOption],
          source: this.source,
          multiple: this.multiple,
        });
      } else {
        this.EventBus.$emit('save-filter', {
          name_filter: this.name_filter,
          type_filter: this.type_filter,
          selectedOption: this.selectedOption,
          source: this.source,
          multiple: this.multiple,
        });
      }
    }
  }

  enableEdit() {
    this.editFilter = true;
    this.EventBus.$emit('enable-edit', {
      name_filter: this.name_filter,
      type_filter: this.type_filter,
    });
  }

  /**
   * @description
   * Recibe un string y lo convierte en otro
   * ejemplo: "estado_pedido" => "Estado del pedido", "fecha_entrega" => "Fecha de entrega"
   *
   * @param {string} value
   * @memberof StateFilterComponent
   * @returns {string}
   *
   */
  convertString(value: string): string {
    const string = value.replace(/_/g, ' ');
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
