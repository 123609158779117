export const svg_filters = {
  fecha_creacion_contacto: '../../../assets/images/perm_contact_calendar.svg',
  // C:\Users\USUARIO\Documents\Dropi\wizard-dashboard\src\assets\images\perm_contact_calendar.svg
  fecha_inicio_ultima_conversacion: '../../../assets/images/event_note.svg',
  fecha_cierre_conversacion: '../../../assets/images/event_available.svg',
  ultimo_origen_conversacion: '../../../assets/images/event.svg',
  fecha_creacion_orden: '../../../assets/images/calendar_add_on.svg',
  automatizacion_fecha: '../../../assets/images/iconmonstr-paper-plane-2 1.svg',
  ultima_automatizacion:
    '../../../assets/images/iconmonstr-paper-plane-2 1-1.svg',
  estado_conversacion: '../../../assets/images/speaker_notes.svg',
  estado_pedido: '../../../assets/images/assignment.svg',
  etiqueta_finalizacion: '../../../assets/images/beenhere.svg',
  motivo_finalizacion: '../../../assets/images/comment_bank.svg',
  etiqueta_orden: '../../../assets/images/label.svg',
  ultimo_asesor_asignado: '../../../assets/images/support_agent.svg',
  transportadora: '../../../assets/images/local_shipping.svg',
  id_producto: '../../../assets/images/box.svg',
  creacion_orden: '../../../assets/images/calendar_add_on.svg',
  tienda: '../../../assets/images/shop.svg',
};

export const statesFilters = {
  fecha_creacion_contacto: [
    {
      value: 'hoy',
      label: 'Hoy',
    },
    {
      value: 'ayer',
      label: 'Ayer',
    },
    {
      value: 'ultimos_7_dias',
      label: 'Últimos 7 días',
    },
    {
      value: 'ultimos_30_dias',
      label: 'Últimos 30 días',
    },
    {
      value: 'ultimos_90_dias',
      label: 'Últimos 90 días',
    },
    {
      value: 'ultimos_180_dias',
      label: 'Últimos 180 días',
    },
    {
      value: 'ultimos_365_dias',
      label: 'Últimos 365 días',
    },
  ],
  creacion_orden: [
    {
      value: 'hoy',
      label: 'Hoy',
    },
    {
      value: 'ayer',
      label: 'Ayer',
    },
    {
      value: 'ultimos_7_dias',
      label: 'Últimos 7 días',
    },
    {
      value: 'ultimos_30_dias',
      label: 'Últimos 30 días',
    },
    {
      value: 'ultimos_90_dias',
      label: 'Últimos 90 días',
    },
    {
      value: 'ultimos_180_dias',
      label: 'Últimos 180 días',
    },
    {
      value: 'ultimos_365_dias',
      label: 'Últimos 365 días',
    },
  ],
};
