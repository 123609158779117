import { BASE_VALIDATION_API, COUNTRIES } from './../../../../config';
import { Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { ContactData } from '@/app/store/modules/conversation/conversation.models';
import Axios from 'axios';
import * as moment from 'moment';
import { BASE_URL_ORCHESTATOR } from '@/config';
import { store } from '@/app/store';
import { conversationActionsTypes } from '@/app/store/modules/conversation/conversation.actions';
const incidences_validation_data = require('../../../data/incidences_data.json');
const _ = require('lodash');

@Component({ name: 'dropi-info-manager' })
export default class DropiInfoManager extends VueWizard {
  public contactData: ContactData = {};
  public arrayContactData: any[] = [];
  public orders: any[] = [];
  public info_shop: {
    name: string;
    country: string;
  }[] = [];
  public historyValidation: any[] = [];
  public loadingOrders = false;
  public missingContactData: string[] = [];
  public editingContactData = 0;
  public toFillKey: any = null;
  public toFillData: any = null;
  public showOrderDetailsModal: boolean[] = [];
  public previousShowOrderDetailsModal: boolean[] = [];
  public loadingSubmitEditOrder = false;
  public loadingUpdateOrder = false;
  public loadingSubmitIncidenceSolution = false;
  public loadingSubmitReturnOrder = false;
  public loadingObservationIncidence = false;
  public enableSubmitEditOrder = true;
  public enableSubmitIncidenceSolution = true;
  public enableSubmitReturnOrder = true;
  public enableSubmitObservationIncidence = false;
  public loadingBtnSolveOrder = false;
  public loadingBtnReturnOrder = false;
  public indidencesValidationData = incidences_validation_data;
  public clickBtnSolveData: any = {};
  public orderDetails: any[] = [];
  public whatsApps: any[] = [];
  @Prop() public project: any;
  @Prop() public dropiIncidenceStatus: any;

  // constantes modales
  public showCancelOrderModal = {
    show: false,
    order: null,
  };
  public showEditOrderModal = false;
  public showModal: boolean[] = [];
  public showConfirmOrderModal: boolean[] = [];
  public showModalEditOrders: boolean[] = [];
  public showGenerateGuideModal: boolean[] = [];
  public showSolveOrderNotice: boolean[] = [];
  public showCancelOrderNotice: boolean[] = [];
  public showSolveValidationOrderNotice: boolean[] = [];

  public roleOrder = '';

  public state_collapsed = false;
  public disableButton = false;
  public tokenDropiError = false;

  public observationIncidenceFormModel = {
    observation: '',
  };

  // modal editar orden
  public editOrderFormModel = {
    name: '',
    surname: '',
    phone: '',
    client_email: '',
    zip_code: '',
    dir: '',
    notes: '',
  };

  // modal solucionar orden
  public solveOrderNoticeFormModel: any = {
    solutionDeliveryDate: undefined,
    solutionSelect: undefined,
    solutionCustomerName: '',
    solutionDetail: '',
    solutionPhone: '',
    solutionNewAddress: '',
    solutionNewAddressDetails: '',
    solutionNewAddressZipCode: '',
  };

  public phoneRegExp = /^\d{10,}$/;
  public zipRegExp = /^\d{5}(?:[-\s]\d{4})?|[0-9]{6}$/;

  public editOrderFormRules = {
    name: [
      {
        required: true,
        message: 'Ingresa el nombre del cliente',
        trigger: 'blur',
      },
    ],
    surname: [
      {
        required: true,
        message: 'Ingresa el nombre del cliente',
        trigger: 'blur',
      },
    ],
    phone: [
      {
        required: true,
        pattern: this.phoneRegExp,
        message: 'Ingresa un teléfono válido',
        trigger: 'blur',
      },
    ],
    client_email: [
      {
        required: true,
        message: 'Ingresa el email del cliente',
        trigger: 'blur',
      },
      { type: 'email', message: 'Ingresa un email válido', trigger: 'blur' },
    ],
    zip_code: [
      {
        required: false,
        pattern: this.zipRegExp,
        message: 'Ingresa un código Zip válido',
        trigger: 'blur',
      },
    ],
    dir: [
      {
        required: true,
        message: 'Ingresa la dirección del cliente',
        trigger: 'blur',
      },
    ],
    notes: [{ required: false, trigger: 'blur' }],
  };

  public solveOrderNoticeFormRules = {
    solutionDeliveryDate: [
      { required: true, message: 'Selecciona una fecha', trigger: 'change' },
    ],
    solutionCustomerName: [
      {
        required: true,
        message: 'Ingresa el nombre del cliente',
        trigger: 'blur',
      },
    ],
    solutionPhone: [
      {
        required: true,
        pattern: this.phoneRegExp,
        message: 'Ingresa un teléfono válido',
        trigger: 'blur',
      },
    ],
    solutionSelect: [
      { required: true, message: 'Selecciona una solución', trigger: 'change' },
    ],
    solutionDetail: [
      {
        required: true,
        message: 'Ingresa el detalle de la solución',
        trigger: 'blur',
      },
    ],
    solutionNewAddress: [
      {
        required: true,
        message: 'Ingresa la nueva dirección de entrega',
        trigger: 'blur',
      },
    ],
    solutionNewAddressDetails: [
      {
        required: false,
        message: 'Ingresa los detalles de la dirección',
        trigger: 'blur',
      },
    ],
    solutionNewAddressZipCode: [
      {
        required: false,
        message: 'Ingresa los detalles de la dirección',
        trigger: 'blur',
      },
    ],
  };

  public observationIncidenceFormRules = {
    observation: [
      {
        required: true,
        message: 'Debes ingresar una observación',
        trigger: 'blur',
      },
    ],
  };

  public solutionTypes: any[] = [
    // { value: "1", label: "Entregar hoy" },
    { value: '2', label: 'Entregar mañana' },
    {
      value: '3',
      label: 'Entregar en fecha asignada por remitente o destinatario',
    },
    { value: '4', label: 'Entregar en la misma dirección de la guía' },
    { value: '5', label: 'Entregar en nueva dirección' },
    // { value: '10', label: 'Devolución total del despacho' },
    { value: '16', label: 'Entrega en base o en terminal' },
    // { value: '6', label: 'Cambio de destino' },
    { value: '91', label: 'Autorización 3er Intento de Entrega' },
  ];

  public suggestedAddresses: string[] = [''];

  public loadingSuggestedAddresses = false;

  public fixAddress = false;

  async showModalOrderDetails(order: any, index: number) {
    await this.getWhatsApps(order);
    await this.getHistoryValidation(order);
    if (!this.showOrderDetailsModal[index]) {
      this.$set(this.showOrderDetailsModal, index, true);
    } else {
      this.$set(this.showOrderDetailsModal, index, false);
    }
  }

  hideModalOrderDetails(index: number) {
    this.$set(this.showOrderDetailsModal, index, false);
  }

  showModalCancelOrder(index: number) {
    if (!this.showModal[index]) {
      this.$set(this.showModal, index, true);
    } else {
      this.$set(this.showModal, index, false);
    }
  }

  hideModalCancelOrder(index: number) {
    this.$set(this.showModal, index, false);
  }

  showModalConfirmOrder(index: number) {
    if (!this.showConfirmOrderModal[index]) {
      this.$set(this.showConfirmOrderModal, index, true);
    } else {
      this.$set(this.showConfirmOrderModal, index, false);
    }
  }
  hideModalConfirmOrder(index: number) {
    this.$set(this.showConfirmOrderModal, index, false);
  }

  showModalEditOrder(order: any, index: number) {
    this.previousShowOrderDetailsModal[index] =
      this.showOrderDetailsModal[index];
    if (this.showOrderDetailsModal[index]) {
      this.$set(this.showOrderDetailsModal, index, false);
    }
    if (!this.showModalEditOrders[index]) {
      this.$set(this.showModalEditOrders, index, true);
      this.editOrderFormModel = {
        name: order.name,
        surname: order.surname,
        phone: order.phone,
        client_email: order.client_email,
        zip_code: order.zip_code,
        dir: order.dir,
        notes: order.notes,
      };
    } else {
      this.$set(this.showModalEditOrders, index, false);
    }
  }

  hideModalEditOrder(index: number) {
    this.editOrderFormModel = {
      name: '',
      surname: '',
      phone: '',
      client_email: '',
      zip_code: '',
      dir: '',
      notes: '',
    };
    this.$set(this.showModalEditOrders, index, false);
    if (this.previousShowOrderDetailsModal[index]) {
      this.$set(this.showOrderDetailsModal, index, true);
    }
  }

  showModalGenerateGuide(index: number) {
    if (!this.showGenerateGuideModal[index]) {
      this.$set(this.showGenerateGuideModal, index, true);
    } else {
      this.$set(this.showGenerateGuideModal, index, false);
    }
  }

  hideModalGenerateGuide(index: number) {
    this.$set(this.showGenerateGuideModal, index, false);
  }

  async btnSolve(order: any, index: number, solve: boolean): Promise<any> {
    if (solve) {
      this.loadingBtnSolveOrder = true;
    } else {
      this.loadingBtnReturnOrder = true;
    }
    let dropi_url = COUNTRIES.CO;
    const countryrequest = order.shop_country || this.project.country;

    switch (countryrequest) {
      case 'CO':
        dropi_url = COUNTRIES.CO;
        break;
      case 'MX':
        dropi_url = COUNTRIES.MX;
        break;
      case 'PA':
        dropi_url = COUNTRIES.PA;
        break;
      case 'EC':
        dropi_url = COUNTRIES.EC;
        break;
      case 'CL':
        dropi_url = COUNTRIES.CL;
        break;
      case 'PE':
        dropi_url = COUNTRIES.PE;
        break;
      case 'ES':
        dropi_url = COUNTRIES.ES;
        break;
      case 'PY':
        dropi_url = COUNTRIES.PY;
        break;
      default:
        dropi_url = COUNTRIES.CO;
        break;
    }
    const serviceEndpoint = `${dropi_url}/orders/clickbtnsolve`;
    const serviceBody = {
      shipping_guide: order.shipping_guide,
    };
    const serviceHeader = {
      'Content-Type': 'application/json',
      'dropi-integration-key': order.tokenDropi,
    };

    try {
      const response = await fetch(serviceEndpoint, {
        method: 'POST',
        headers: serviceHeader,
        body: JSON.stringify(serviceBody),
      });

      if (!response.ok) {
        throw new Error(
          response.status +
            ' - Error de solucionar o devolver orden en novedades',
        );
      }

      const clickbtnsolveData = await response.json();
      if (clickbtnsolveData.isSuccess) {
        this.clickBtnSolveData = clickbtnsolveData;
        if (
          order.distribution_company.incidence_solution_method == 'MEC' &&
          clickbtnsolveData.confirmacionConsulta == 'NO ESTA EN CONFIRMACIONES'
        ) {
          if (solve) {
            this.loadingBtnSolveOrder = false;
          } else {
            this.loadingBtnReturnOrder = false;
          }
          this.$toast.error(
            'Esta Guía, ya fué gestionada o aún no está en el area de confirmación',
          );
          return;
        } else {
          if (solve) {
            this.loadingBtnSolveOrder = false;
            this.showModalSolveOrderNotice(index);
          } else {
            this.loadingBtnReturnOrder = false;
            this.showModalCancelOrderNotice(index);
          }
        }
      } else {
        if (solve) {
          this.loadingBtnSolveOrder = false;
        } else {
          this.loadingBtnReturnOrder = false;
        }
        this.$toast.error(clickbtnsolveData.message);
        return;
      }
    } catch (error) {
      this.$toast.error('Error de comunicación con novedades de Dropi');
      this.dropiIncidenceStatus.reconnecting = true;
      this.reconnectBtnSolveInterval(
        serviceEndpoint,
        serviceBody,
        serviceHeader,
      );
    }
  }

  async reconnectBtnSolve(
    endpoint: string,
    body: Object,
    headers: HeadersInit | undefined,
  ): Promise<any> {
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.isSuccess) {
        clearInterval(this.dropiIncidenceStatus.reconnectingIntervalId);
        this.dropiIncidenceStatus.reconnecting = false;
        this.dropiIncidenceStatus.reconnectingRemainingTime = 0;
        this.loadingBtnSolveOrder = false;
        return;
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  reconnectBtnSolveInterval(
    endpoint: string,
    body: Object,
    headers: HeadersInit | undefined,
  ) {
    const reconnectingTime =
      this.dropiIncidenceStatus.reconnectingRemainingTime;
    this.dropiIncidenceStatus.reconnectingIntervalId = setInterval(() => {
      this.dropiIncidenceStatus.reconnectingRemainingTime--;
      if (this.dropiIncidenceStatus.reconnectingRemainingTime === 0) {
        this.dropiIncidenceStatus.reconnectingRemainingTime = reconnectingTime;
        void (async () => {
          await this.reconnectBtnSolve(endpoint, body, headers);
        })();
      }
    }, 1000);
  }

  showModalSolveOrderNotice(index: number) {
    if (!this.showSolveOrderNotice[index]) {
      this.$set(this.showSolveOrderNotice, index, true);
    } else {
      this.$set(this.showSolveOrderNotice, index, false);
    }
  }

  hideModalSolveOrderNotice(index: number) {
    this.solveOrderNoticeFormModel = {
      solutionDeliveryDate: undefined,
      solutionSelect: undefined,
      solutionDetail: '',
      solutionPhone: '',
      solutionNewAddress: '',
      solutionNewAddressDetails: '',
      solutionNewAddressZipCode: '',
    };
    this.$set(this.showSolveOrderNotice, index, false);
  }

  showModalCancelOrderNotice(index: number) {
    if (!this.showCancelOrderNotice[index]) {
      this.$set(this.showCancelOrderNotice, index, true);
    } else {
      this.$set(this.showCancelOrderNotice, index, false);
    }
  }

  hideModalCancelOrderNotice(index: number) {
    this.$set(this.showCancelOrderNotice, index, false);
  }

  showModalSolveValidationOrderNotice(order: any, index: number) {
    this.getHistoryValidation(order);
    console.log('order', order.status);

    if (
      order.status === 'PENDIENTE' ||
      order.status === 'PENDIENTE CONFIRMACION'
    ) {
      if (!this.showSolveValidationOrderNotice[index]) {
        this.$set(this.showSolveValidationOrderNotice, index, true);
      } else {
        this.$set(this.showSolveValidationOrderNotice, index, false);
      }
    } else {
      this.$toast.info(
        'Solo se pueden solucionar órdenes pendientes por confirmar',
      );
      return;
    }
  }

  hideModalSolveValidationOrderNotice(index: number) {
    this.$set(this.showSolveValidationOrderNotice, index, false);
  }

  handleStates(index: number) {
    if (index === 0) {
      return true;
    }
    return false;
  }

  async mounted(): Promise<any> {
    await this.watcherCurrentConversation();
  }

  get currentConversation(): any {
    return store.state.conversation!.currentConversation;
  }

  getMissingContactData(): void {
    this.missingContactData = [
      'name',
      'lastName',
      'phoneNumber',
      'address',
      'city',
      'email',
      'identification',
      'postalCode',
    ];
    this.arrayContactData.forEach(contactData =>
      this.missingContactData.splice(
        this.missingContactData.indexOf(contactData.originalKey),
        1,
      ),
    );
  }

  async editContactData(contactData: any): Promise<any> {
    (this.contactData as any)[contactData.originalKey] = contactData.value;
    await this.sendNewContactData();
    this.editingContactData = 0;
  }

  @Watch('currentConversation')
  async watcherCurrentConversation(): Promise<any> {
    if (this.currentConversation.contactData) {
      this.contactData = _.cloneDeep(this.currentConversation.contactData);
    } else {
      this.contactData = {};
    }
    this.getArrayContactData();
    this.getMissingContactData();
    this.getOrders();
    this.getRoleByToken();
  }

  @Emit()
  sendDropiIncidenceStatus() {
    return this.dropiIncidenceStatus;
  }

  getArrayContactData(): void {
    this.arrayContactData = [];
    if (this.contactData) {
      const keys = Object.keys(this.contactData);
      keys.forEach(key => {
        if (key) {
          this.arrayContactData.push({
            originalKey: key,
            key: this.translateKey(key),
            value: (this.contactData as any)[key],
            icon: this.keyIcon(key),
          });
        }
      });
    }
  }
  async getOrders(): Promise<void> {
    try {
      this.loadingOrders = true;
      this.orders = [];
      const requests = (this.project!.dropiUsers || []).map(
        async (dropiUser: any) => {
          // if country is not defined, we use the default country of the project
          let { country, token, name } = dropiUser;
          if (!country) {
            country = this.project!.country;
          }
          const areaCode = this.getAreaCode(country);
          const dropi_url = this.getDropiUrl(country);
          const consumer = this.currentConversation.currentConsumer;

          const newConsumer = this.getNewConsumer(consumer, areaCode);
          if (newConsumer) {
            const url = `${dropi_url}/orders/myorders?filter_by=CELULAR&value_filter_by=${newConsumer}&orderBy=id&orderDirection=desc&start=0&result_number=10`;
            const requestOptions = {
              method: 'GET',
              headers: {
                'dropi-integration-key': token,
              },
            };
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
              throw new Error(response.status + ' - Error de obtener órdenes');
            }
            const data = await response.json();
            return { objects: data.objects, country, token, name };
          }

          return [];
        },
      );

      const responses = await Promise.all(requests);
      responses.forEach((response: any) => {
        if (response.objects) {
          response.objects.forEach((order: any) => {
            this.orders.push({
              ...order,
              shop_country: response.country,
              shop_name: response.name,
              tokenDropi: response.token,
            });
          });
        }
      });

      this.orders.sort(
        (a: any, b: any) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
      this.loadingOrders = false;
      this.showModal = new Array(this.orders.length).fill(false);
      this.showConfirmOrderModal = new Array(this.orders.length).fill(false);
      this.showModalEditOrders = new Array(this.orders.length).fill(false);
      this.showGenerateGuideModal = new Array(this.orders.length).fill(false);
      this.showSolveOrderNotice = new Array(this.orders.length).fill(false);
      this.showCancelOrderNotice = new Array(this.orders.length).fill(false);
      this.showSolveValidationOrderNotice = new Array(this.orders.length).fill(
        false,
      );
    } catch (error) {
      // Manejar el error como desees
      this.$toast.error('Error de comunicación con órdenes de Dropi');
      //console.error("Ocurrió un error al obtener las órdenes:", error);
      // this.tokenDropiError = true;

      // Puedes mostrar un mensaje de error o tomar otras acciones
    }
  }

  getShop_name(shop_name: any): string {
    if (shop_name.shop != null) {
      return (
        'Orden integración: ' +
        shop_name.shop.name +
        ' - ' +
        shop_name.shop.type
      );
    }
    return (
      'Orden manual: ' +
      (shop_name.user_name
        ? shop_name.user_name
        : shop_name.client_email
        ? shop_name.client_email
        : 'Sin nombre')
    );
  }

  getAreaCode(country: string): number {
    switch (country) {
      case 'CO':
        return 57;
      case 'MX':
        return 52;
      case 'PA':
        return 507;
      case 'EC':
        return 593;
      case 'PE':
        return 51;
      case 'CL':
        return 56;
      case 'ES':
        return 34;
      case 'PY':
        return 595;
      default:
        return 57;
    }
  }

  getDropiUrl(country: string): string {
    switch (country) {
      case 'CO':
        return COUNTRIES.CO;
      case 'MX':
        return COUNTRIES.MX;
      case 'PA':
        return COUNTRIES.PA;
      case 'EC':
        return COUNTRIES.EC;
      case 'PE':
        return COUNTRIES.PE;
      case 'CL':
        return COUNTRIES.CL;
      case 'ES':
        return COUNTRIES.ES;
      case 'PY':
        return COUNTRIES.PY;
      default:
        return COUNTRIES.CO;
    }
  }
  async getHistoryValidation(order: any) {
    try {
      this.historyValidation = [];
      const requests = (this.project!.dropiUsers || []).map(
        async (dropiUser: any) => {
          let { country, token } = dropiUser;
          if (!country) {
            country = this.project!.country;
          }
          const dropi_url = this.getDropiUrl(country);
          const url = `${dropi_url}/orders/historical-addresses/${order.id}`;
          const requestOptions = {
            method: 'GET',
            headers: {
              'dropi-integration-key': token,
            },
          };
          const response = await fetch(url, requestOptions);
          if (!response.ok) {
            throw new Error(
              response.status + ' - Error de obtener historial de validación',
            );
          }
          const data = await response.json();
          return data.objects;
        },
      );
      const responses = await Promise.all(requests);
      responses.forEach((response: any) => {
        if (response != undefined && response != null && response != '') {
          response.forEach((order: any) => {
            this.historyValidation.push({
              ...order,
            });
          });
        }
      });
    } catch (error) {
      this.$toast.error('Error de comunicación con órdenes de Dropi');
    }
  }
  getNewConsumer(consumer: string, areaCode: number): string {
    const tamanio = String(areaCode);
    const newConsumer = consumer.substring(tamanio.length);

    if (newConsumer && newConsumer !== '') {
      return newConsumer;
    }

    return '';
  }

  async getWhatsApps(orderID: any): Promise<void> {
    if (
      this.project!.dropiUsers != undefined &&
      this.project!.dropiUsers!.length > 0
    ) {
      let dropi_url = COUNTRIES.CO;
      let areaCode: any = '';
      this.whatsApps = [];
      const countryrequest = orderID.shop_country || this.project.country;

      switch (countryrequest) {
        case 'CO':
          areaCode = 57;
          dropi_url = COUNTRIES.CO;
          break;
        case 'MX':
          dropi_url = COUNTRIES.MX;
          areaCode = 52;
          break;
        case 'PA':
          dropi_url = COUNTRIES.PA;
          areaCode = 507;
          break;
        case 'EC':
          dropi_url = COUNTRIES.EC;
          areaCode = 593;
          break;
        case 'PE':
          dropi_url = COUNTRIES.PE;
          areaCode = 51;
          break;
        case 'CL':
          dropi_url = COUNTRIES.CL;
          areaCode = 56;
          break;
        case 'ES':
          dropi_url = COUNTRIES.ES;
          areaCode = 34;
          break;
        case 'PY':
          dropi_url = COUNTRIES.PY;
          areaCode = 595;
          break;
        default:
          dropi_url = COUNTRIES.CO;
          areaCode = 57;
          break;
      }
      if (orderID != undefined && orderID != null && orderID != '') {
        const requests = (this.project!.dropiUsers || []).map(
          async (dropiUser: any) => {
            let { country, token } = dropiUser;
            if (!country) {
              country = this.project!.country;
            }
            const dropi_url = this.getDropiUrl(country);
            const url = `${dropi_url}/campaings/get/whatsapps?order_id=${orderID.id}`;
            const requestOptions = {
              method: 'GET',
              headers: {
                'dropi-integration-key': token,
              },
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();
            return data.objects;
          },
        );

        const responses = await Promise.all(requests);

        responses.forEach((response: any) => {
          if (response != undefined && response != null && response != '') {
            response.forEach((order: any) => {
              this.whatsApps.push({
                ...order,
              });
            });
          }
        });
      }
    }
  }

  // get role by token
  async getRoleByToken(): Promise<void> {
    let role = '';
    let dropi_url = COUNTRIES.CO;
    switch (this.project.country) {
      case 'CO':
        dropi_url = COUNTRIES.CO;
        break;
      case 'MX':
        dropi_url = COUNTRIES.MX;
        break;
      case 'PA':
        dropi_url = COUNTRIES.PA;
        break;
      case 'EC':
        dropi_url = COUNTRIES.EC;
        break;
      case 'CL':
        dropi_url = COUNTRIES.CL;
        break;
      case 'PE':
        dropi_url = COUNTRIES.PE;
        break;
      case 'ES':
        dropi_url = COUNTRIES.ES;
        break;
      case 'PY':
        dropi_url = COUNTRIES.PY;
        break;
      default:
        dropi_url = COUNTRIES.CO;
        break;
    }
    try {
      const response = await fetch(`${dropi_url}/shops/get/shop-data`, {
        method: 'GET',
        headers: {
          'dropi-integration-key': this.project!.dropiUsers[0]!.token,
        },
      });

      if (!response.ok) {
        throw new Error(response.status + ' - Error de obtener rol por token');
      }

      const data = await response.json();
      if (data.isSuccess) {
        role = data.objects.role;
      }
    } catch (error) {
      this.$toast.error(
        'Hubo un problema al obtener la información de tu cuenta, intenta de nuevo más tarde',
      );

      this.loadingOrders = false;
      // this.tokenDropiError = true;
    }
    this.roleOrder = role;
  }

  // update Order
  async updateOrder(
    statusOrder: string,
    order: any,
    index: number,
  ): Promise<any> {
    this.disableButton = true;
    this.loadingUpdateOrder = true;
    const countryrequest = order.shop_country || this.project.country;
    let dropi_url = COUNTRIES.CO;
    switch (countryrequest) {
      case 'CO':
        dropi_url = COUNTRIES.CO;
        break;
      case 'MX':
        dropi_url = COUNTRIES.MX;
        break;
      case 'PA':
        dropi_url = COUNTRIES.PA;
        break;
      case 'EC':
        dropi_url = COUNTRIES.EC;
        break;
      case 'CL':
        dropi_url = COUNTRIES.CL;
        break;
      case 'PE':
        dropi_url = COUNTRIES.PE;
        break;
      case 'ES':
        dropi_url = COUNTRIES.ES;
        break;
      case 'PY':
        dropi_url = COUNTRIES.PY;
        break;
      default:
        dropi_url = COUNTRIES.CO;
        break;
    }

    // nombre del agente y correo del agente que actualiza la orden, store
    const nameAgent =
      this.$store.state.user.user.name.firstName +
      ' ' +
      this.$store.state.user.user.name.lastName;
    const emailAgent = this.$store.state.user.user.username;

    try {
      const response = await fetch(`${dropi_url}/orders/myorders/${order.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'dropi-integration-key': order.tokenDropi,
        },
        body: JSON.stringify({
          status: statusOrder,
          usuario_chatcenter: {
            name: nameAgent,
            email: emailAgent,
          },
        }),
      });

      if (!response.ok) {
        throw new Error(response.status + ' - Error de actualizar orden');
      }

      const data = await response.json();

      if (data.isSuccess) {
        this.$toast.success('¡Orden actualizada correctamente!');
        this.$set(this.showModal, index, false);
        this.$set(this.showConfirmOrderModal, index, false);
        this.$set(this.showModalEditOrders, index, false);
        this.$set(this.showGenerateGuideModal, index, false);
        this.$set(this.showModalGenerateGuide, index, false);
        this.disableButton = false;
        this.loadingUpdateOrder = false;
        this.getOrders();
      } else {
        this.disableButton = false;
        this.loadingUpdateOrder = false;
        throw new Error(
          response.status + ' - Error de status al actualizar orden',
        );
      }
    } catch (error) {
      this.$toast.error(
        'No se pudo actualizar la orden, novedades de Dropi no está disponible actualmente',
      );
    }
  }

  onValEditOrderForm(prop: string, valid: boolean) {
    if (!valid) {
      this.enableSubmitEditOrder = false;
    } else {
      this.enableSubmitEditOrder = true;
    }
  }

  onValSolveOrderNoticeForm(prop: string, valid: boolean) {
    if (!valid) {
      this.enableSubmitIncidenceSolution = false;
    } else {
      this.enableSubmitIncidenceSolution = true;
    }
  }

  onValObservationIncidenceForm(prop: string, valid: boolean) {
    if (!valid) {
      this.enableSubmitObservationIncidence = false;
    } else {
      this.enableSubmitObservationIncidence = true;
    }
  }

  async submitEditOrderForm(order: any, index: number) {
    this.loadingSubmitEditOrder = true;
    this.enableSubmitEditOrder = false;
    delete order.status;
    let dropi_url = COUNTRIES.CO;
    const countryrequest = order.shop_country || this.project.country;
    switch (countryrequest) {
      case 'CO':
        dropi_url = COUNTRIES.CO;
        break;
      case 'MX':
        dropi_url = COUNTRIES.MX;
        break;
      case 'PA':
        dropi_url = COUNTRIES.PA;
        break;
      case 'EC':
        dropi_url = COUNTRIES.EC;
        break;
      case 'CL':
        dropi_url = COUNTRIES.CL;
        break;
      case 'PE':
        dropi_url = COUNTRIES.PE;
        break;
      case 'ES':
        dropi_url = COUNTRIES.ES;
        break;
      case 'PY':
        dropi_url = COUNTRIES.PY;
        break;
      default:
        dropi_url = COUNTRIES.CO;
        break;
    }

    try {
      const response = await fetch(`${dropi_url}/orders/myorders/${order.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'dropi-integration-key': order.tokenDropi,
        },
        body: JSON.stringify({
          ...order,
          ...this.editOrderFormModel,
        }),
      });

      if (!response.ok) {
        throw new Error(response.status + ' - Error de edición de órdenes');
      }

      const data = await response.json();

      if (data.isSuccess) {
        this.$toast.success('Orden actualizada correctamente');
        this.$set(this.orders, index, false);
        this.getOrders();
        this.loadingSubmitEditOrder = false;
        this.enableSubmitEditOrder = true;
      } else {
        this.loadingSubmitEditOrder = false;
        this.enableSubmitEditOrder = true;
        throw new Error(
          response.status + ' - Error de edición de órdenes, ' + data.message,
        );
      }
    } catch (error) {
      console.log('error', error);

      const e = error as any;
      const message =
        e.message ||
        'Error de comunicación con el actualizador de órdenes de Dropi';
      this.$toast.error(message);
      this.loadingSubmitEditOrder = false;
      this.enableSubmitEditOrder = true;
    }
  }

  async setContactData(): Promise<any> {
    let tempData: any = {};
    tempData[this.toFillData.originalKey] = this.toFillData.value;
    Object.assign(this.contactData, tempData);
    this.toFillData = null;
    this.toFillKey = null;
    this.getArrayContactData();
    this.getMissingContactData();
    await this.sendNewContactData();
  }

  changeToFillData(): void {
    this.toFillData = {
      originalKey: this.toFillKey,
      key: this.translateKey(this.toFillKey),
      value: '',
      icon: this.keyIcon(this.toFillKey),
    };
  }

  async sendNewContactData(): Promise<any> {
    await Axios.post(
      `${BASE_URL_ORCHESTATOR}/agent/${
        store.state.agent!.agent._id
      }/conversation/contactData/${this.currentConversation._id}`,
      this.contactData,
    ).then(() => {
      if (this.contactData) {
        store.dispatch(
          conversationActionsTypes.changeCurrentConversationContactData(
            this.contactData,
          ),
        );
      }
    });
  }

  async submitObservationIncidenceForm(order: any, index: number) {
    this.loadingObservationIncidence = true;
    const nameAgent =
      this.$store.state.user.user.name.firstName +
      ' ' +
      this.$store.state.user.user.name.lastName;
    const emailAgent = this.$store.state.user.user.username;
    let dropi_url = COUNTRIES.CO;
    const countryrequest = order.shop_country || this.project.country;
    switch (countryrequest) {
      case 'CO':
        dropi_url = COUNTRIES.CO;
        break;
      case 'MX':
        dropi_url = COUNTRIES.MX;
        break;
      case 'PA':
        dropi_url = COUNTRIES.PA;
        break;
      case 'EC':
        dropi_url = COUNTRIES.EC;
        break;
      case 'CL':
        dropi_url = COUNTRIES.CL;
        break;
      case 'PE':
        dropi_url = COUNTRIES.PE;
        break;
      case 'ES':
        dropi_url = COUNTRIES.ES;
        break;
      case 'PY':
        dropi_url = COUNTRIES.PY;
        break;

      default:
        dropi_url = COUNTRIES.CO;
        break;
    }
    try {
      const response = await fetch(
        `${dropi_url}/orders/saveobservationincidence`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'dropi-integration-key': order.tokenDropi,
          },
          body: JSON.stringify({
            order_id: order.id,
            observation: this.observationIncidenceFormModel.observation,
            usuario_chatcenter: {
              name: nameAgent,
              email: emailAgent,
            },
          }),
        },
      );

      if (!response.ok) {
        throw new Error(
          response.status + ' - Error de envío de obserbación en novedades',
        );
      }

      const data = await response.json();

      if (data) {
        this.$toast.success('Solución enviada correctamente');
        this.observationIncidenceFormModel.observation = '';
        this.loadingObservationIncidence = false;
        this.getOrders();
      } else {
        this.loadingObservationIncidence = false;
        throw new Error(
          response.status + ' - Error de envío de obserbación en novedades',
        );
      }
    } catch (error) {
      this.$toast.error(
        'Error de comunicación con el solucionador de novedades de Dropi',
      );
    }
  }

  async submitIncidenceSolution(order: any, index: number): Promise<any> {
    this.loadingSubmitIncidenceSolution = true;
    this.enableSubmitIncidenceSolution = false;
    let tipocategoria = 0;
    let saveDataTemp = {};
    let formattedSolutionDeliveryDate = '';
    if (this.solveOrderNoticeFormModel.solutionDeliveryDate) {
      formattedSolutionDeliveryDate =
        this.solveOrderNoticeFormModel.solutionDeliveryDate.format(
          'YYYY-MM-DD',
        );
    }
    let dropi_url = COUNTRIES.CO;
    const countryrequest = order.shop_country || this.project.country;
    switch (countryrequest) {
      case 'CO':
        dropi_url = COUNTRIES.CO;
        saveDataTemp = {
          order_id: order.id,
          direccionConfirma: this.solveOrderNoticeFormModel.solutionNewAddress,
          nombreConfirma: this.solveOrderNoticeFormModel.solutionCustomerName,
          datosAdicionalDir:
            this.solveOrderNoticeFormModel.solutionNewAddressDetails,
          telefonoBaseConfirma: this.solveOrderNoticeFormModel.solutionPhone,
          fechaConfirma: formattedSolutionDeliveryDate,
          my_confirmations: order.my_confirmations,
          solution: this.solveOrderNoticeFormModel.solutionDetail,
          essolucion: 1,
          tipocategoria: tipocategoria,
          selectValueConfirma: this.solveOrderNoticeFormModel.solutionSelect,
        };
        break;
      case 'MX':
        dropi_url = COUNTRIES.MX;
        if (order.distribution_company.name === 'BLUE') {
          saveDataTemp = {
            order_id: order.id,
            direccionConfirma:
              this.solveOrderNoticeFormModel.solutionNewAddress,
            datosAdicionalDir:
              this.solveOrderNoticeFormModel.solutionNewAddressDetails,
            newZipcode:
              this.solveOrderNoticeFormModel.solutionNewAddressZipCode,
          };
        } else if (order.distribution_company.name === 'QUIKEN') {
          saveDataTemp = {
            order_id: order.id,
            solution: this.solveOrderNoticeFormModel.solutionDetail,
            nombreConfirma: this.solveOrderNoticeFormModel.solutionCustomerName,
            direccionConfirma:
              this.solveOrderNoticeFormModel.solutionNewAddress,
            newZipcode:
              this.solveOrderNoticeFormModel.solutionNewAddressZipCode,
            telefonoBaseConfirma: this.solveOrderNoticeFormModel.solutionPhone,
          };
        }
        break;
      case 'PA':
        dropi_url = COUNTRIES.PA;
        break;
      case 'EC':
        dropi_url = COUNTRIES.EC;
        if (order.distribution_company.name === 'GINTRACOM') {
          saveDataTemp = {
            order_id: order.id,
            direccionConfirma:
              this.solveOrderNoticeFormModel.solutionNewAddress,
            datosAdicionalDir:
              this.solveOrderNoticeFormModel.solutionNewAddressDetails,
            my_confirmations: order.my_confirmations,
            solution: this.solveOrderNoticeFormModel.solutionDetail,
            tipocategoria: tipocategoria,
          };
        }
        break;
      case 'CL':
        dropi_url = COUNTRIES.CL;
        if (order.distribution_company.name === 'BLUE') {
          saveDataTemp = {
            order_id: order.id,
            direccionConfirma:
              this.solveOrderNoticeFormModel.solutionNewAddress,
            datosAdicionalDir:
              this.solveOrderNoticeFormModel.solutionNewAddressDetails,
            my_confirmations: order.my_confirmations,
            solution: this.solveOrderNoticeFormModel.solutionDetail,
            essolucion: 1,
            tipocategoria: tipocategoria,
          };
        }
        break;
      case 'PE':
        dropi_url = COUNTRIES.PE;
        break;
      default:
        dropi_url = COUNTRIES.CO;
        saveDataTemp = {
          order_id: order.id,
          direccionConfirma: this.solveOrderNoticeFormModel.solutionNewAddress,
          nombreConfirma: this.solveOrderNoticeFormModel.solutionCustomerName,
          datosAdicionalDir:
            this.solveOrderNoticeFormModel.solutionNewAddressDetails,
          telefonoBaseConfirma: this.solveOrderNoticeFormModel.solutionPhone,
          fechaConfirma: formattedSolutionDeliveryDate,
          my_confirmations: order.my_confirmations,
          solution: this.solveOrderNoticeFormModel.solutionDetail,
          essolucion: 1,
          tipocategoria: tipocategoria,
          selectValueConfirma: this.solveOrderNoticeFormModel.solutionSelect,
        };
        break;
    }

    // nombre del agente y correo del agente que actualiza la orden, store
    const nameAgent =
      this.$store.state.user.user.name.firstName +
      ' ' +
      this.$store.state.user.user.name.lastName;
    const emailAgent = this.$store.state.user.user.username;

    try {
      const response = await fetch(
        `${dropi_url}/orders/saveincidencesolution`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'dropi-integration-key': order.tokenDropi,
          },
          body: JSON.stringify({
            data: [
              {
                ...saveDataTemp,
              },
            ],
            usuario_chatcenter: {
              name: nameAgent,
              email: emailAgent,
            },
          }),
        },
      );

      if (!response.ok) {
        throw new Error(
          response.status +
            ' - Error de actualización de observación en novedades',
        );
      }

      const data = await response.json();

      if (data.isSuccess) {
        this.$toast.success('Solución enviada correctamente');
        this.$set(this.showSolveOrderNotice, index, false);
        this.loadingSubmitIncidenceSolution = false;
        this.enableSubmitIncidenceSolution = true;
        this.solveOrderNoticeFormModel = {
          solutionDeliveryDate: undefined,
          solutionSelect: undefined,
          solutionDetail: '',
          solutionPhone: '',
          solutionNewAddress: '',
          solutionNewAddressDetails: '',
          solutionNewAddressZipCode: '',
          solutionCustomerName: '',
        };
        this.getOrders();
      } else {
        this.loadingSubmitIncidenceSolution = false;
        this.enableSubmitIncidenceSolution = true;
        throw new Error(
          response.status +
            ' - Error de actualización de observación en novedades',
        );
      }
    } catch (error) {
      this.$toast.error(
        'Error de comunicación con el solucionador de novedades de Dropi',
      );
    }
  }

  async submitIncidenceReturn(order: any, index: number): Promise<any> {
    this.loadingSubmitReturnOrder = true;
    this.enableSubmitReturnOrder = false;
    let dropi_url = COUNTRIES.CO;
    const nameAgent =
      this.$store.state.user.user.name.firstName +
      ' ' +
      this.$store.state.user.user.name.lastName;
    const emailAgent = this.$store.state.user.user.username;
    const countryrequest = order.shop_country || this.project.country;

    switch (countryrequest) {
      case 'CO':
        dropi_url = COUNTRIES.CO;
        break;
      case 'MX':
        dropi_url = COUNTRIES.MX;
        break;
      case 'PA':
        dropi_url = COUNTRIES.PA;
        break;
      case 'EC':
        dropi_url = COUNTRIES.EC;
        break;
      case 'CL':
        dropi_url = COUNTRIES.CL;
        break;
      case 'PE':
        dropi_url = COUNTRIES.PE;
        break;
      case 'ES':
        dropi_url = COUNTRIES.ES;
        break;
      case 'PY':
        dropi_url = COUNTRIES.PY;
        break;
      default:
        dropi_url = COUNTRIES.CO;
        break;
    }
    try {
      const response = await fetch(
        `${dropi_url}/orders/saveincidencesolution`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'dropi-integration-key': order.tokenDropi,
          },
          body: JSON.stringify({
            data: [
              {
                CLIENTE_CANCELA_ENTREGA_ENVIO: true,
                essolucion: 1,
                my_confirmations: order.my_confirmations,
                order_id: order.id,
                solution: 'DEVOLVER AL REMITENTE',
                tipocategoria: 1,
              },
            ],
            usuario_chatcenter: {
              name: nameAgent,
              email: emailAgent,
            },
          }),
        },
      );

      if (!response.ok) {
        throw new Error(
          response.status + ' - Error de actualización de data en devoluciones',
        );
      }

      const data = await response.json();

      if (data.isSuccess) {
        this.$toast.success('Se ha solicitado la devolución');
        this.$set(this.showCancelOrderNotice, index, false);
        this.loadingSubmitReturnOrder = false;
        this.enableSubmitReturnOrder = true;
        this.getOrders();
      } else {
        this.loadingSubmitReturnOrder = false;
        this.enableSubmitReturnOrder = true;
        throw new Error(
          response.status + ' - Error de actualización de data en devoluciones',
        );
      }
    } catch (error) {
      this.$toast.error(
        'Error de comunicación con las devoluciones de novedades de Dropi',
      );
    }
  }

  shouldShowSolveIncidence(order: any): boolean {
    const {
      issue_solved_by_parent_order,
      issue_solved_by_operator,
      status,
      history_new_orders,
      country,
      shipping_company,
    } = order;

    const hasIssue = !issue_solved_by_parent_order && !issue_solved_by_operator;
    const isIncidenceStatus = status === 'NOVEDAD';
    const isInProcessing =
      status === 'EN PROCESAMIENTO' && history_new_orders.length > 0;
    const shouldShowIncidenceSolve = this.getDistCompanyConditions(
      country,
      shipping_company,
    )
      ? this.getDistCompanyConditions(country, shipping_company)
          .show_incidence_solve_on_processing
      : false;

    return (
      hasIssue &&
      (isIncidenceStatus || (isInProcessing && shouldShowIncidenceSolve))
    );
  }

  translateKey(key: string): string {
    switch (key) {
      case 'name':
        return 'Nombre';
      case 'lastName':
        return 'Apellido';
      case 'phoneNumber':
        return 'Telefono';
      case 'address':
        return 'Dirección';
      case 'city':
        return 'Ciudad';
      case 'email':
        return 'Correo';
      case 'identification':
        return 'Identificación';
      case 'postalCode':
        return 'Codigo postal';
      default:
        return 'Sin definir';
    }
  }

  keyIcon(key: string): string {
    switch (key) {
      case 'name':
        return 'user';
      case 'lastName':
        return 'signature';
      case 'phoneNumber':
        return 'phone';
      case 'address':
        return 'map-marker';
      case 'city':
        return key;
      case 'email':
        return 'envelope';
      case 'identification':
        return 'id-card';
      case 'postalCode':
        return 'mail-bulk';
      default:
        return 'user';
    }
  }

  formatUpdatedDate(date: Date | string, showTime: boolean): string {
    moment.locale('es');
    if (showTime) {
      return moment.default(date).format('DD/MM/YYYY - hh:mm');
    }
    return moment.default(date).format('DD/MM/YYYY');
  }

  fotmatNumber(value: string): string {
    const num = parseFloat(value);
    const formatted =
      '$' +
      num
        .toLocaleString('es-CO', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(',', '.');
    return formatted;
  }

  sumAllProducts(products: any): string {
    const allP = products
      .map((item: any) => item.quantity)
      .reduce((acc: number, cur: number) => acc + cur);
    const parsedAllp = parseFloat(allP);
    let result = '';
    if (parsedAllp === 1) {
      result = parsedAllp.toString() + ' artículo';
    } else if (parsedAllp > 1) {
      result = parsedAllp.toString() + ' artículos';
    }
    return result;
  }

  expandAllOrders(): void {
    const el = this.$refs.orders_collapse_group as any;

    // if all elements are collapsed, expand them all
    // otherwise, collapse them all
    if (el.elements.every((e: any) => !e.status)) {
      el.openAll();
      this.state_collapsed = false;
    } else {
      el.closeAll();
      this.state_collapsed = true;
    }
  }

  collapseAllOrders(): void {
    const el = this.$refs.orders_collapse_group as any;
    el.closeAll(); // close all elements
    this.state_collapsed = true;
  }

  updateCollapseStatus(obj: any): void {
    if (!obj.status === true) {
      obj.vm.$el.classList.add('collapsed');
    } else {
      obj.vm.$el.classList.remove('collapsed');
    }
  }

  percentageReturnedOrders(): number {
    if (this.orders.length > 0) {
      const order = this.orders[0];
      const client_total_orders_returneds = order.client_total_orders_returneds;
      const client_total_orders = order.client_total_orders;

      if (client_total_orders === 0) return 0;

      const returnedPercentage =
        (client_total_orders_returneds / client_total_orders) * 100;
      const roundedPercentage = Math.floor(returnedPercentage);
      return roundedPercentage;
    } else {
      return 0;
    }
  }

  orderActivityAlertClass(): string {
    const percentage = this.percentageReturnedOrders();
    if (percentage === 0) {
      return 'order-activity-alert--green';
    } else if (percentage > 0 && percentage < 20) {
      return 'order-activity-alert--yellow';
    } else if (percentage >= 20 && percentage < 50) {
      return 'order-activity-alert--yellow2';
    } else if (percentage >= 50 && percentage <= 100) {
      return 'order-activity-alert--red';
    } else {
      return 'order-activity-alert--green';
    }
  }

  getMessageRepeadOrders(): string {
    let message = '';
    if (this.orders.length === 0) {
      return message;
    }
    const repeatedOrders = this.orders[0].ordenes_repetidas;
    if (repeatedOrders != null && repeatedOrders.length > 0) {
      let first_ms = 'Este cliente tiene un total de';
      let second_ms = 'ordenes, donde el producto puede que esté repetido';
      let three_ms = 'La orden mas reciente tiene el id:';

      if (repeatedOrders.length < 2) {
        second_ms = 'orden, donde el producto puede que esté repetido';
      }

      return (
        first_ms +
        ' ' +
        repeatedOrders.length +
        ' ' +
        second_ms +
        '. ' +
        three_ms +
        ' ' +
        repeatedOrders[0]['id']
      );
    } else {
      return message;
    }
  }

  getDistCompanyConditions = (
    country: string,
    distribution_company_name: string,
  ): any => {
    if (incidences_validation_data) {
      const distCompanyIndex =
        incidences_validation_data[0].distribution_companies.findIndex(
          (elem: any) =>
            elem.country === country &&
            elem.distribution_company_name === distribution_company_name,
        );
      return incidences_validation_data[0].distribution_companies[
        distCompanyIndex
      ];
    } else {
      return null;
    }
  };

  // copy text to clipboard
  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.$notification.success({
      placement: 'bottomRight',
      message: `Texto copiado`,
      description: `El texto se ha copiado al portapapeles`,
    });
  }

  async getSuggestedAddress(item: any, index: number) {
    try {
      this.loadingSuggestedAddresses = true;
      const request = await Axios.post(
        `${BASE_VALIDATION_API}/api/address/geoassisted-siti`,
        {
          city: item.city,
          address: item.dir,
        },
      );
      const response = request.data;

      this.loadingSuggestedAddresses = false;
      this.suggestedAddresses = response.data.map((address: string) => {
        const indiceDosPuntos = address.indexOf(':');
        return indiceDosPuntos !== -1
          ? address.slice(indiceDosPuntos + 1).trim()
          : address;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingSuggestedAddresses = false;
    }
  }

  async showSuggestedAddress(item: any, index: number) {
    await this.getSuggestedAddress(item, index);
    this.fixAddress = true;
  }

  hideSuggestedAddress() {
    this.fixAddress = false;
  }

  //
  svgValidationIcon(item: any): string {
    let icon = '';

    if (item.is_validated) {
      icon += 'green-circle';
    }
    if (!item.is_validated) {
      icon += 'red-circle';
    }

    if (item.is_validated && item.has_validated_historial) {
      icon += 'gray-circle';
    }

    if (item.is_validated == null && item.has_validated_historial) {
      icon += 'blue-circle';
    }

    if (item.is_validated && !item.has_aggregation) {
      icon += 'yellow-circle';
    }

    return icon;
  }
}
