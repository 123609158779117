import { Component, Vue, Prop } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import dayjs, { Dayjs } from "dayjs";
const locale = require("ant-design-vue/es/date-picker/locale/es_ES.js");

@Component({ name: "date-filter-component" })
export default class DateFilterComponent extends VueWizard {
  @Prop() readonly title_filter!: string;
  @Prop() readonly type_filter!: string;
  @Prop() readonly name_filter!: string;
  @Prop() readonly svg!: string;
  @Prop() readonly EventBus!: Vue;

  public datePickerStart: any = null;
  public datePickerEnd: any = null;
  public locale: any = locale.default;
  public format: [string, string] = [
    "YYYY-MM-DDTHH:mm:ssZ",
    "YYYY-MM-DDTHH:mm:ssZ",
  ];
  public editFilter: boolean = true;

  closeFilter() {
    this.EventBus.$emit("close-filter", {
      name_filter: this.name_filter,
      type_filter: this.type_filter,
    });
  }

  saveFilter() {
    // format date to dd-mm-yyyy hh:mm am/pm
    if (this.datePickerStart) {
      this.datePickerStart[0] = dayjs(this.datePickerStart[0])
        .startOf("day")
        .format("DD-MM-YYYY HH:mm A");
      this.datePickerStart[1] = dayjs(this.datePickerStart[1])
        .endOf("day")
        .format("DD-MM-YYYY HH:mm A");
      this.editFilter = false;
      this.EventBus.$emit("save-filter", {
        name_filter: this.name_filter,
        type_filter: this.type_filter,
        date_start: this.datePickerStart,
      });
    }
  }
  convertString(value: string): string {
    const string = value.replace(/_/g, " ");
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
